export function CardContent({ shouldShowYearlyPricing }) {
  return (
    <>
      <h5 className="mt-3 text-gray-500 dark:text-indigo-500 text-sm">
        With a paid plan, you&apos;ll get access to all of
        Scribenote&apos;s great features, unlimited.
      </h5>
      <ul className="list-disc p-6 text-gray-500 dark:text-indigo-300 text-sm font-medium space-y-2">
        {shouldShowYearlyPricing && <li>Save $998 Annually</li>}
        <li>Unlimited Notes</li>
        <li>Unlimited Medical Records</li>
        <li>Unlimited Support (Email, Chat, Phone, Video)</li>
      </ul>
    </>
  );
}
