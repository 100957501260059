import { React } from "react";

import {
  useNoteMultiselect,
  useNoteMultiselectContext,
} from "../../../hooks";
import Twemoji from "../../../Twemoji";
import NoteCreatedAt from "../../notebook/note/NoteCreatedAt";
import { ConfirmModal } from "../modals/PopupModals";

export function DeleteMultiModal({ notes, refetch }) {
  const {
    numSelectedNotes,
    selectedNoteUuids,
    shouldShowDeleteNoteModal,
    setShouldShowDeleteNoteModal,
  } = useNoteMultiselectContext();

  const { handleDeleteNotes, loading } = useNoteMultiselect({
    notes,
    refetch,
  });

  return (
    <ConfirmModal
      shouldShow={shouldShowDeleteNoteModal}
      hideModal={() => setShouldShowDeleteNoteModal(false)}
      title={
        numSelectedNotes > 1
          ? "Delete these notes?"
          : "Delete this note?"
      }
      confirmText="Delete"
      confirmAction={handleDeleteNotes}
      cancelAction={() => setShouldShowDeleteNoteModal(false)}
      disabled={loading}
    >
      <h5>
        Are you sure you&apos;d like to{" "}
        <strong>
          delete{" "}
          {numSelectedNotes > 1
            ? `these ${numSelectedNotes} notes`
            : "this note"}
        </strong>
        ?
      </h5>
      <div className="mt-1 max-h-64 overflow-y-auto">
        {notes?.map((note) => {
          if (Object.keys(selectedNoteUuids)?.includes(note?.uuid)) {
            return (
              <h5 key={note?.uuid} className="text-sm">
                <Twemoji emoji={"📝"} />{" "}
                <span className="font-medium">
                  {note?.patient?.name || ""}{" "}
                </span>
                <span>
                  <span className="font-medium">{note?.title}</span>{" "}
                  <NoteCreatedAt
                    createdAt={note?.createdAt}
                    size="sm"
                  />
                </span>
              </h5>
            );
          }
        })}
      </div>
    </ConfirmModal>
  );
}
