import React from "react";

import { AdditionalFieldInput } from "./AdditionalFieldInput.js";
import Twemoji from "../../../../Twemoji.js";

export function KeyPatientInfoFormFields({
  control,
  keyHistory,
  keyMedications,
  vaccinationStatus,
  weight,
  placeholder = "Type additional info here...",
}) {
  return (
    <>
      <AdditionalFieldInput
        control={control}
        title={
          <span className="text-gray-600 dark:text-gray-300">
            <Twemoji emoji="🩺" /> Key History
          </span>
        }
        fieldName="keyHistory"
        placeholder={placeholder}
        defaultValue={keyHistory}
      />
      <AdditionalFieldInput
        control={control}
        title={
          <span className="text-gray-600 dark:text-gray-300">
            <Twemoji emoji="💊" /> Key Medications
          </span>
        }
        fieldName="keyMedications"
        placeholder={placeholder}
        defaultValue={keyMedications}
      />
      <AdditionalFieldInput
        control={control}
        title={
          <span className="text-gray-600 dark:text-gray-300">
            <Twemoji emoji="💉" /> Vaccination Status
          </span>
        }
        fieldName="vaccinationStatus"
        placeholder={placeholder}
        defaultValue={vaccinationStatus}
      />
      <AdditionalFieldInput
        control={control}
        title={
          <span className="text-gray-600 dark:text-gray-300">
            <Twemoji emoji="⚖️" /> Weight
          </span>
        }
        fieldName="weight"
        placeholder={placeholder}
        defaultValue={weight}
      />
    </>
  );
}
