import React, { useEffect, useState } from "react";

import AddUsersTable from "./AddUsersTable";

const ConfirmNewUsers = ({
  newUsers = [],
  existingUsers = [],
  deactivatedUsers = [],
  setUsersState,
  onRemove,
  error,
}) => {
  const [newUsersState, setNewUsersState] = useState(newUsers);
  const [existingUsersState, setExistingUsersState] =
    useState(existingUsers);

  useEffect(() => {
    if (setUsersState) {
      setUsersState({
        newUsers: newUsersState,
        existingUsers: existingUsersState,
      });
    }
  }, [newUsersState, existingUsersState, setUsersState]);

  return (
    <>
      {newUsersState.length > 0 && (
        <div className="bg-gray-100/70 dark:bg-gray-700/30 border border-gray-50 dark:border-gray-700/50 rounded-lg py-2 px-2 w-full my-4">
          <h5 className="mb-2 font-bold text-lg">New Users</h5>
          <AddUsersTable
            users={newUsersState}
            isExisting={false}
            setUsers={setNewUsersState}
            onRemove={onRemove}
            error={error}
          />
        </div>
      )}
      {existingUsersState.length > 0 && (
        <div className="bg-gray-100/70 dark:bg-gray-700/30 border border-gray-50 dark:border-gray-700/50 rounded-lg py-2 px-2 w-full my-4">
          <h5 className="mb-2 font-bold text-lg">Existing Users</h5>
          <AddUsersTable
            users={existingUsersState}
            isExisting={true}
            setUsers={setExistingUsersState}
            onRemove={onRemove}
            error={error}
          />
        </div>
      )}
      {deactivatedUsers.length > 0 && (
        <p>
          User{deactivatedUsers.length > 1 ? "s" : ""}{" "}
          {deactivatedUsers.map((user, index) => (
            <span key={index}>
              {user}
              {index < deactivatedUsers.length - 1 ? ", " : ""}
            </span>
          ))}{" "}
          {deactivatedUsers.length > 1 ? "are" : "is"} deactivated and
          therefore cannot be added. Please contact customer support
          to reinstate any accounts.
        </p>
      )}
      {existingUsersState.length === 0 &&
        newUsersState.length === 0 &&
        deactivatedUsers.length === 0 && (
          <p className="text-center">
            It looks like you haven't added any users yet. Return to
            the previous step to add users to your team!
          </p>
        )}
    </>
  );
};

export default ConfirmNewUsers;
