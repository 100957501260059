import { useMutation } from "@apollo/client";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";

import ArrowLight from "../../assets/imgs/light-arrow.png";
import EDIT_USER from "../../graphql/mutations/EditUser.js";
import { useOnboarding, useWatchClicksOutside } from "../../hooks";
import Twemoji from "../../Twemoji";

export function FirstLoginHelperText() {
  const { ref, isClickedOutside, setIsClickedOutside } =
    useWatchClicksOutside();

  const [editUser] = useMutation(EDIT_USER);

  const {
    shouldShowDashboardHelperText,
    setShouldShowDashboardHelperText,
    refetchUserOnboardingInfo,
  } = useOnboarding();

  function completeOnboarding() {
    setShouldShowDashboardHelperText(false);
    setIsClickedOutside(false);
    editUser({
      variables: { isOnboardingComplete: true },
    }).then(() => {
      refetchUserOnboardingInfo();
    });
  }

  useEffect(() => {
    if (isClickedOutside) {
      completeOnboarding();
    }
  }, [isClickedOutside]);

  if (!shouldShowDashboardHelperText) {
    return null;
  }

  return (
    <>
      <div className="fixed z-30 top-0 left-0 w-screen h-screen bg-gray-900/40"></div>
      <div
        ref={ref}
        className="absolute z-40 top-1 right-10 flex flex-row items-center"
      >
        <div className="w-96 text-gray-700 bg-white border-2 border-indigo-500 dark:bg-gray-700 shadow-xl shadow-indigo-500/30 p-3 rounded-lg text-center">
          <p className="text-sm font-medium">
            Welcome to your Dashboard! If you&apos;d like to continue
            learning how Scribenote works, click the{" "}
            <FontAwesomeIcon icon={faQuestionCircle} /> button.
            Otherwise, you&apos;re all set up - enjoy!{" "}
            <Twemoji emoji={"🎉"} />
          </p>
          <button
            className="text-indigo-500 underline text-xs"
            onClick={() => {
              completeOnboarding();
            }}
          >
            Dismiss
          </button>
        </div>
        <img
          src={ArrowLight}
          className="w-6 ml-2 mb-2 -rotate-[120deg] animate-[arrow_1.5s_ease-in-out_infinite]"
        />
      </div>
    </>
  );
}
