import React from "react";
import { useNavigate } from "react-router";

import { TemplateItem } from "./TemplateItem.js";

function EmptyState() {
  const navigate = useNavigate();
  return (
    <h5
      size="sm"
      className="text-center text-gray-400 dark:text-white p-3"
    >
      You haven&#39;t created any templates yet. {"\n"}
      You can{" "}
      <span
        onClick={() => {
          navigate("/templates");
        }}
        className="underline hover:text-indigo-400 transition-all cursor-pointer"
      >
        create custom templates here.
      </span>{" "}
    </h5>
  );
}

export function TemplateList({
  data,
  refetch,
  isForTemplateSelection,
  isPreset,
}) {
  let presetTemplatesCount = 0;

  data?.user?.templates?.forEach((template) => {
    if (template.isPreset === isPreset) {
      presetTemplatesCount += 1;
    }
  });

  return (
    <>
      {!isPreset &&
      presetTemplatesCount === 0 &&
      isForTemplateSelection ? (
        <EmptyState />
      ) : null}
      {data?.user?.templates?.map((template, index) => {
        if (template.isPreset === isPreset) {
          return (
            <TemplateItem
              key={index}
              uuid={template.uuid}
              name={template.name}
              description={template.description}
              colorScheme={template.colorScheme}
              isPreset={isPreset}
              refetch={refetch}
              isForTemplateSelection={isForTemplateSelection}
            />
          );
        }
      })}
    </>
  );
}
