import React from "react";
import { useTransition, animated } from "react-spring";

import Loading from "./Loading";

export default function FullScreenLoading({ loadingTrigger }) {
  const fadeLoadingAnim = useTransition(loadingTrigger, {
    from: { opacity: 1, zIndex: 20 },
    enter: { opacity: 1, zIndex: 20 },
    leave: { opacity: 0, zIndex: 20 },
    config: { friction: 20 },
  });

  return fadeLoadingAnim(
    (styles, item) =>
      item && (
        <animated.div
          style={styles}
          className="fixed inset-0 transition-opacity"
        >
          <div className="absolute inset-0 bg-white dark:bg-gray-800"></div>
          <div className="flex items-center min-h-screen justify-center">
            <div className="relative inline-block my-4 align-middle max-w-lg w-full">
              <Loading shouldShow />
              <h4 className="text-center text-gray-400 mt-4">
                Loading...
              </h4>
            </div>
          </div>
        </animated.div>
      ),
  );
}
