import React from "react";

import { BasicButton } from "../buttons/BasicButton";

const JoyrideTooltip = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  skipProps,
  isLastStep,
}) => (
  <div
    {...tooltipProps}
    className="bg-white dark:bg-gray-800 w-60 md:w-80 border border-white rounded-xl p-3 md:p-5 flex flex-col"
  >
    {step.title && <h3>{step.title}</h3>}
    <div className="text-xs md:text-sm">
      <span className="text-xs md:text-sm dark:text-gray-200">
        {step.content}
      </span>
    </div>
    <div className="flex flex-row items-end mt-3 ">
      <button className="flex-1 text-left" {...skipProps}>
        <h5 className="text-xs text-gray-500 hover:text-red-600 transition-all focus:outline-none">
          Skip tour
        </h5>
      </button>
      <div className="flex flex-row items-center space-x-1">
        {index > 0 && (
          <BasicButton {...backProps}>
            <h5 id="back" className="text-white text-xs md:text-sm">
              ← Back
            </h5>
          </BasicButton>
        )}
        {continuous &&
          (isLastStep ? (
            <BasicButton {...primaryProps}>
              <h5 id="done" className="text-white text-xs md:text-sm">
                Done
              </h5>
            </BasicButton>
          ) : (
            <BasicButton {...primaryProps}>
              <h5 id="next" className="text-white text-xs md:text-sm">
                Next →
              </h5>
            </BasicButton>
          ))}
        {!continuous && (
          <BasicButton {...closeProps}>
            <h5 id="close" className="text-white text-xs md:text-sm">
              Close
            </h5>
          </BasicButton>
        )}
      </div>
    </div>
  </div>
);

export default JoyrideTooltip;
