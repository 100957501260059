import { useMutation } from "@apollo/client";
import {
  faThumbsDown,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextareaAutosize } from "@mui/base";
import React, { useState } from "react";

import ADD_FEEDBACK_TO_CUSTOMIZATION from "../../../../../../../graphql/mutations/AddFeedbackToCustomization";
import { alert } from "../../../../../../common/Alert.js";
import { ConfirmModal } from "../../../../../../common/modals/PopupModals";

const CustomizationFeedback = ({ llmCustomizationUuid }) => {
  //state
  const [submittedResult, setSubmittedResult] = useState(null);
  const [additionalFeedback, setAdditionalFeedback] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [addFeedbackToCustomization] = useMutation(
    ADD_FEEDBACK_TO_CUSTOMIZATION,
  );

  //handlers
  const handleSubmit = (result) => {
    setSubmittedResult(result);
    const message =
      result === "good"
        ? "Glad to hear that! We'll use this feedback to improve your future notes!"
        : "Sorry to hear that! We'll use this feedback to improve your future notes!";
    alert(result === "good" ? "success" : "warning", message);
    setShowModal(true);
  };

  const handleFeedbackSubmission = async (
    includeAdditionalFeedback = false,
  ) => {
    const addFeedbackInput = {
      llmCustomizationUuid,
      rating: submittedResult,
      feedback: includeAdditionalFeedback
        ? additionalFeedback
        : undefined,
    };

    try {
      await addFeedbackToCustomization({
        variables: { addFeedbackInput },
      });
      alert("success", "Feedback submitted successfully.");
    } catch (e) {
      alert("error", "Failed to submit feedback. Please try again.");
    } finally {
      setShowModal(false);
    }
  };

  return (
    <>
      <div className="flex justify-between items-center my-2 px-2">
        <p className="text-sm font-bold">
          What did you think of this result?
        </p>
        <div className="flex space-x-1">
          <button
            onClick={() => handleSubmit("good")}
            data-testid="thumbs-up-button"
            className={`${
              submittedResult === "good"
                ? "bg-green-400 text-white"
                : "text-gray-50 dark:text-indigo-900 hover:text-white dark:bg-green-700 bg-green-200 dark:hover:bg-green-600 hover:bg-green-400"
            } rounded-full h-7 w-7 shadow-sm transition-colors`}
          >
            <FontAwesomeIcon icon={faThumbsUp} />
          </button>
          <button
            onClick={() => handleSubmit("bad")}
            data-testid="thumbs-down-button"
            className={`${
              submittedResult === "bad"
                ? "bg-red-400 text-white"
                : "text-gray-50 dark:text-indigo-900 hover:text-white dark:bg-red-700 dark:hover:bg-red-600 bg-red-200 hover:bg-red-400"
            } rounded-full h-7 w-7 shadow-sm transition-colors`}
          >
            <FontAwesomeIcon icon={faThumbsDown} />
          </button>
        </div>
      </div>
      <ConfirmModal
        shouldShow={showModal}
        hideModal={() => handleFeedbackSubmission(false)}
        title={"Additional Feedback"}
        cancelText={"Skip"}
        cancelAction={() => handleFeedbackSubmission(false)}
        confirmAction={() => handleFeedbackSubmission(true)}
      >
        <TextareaAutosize
          minRows={3}
          placeholder={"Let us know how we can improve (optional)"}
          value={additionalFeedback}
          onChange={(e) => setAdditionalFeedback(e.target.value)}
          data-testid="feedback-textarea"
          className="resize-none appearance-none relative block w-full px-3 py-2 border dark:bg-gray-700 dark:border-gray-600 dark:text-gray-200 border-gray-200 bg-gray-200 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:z-10 text-sm"
        />
      </ConfirmModal>
    </>
  );
};

export default CustomizationFeedback;
