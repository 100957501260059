import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

import { AccountItem, AccountItemLine } from "./AccountItem";
import { BasicButton } from "../common/buttons/BasicButton";

export default function AnalyticsSettings() {
  const navigate = useNavigate();
  return (
    <div id="analyticsSection">
      <AccountItem
        title="Analytics"
        icon={
          <FontAwesomeIcon
            icon={faChartLine}
            size="lg"
            color="gray"
          />
        }
      >
        <AccountItemLine>
          <BasicButton
            onClick={() => {
              navigate(`/notebook/analytics/`);
            }}
          >
            View Patient Analytics
          </BasicButton>
        </AccountItemLine>
      </AccountItem>
    </div>
  );
}
