import { gql, useQuery } from "@apollo/client";
import {
  faMicrophone,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useEffect, useState } from "react";

import SleepyScribbles from "../../../assets/imgs/scribbles-sleep.svg";
import { useWatchNoteUpdates } from "../../../hooks";
import Loading from "../../common/loading/Loading";
import RecordingModal from "../../common/modals/RecordingModal";

export const GET_NOTE = gql`
  query getProcessingNote($uuid: String) {
    processingNote(uuid: $uuid) {
      isMarkedAsProcessing
    }
  }
`;

export function ProcessingNoteFormFallback({ note }) {
  const [isRefreshCheckAllowed, setIsRefreshCheckAllowed] =
    useState(false);

  const [shouldShowRecordingModal, setShouldShowRecordingModal] =
    useState(false);

  const queryOptions = {
    uuid: note?.uuid,
  };

  const { pollInterval } = useFlags();

  const { data, refetch, loading } = useQuery(GET_NOTE, {
    variables: queryOptions,
    fetchPolicy: "network-only",
    pollInterval: pollInterval ?? 10000,
  });

  useWatchNoteUpdates({
    refetch: () => refetch(queryOptions),
    loading,
  });

  useEffect(() => {
    // POTENTIAL RACE CONDITION: Click immediately after finishing a recording
    // POTENTIAL SOLUTION: don't let them click from the inbox for at least 10 seconds
    // Or, tell them not to check so soon
    if (data?.processingNote?.isMarkedAsProcessing == false) {
      setIsRefreshCheckAllowed(true);
    }
  }, [data?.processingNote?.isMarkedAsProcessing]);

  return (
    <>
      <RecordingModal
        shouldShow={shouldShowRecordingModal}
        setShouldShow={setShouldShowRecordingModal}
        isForEditNote={true}
        noteUuid={note?.uuid}
      />
      <div className="my-10 flex flex-col space-y-4 items-center py-20 border border-gray-100 dark:border-gray-600 dark:bg-gray-600/50 bg-gray-50 rounded-xl text-center">
        {isRefreshCheckAllowed ? (
          <>
            <img
              className="flex self-center"
              width="200px"
              src={SleepyScribbles}
            />
            <h4>All done!</h4>

            <h5 className="text-gray-700 max-w-3xl">
              Your automatic note is ready for review. Please reload
              the page to see it.
            </h5>
            <button
              type="button"
              className="text-sm h-10 px-4 flex items-center text-white font-medium justify-center bg-indigo-500 hover:bg-indigo-600 shadow-sm rounded-full transition-colors"
              onClick={() => {
                window.location.reload();
              }}
            >
              <FontAwesomeIcon icon={faSync} className="mr-2" />{" "}
              Reload
            </button>
          </>
        ) : (
          <>
            <Loading shouldShow />

            <h4>Hang tight!</h4>

            <h5 className="text-gray-700 max-w-3xl">
              Your automatic note is currently being written by our
              magic scribe fairies. You may still add to the
              recording, and we'll include the additional information.
            </h5>
            <button
              className="text-sm h-10 px-4 flex items-center text-white font-medium justify-center bg-indigo-500 hover:bg-indigo-600 shadow-sm rounded-full transition-colors"
              onClick={() => setShouldShowRecordingModal(true)}
            >
              <FontAwesomeIcon icon={faMicrophone} className="mr-2" />{" "}
              Add to Recording
            </button>
          </>
        )}
      </div>
    </>
  );
}
