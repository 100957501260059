import { gql, useMutation, useLazyQuery } from "@apollo/client";
import {
  faMicrophone,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import React, { useRef, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useLocation } from "react-router-dom";

import { alert } from "../../common/Alert";
import { ConfirmModal } from "../../common/modals/PopupModals";
import RecordingModal from "../../common/modals/RecordingModal";

const DELETE_NOTE_AUDIO_RECORDING = gql`
  mutation deleteNoteAudioRecording($noteUuid: String) {
    deleteNoteAudioRecording(noteUuid: $noteUuid) {
      ok
    }
  }
`;

export const GET_AUDIO = gql`
  query getAudio($uuid: String) {
    note(uuid: $uuid) {
      audioStorageLink
      isNewRecording
      isAutoSoap
      isMarkedAsProcessing
    }
  }
`;

export const AudioPlayer = ({
  noteUuid,
  shouldShowDeleteButton = true,
  shouldShowAddButton = true,
}) => {
  const audioRef = useRef();
  const [isEditingNote, setIsEditingNote] = useState(false);
  const [
    shouldShowDeleteRecordingModal,
    setShouldShowDeleteRecordingModal,
  ] = useState(false);
  const [shouldShowRecordingModal, setShouldShowRecordingModal] =
    useState(false);
  const location = useLocation();

  const [getAudio, { data, refetch }] = useLazyQuery(GET_AUDIO, {
    fetchPolicy: "network-only",
  });

  const [deleteNoteAudioRecording] = useMutation(
    DELETE_NOTE_AUDIO_RECORDING,
  );

  useEffect(() => {
    const pageName = location?.pathname.split("/")[1];

    setIsEditingNote(
      pageName === "edit_note" || pageName === "super_soap",
    );
  }, [location]);

  useEffect(() => {
    if (noteUuid) {
      getAudio({
        variables: {
          uuid: noteUuid,
        },
      });
    }
  }, [noteUuid]);

  useEffect(() => {
    if (data?.note?.audioStorageLink) {
      audioRef.current.load();
    }
  }, [data]);

  function hideModal() {
    setShouldShowDeleteRecordingModal(false);
  }

  function renderAudioPlayer() {
    if (data?.note?.audioStorageLink) {
      return (
        <audio
          id="existingRecordingControls"
          className="w-full rounded-lg"
          controls
          ref={audioRef}
        >
          <source src={data.note.audioStorageLink} type="audio/wav" />
          Your browser does not support the
          <code>audio</code> element.
          <a href={data.note.audioStorageLink}>
            Click here to download the full appointment audio
          </a>
        </audio>
      );
    } else {
      return null;
    }
  }

  return (
    <>
      <RecordingModal
        shouldShow={shouldShowRecordingModal}
        setShouldShow={setShouldShowRecordingModal}
        isForEditNote={isEditingNote}
        noteUuid={noteUuid}
      />
      {createPortal(
        <ConfirmModal
          shouldShow={shouldShowDeleteRecordingModal}
          hideModal={hideModal}
          title={"Delete Recording"}
          cancelText={"Never Mind"}
          confirmText={"Yes, Delete"}
          cancelAction={() => hideModal()}
          confirmAction={() => {
            if (data?.note?.audioStorageLink) {
              deleteNoteAudioRecording({
                variables: { noteUuid: noteUuid },
              })
                .then(() => {
                  alert(
                    "success",
                    "Audio recording deleted successfully.",
                  );
                  refetch({ uuid: noteUuid });
                })
                .catch(() => {
                  alert(
                    "error",
                    "An error occurred. Please try again later or contact support.",
                  );
                });
            }
            hideModal();
          }}
        >
          <h5>
            Are you sure you&apos;d like to{" "}
            <strong className="text-red-500">
              delete the recording
            </strong>{" "}
            associated with this note? This can&apos;t be undone.
          </h5>
        </ConfirmModal>,
        document.getElementById("root-node"),
      )}
      <div className="flex flex-row items-center space-x-1">
        {renderAudioPlayer()}
        {shouldShowAddButton && data?.note?.audioStorageLink ? (
          <Tooltip
            title="Click to make a follow-up recording. It will be combined with the original recording."
            arrow
            placement="top"
          >
            <span>
              <button
                className="text-sm h-10 px-4 flex items-center text-white font-medium justify-center bg-indigo-500 hover:bg-indigo-600 shadow-md rounded-full transition-colors"
                onClick={() => setShouldShowRecordingModal(true)}
              >
                <FontAwesomeIcon
                  icon={faMicrophone}
                  className="mr-2"
                />{" "}
                Add
              </button>
            </span>
          </Tooltip>
        ) : null}
        {data?.note?.audioStorageLink &&
          !data?.note?.isAutoSoap &&
          !data?.note?.isNewRecording &&
          !data?.note?.isMarkedAsProcessing &&
          shouldShowDeleteButton && (
            <button
              title="Delete recording"
              className="text-sm h-10 px-4 flex items-center justify-center bg-gray-400 hover:bg-red-400 rounded-full shadow-sm transition-colors"
              onClick={() => setShouldShowDeleteRecordingModal(true)}
            >
              <FontAwesomeIcon icon={faTimes} color="white" />
            </button>
          )}
      </div>
    </>
  );
};
