import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, AvatarGroup, Tooltip } from "@mui/material";

import { AccountItem, AccountItemLine } from "../AccountItem";
import SelectTeam from "./SelectTeam";
import { useTeam } from "../../../hooks/use-team";

export default function TeamSettings({ rootUser }) {
  const { currentTeam } = useTeam();
  if (!currentTeam?.uuid) {
    return null;
  }

  return (
    <AccountItem
      title="Team"
      icon={<FontAwesomeIcon icon={faUsers} size="lg" color="gray" />}
    >
      <div className="flex-1 flex mr-4 md:space-x-4">
        <div className="flex-1 divide-y dark:divide-gray-700">
          <AccountItemLine title="Current Team Name:">
            {currentTeam?.name}
            <AvatarGroup max={8}>
              {currentTeam?.users?.map((user) => (
                <Tooltip
                  key={user}
                  arrow
                  placement="top"
                  title={`${user?.firstName}
                ${user?.lastName}`}
                >
                  <Avatar
                    sx={{ width: 24, height: 24, fontSize: 10 }}
                  >
                    {user?.firstName.charAt(0)}
                    {user?.lastName.charAt(0)}
                  </Avatar>
                </Tooltip>
              ))}
            </AvatarGroup>
          </AccountItemLine>
          <AccountItemLine title="Switch Current Team:">
            <SelectTeam className="mt-2" rootUser={rootUser} />
          </AccountItemLine>
        </div>
      </div>
    </AccountItem>
  );
}
