import { useMutation } from "@apollo/client";
import moment from "moment";
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
} from "react";

import { useAuth } from "./use-auth";
import { LINK_TEAM_MEMBER } from "../graphql/mutations/TeamsUsers";

const TeamMemberContext = createContext();
const MS_IN_DAY = 86400000;

export const TeamMemberProvider = ({ children }) => {
  const [selectedLinkedUser, setSelectedLinkedUser] = useState(
    localStorage.getItem("user_uuid") || "",
  );
  const [selectedUserName, setSelectedUserName] = useState("");
  const [linkToTeamMember] = useMutation(LINK_TEAM_MEMBER);
  const { userUuid } = useAuth();

  useEffect(() => {
    if (userUuid && !selectedLinkedUser) {
      setSelectedLinkedUser(userUuid);
    }
  }, [userUuid]);

  useEffect(() => {
    const storedUserName = localStorage.getItem("user_name");
    if (storedUserName) {
      setSelectedUserName(storedUserName);
    }
  }, []);

  const linkMember = async (linkedUserUuid) => {
    try {
      const response = await linkToTeamMember({
        variables: { linkedUserUuid },
      });
      const usersName = `${response?.data?.linkToTeamMember?.user?.firstName} ${response?.data?.linkToTeamMember?.user?.lastName}`;
      setSelectedUserName(usersName);
      setSelectedLinkedUser(linkedUserUuid);
      localStorage.setItem("user_uuid", linkedUserUuid);
      localStorage.setItem("user_name", usersName);
      localStorage.setItem("auth_expiry", moment.now() + MS_IN_DAY);
      localStorage.setItem(
        "accessCsrf",
        response?.data?.linkToTeamMember?.accessCsrf,
      );
      return {
        success: true,
        user: response?.data?.linkToTeamMember?.user,
      };
    } catch (error) {
      console.log(error);
      return { success: false, error: error.message };
    }
  };

  const returnToRootUser = async () => {
    const rootUserUuid = localStorage.getItem(
      "root_logged_in_user_uuid",
    );
    return linkMember(rootUserUuid);
  };

  return (
    <TeamMemberContext.Provider
      value={{
        selectedLinkedUser,
        selectedUserName,
        linkMember,
        returnToRootUser,
      }}
    >
      {children}
    </TeamMemberContext.Provider>
  );
};

export const useTeamMember = () => {
  return useContext(TeamMemberContext);
};
