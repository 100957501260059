import { useMutation } from "@apollo/client";
import { faMagic } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";

import { AccountItem, AccountItemBlock } from "./AccountItem.js";
import { ObjectiveNormalDefaultsForm } from "./ObjectiveNormalDefaultsForm.js";
import EDIT_USER from "../../graphql/mutations/EditUser.js";

function AutoSOAPSettingItem({ onChange, valueToSet, title, desc }) {
  return (
    <div
      onClick={onChange}
      className={`flex-1 ${
        valueToSet ? "bg-indigo-50 dark:bg-gray-700" : ""
      } dark:hover:bg-gray-700 hover:bg-indigo-50 rounded-xl p-3 cursor-pointer h-full`}
    >
      <div className="flex flex-row items-center space-x-2">
        <input
          onChange={onChange}
          checked={valueToSet}
          type={"radio"}
          value={valueToSet}
        />
        <label>
          <h5
            className={`font-semibold cursor-pointer ${
              valueToSet ? "dark:text-indigo-400 text-indigo-700" : ""
            }`}
          >
            {title}
          </h5>
        </label>
      </div>
      <h5 className="text-xs text-gray-400 dark:text-gray-400">
        {desc}
      </h5>
    </div>
  );
}

function AutoSoapSettings({
  accountType,
  shouldUseAutoSoaps,
  updateSetting,
  useWrapper = true,
}) {
  let planDerivedDescription =
    "Your recordings will be automatically converted to SOAPs. ";

  if (accountType !== "pro" || accountType !== "unlimited") {
    // FLAG FOR ONE PLAN
    planDerivedDescription =
      "With this setting on, your first recording of the day will automatically be converted to a SOAP note. After that, your recordings will be saved as standard recordings. To unlock unlimited daily Auto-Notes, upgrade to a paid plan. ";
  }

  const desc =
    planDerivedDescription +
    "This will also enable a recording interface on the Dashboard of your Scribenote mobile app.";

  if (useWrapper) {
    return (
      <div id="autoSOAPSection">
        <AccountItem
          title="Auto-Notes"
          icon={
            <FontAwesomeIcon icon={faMagic} size="lg" color="gray" />
          }
        >
          <div className="w-full flex flex-col items-center space-y-4 divide-y dark:divide-gray-700">
            <AccountItemBlock
              title="Enable Auto-Notes:"
              desc="By default, all recordings you make will be Auto-Notes, meaning they will be turned into a note automatically. You can choose to disable this if you'd like your recordings to just be recordings."
            >
              <div className="flex flex-col md:flex-row md:items-center space-y-4 md:space-y-0 md:space-x-4 h-full">
                <AutoSOAPSettingItem
                  onChange={() => updateSetting(true)}
                  valueToSet={shouldUseAutoSoaps}
                  title="Use Automation"
                  desc={desc}
                />
                <AutoSOAPSettingItem
                  onChange={() => updateSetting(false)}
                  valueToSet={!shouldUseAutoSoaps}
                  title="Don't Use Automation"
                  desc="Your recordings will still be saved on Scribenote, but
        will not be automatically converted into notes."
                />
              </div>
            </AccountItemBlock>

            <ObjectiveNormalDefaultsForm />
          </div>
        </AccountItem>
      </div>
    );
  } else {
    return (
      <div className="flex flex-col items-center">
        <div className="flex flex-col md:flex-row md:items-center space-y-4 md:space-y-0 md:space-x-4 h-full">
          <AutoSOAPSettingItem
            onChange={() => updateSetting(true)}
            valueToSet={shouldUseAutoSoaps}
            title="Use Automation"
            desc={desc}
          />
          <AutoSOAPSettingItem
            onChange={() => updateSetting(false)}
            valueToSet={!shouldUseAutoSoaps}
            title="Don't Use Automation"
            desc="Your recordings will still be saved on Scribenote, but
  will not be automatically converted into notes."
          />
        </div>
      </div>
    );
  }
}

/**
 *
 * @param {*} shouldUseAutoSoaps Theusers account level setting
 * @returns
 */
export function AutoSoapSettingsAccount({ accountType, shouldUse }) {
  const [shouldUseAutoSoaps, setShouldUseAutoSoaps] =
    useState(shouldUse);

  const [editUser] = useMutation(EDIT_USER);

  useEffect(() => {
    // Ensure parity with database results
    if (shouldUse != shouldUseAutoSoaps) {
      setShouldUseAutoSoaps(shouldUse);
    }
  }, [shouldUse]);

  function updateSetting(selection) {
    setShouldUseAutoSoaps(selection);
    editUser({
      variables: {
        shouldUseAutoSoaps: selection,
      },
    });
  }

  return (
    <AutoSoapSettings
      accountType={accountType}
      shouldUseAutoSoaps={shouldUseAutoSoaps}
      updateSetting={updateSetting}
    />
  );
}

/**
 * This differs from the account settings.
 * Here, by default on render, we set the user to use Automatic Notes (fka Auto-SOAPs).
 * On account, we don't do that.
 */
export function AutoSoapSettingsOnboarding() {
  const [shouldUseAutoSoaps, setShouldUseAutoSoaps] = useState(true);
  const [editUser] = useMutation(EDIT_USER);

  function updateSetting(selection) {
    setShouldUseAutoSoaps(selection);
    editUser({
      variables: {
        shouldUseAutoSoaps: selection,
      },
    });
  }

  useEffect(() => {
    updateSetting(true);
  }, []);

  return (
    <AutoSoapSettings
      accountType={"basic"}
      shouldUseAutoSoaps={shouldUseAutoSoaps}
      updateSetting={updateSetting}
      useWrapper={false}
    />
  );
}
