import { useQuery } from "@apollo/client";
import {
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import NewPatient from "./NewPatient.js";
import { PatientSearchBar } from "./PatientSearchBar.js";
import { patientSteps } from "../../assets/data/JoyrideTourSteps.js";
import {
  GET_PATIENT_ANALYTICS,
  PatientFilteringOptions,
} from "../analytics/PatientAnalytics.js";
import JoyrideTour from "../common/modals/JoyrideTour.js";
import PageTitle from "../layout/PageTitle.js";

function Patients({
  isForPatientSelectionAction = false,
  setSelectedPatientName,
  setSelectedPatientUuid,
}) {
  const navigate = useNavigate();
  const [sortBy, setSortBy] = useState("name");
  const [filterBy, setFilterBy] = useState("none");
  const [ascending, setAscending] = useState(true);
  const [filteredItems, setFilteredItems] = useState([]);
  const [shouldShowFilteringOptions, setShouldShowFilteringOptions] =
    useState(false);

  let location = useLocation();
  const [shouldShow, setShouldShow] = useState(
    location?.state?.shouldShow,
  );
  const [runJoyrideTour, setRunJoyrideTour] = useState(false);

  const { refetch, data, loading } = useQuery(GET_PATIENT_ANALYTICS, {
    variables: {
      sortBy,
      filterBy,
      ascending,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    refetch();
  }, [sortBy, filterBy, ascending]);

  useEffect(() => {
    if (data && !loading) {
      setFilteredItems(data?.patientAnalytics);
    }
  }, [data, loading]);

  const loadingTrigger = loading || data === undefined;

  function onPatientClick(uuid) {
    if (!isForPatientSelectionAction) {
      navigate(`/notebook/${uuid}`);
    }
  }

  if (!loading && !data) {
    return null;
  } else {
    return (
      <div>
        <JoyrideTour
          steps={patientSteps}
          runJoyrideTour={runJoyrideTour}
          setRunJoyrideTour={setRunJoyrideTour}
        />

        {isForPatientSelectionAction ? null : (
          <PageTitle
            title="Patient Notebook"
            onClick={() => {
              setRunJoyrideTour(true);
            }}
            showHelpButton
          />
        )}
        <div className="flex w-full pt-5 flex-row">
          <div
            id="patientSearch"
            className="flex-1 w-full mr-2 md:mr-10"
          >
            <div className="w-full flex flex-col">
              <button
                className="self-end mb-2"
                onClick={() =>
                  setShouldShowFilteringOptions(
                    !shouldShowFilteringOptions,
                  )
                }
              >
                <h5 className="text-xs: md:text-sm dark:text-gray-500 hover:text-gray-600 dark:hover:text-gray-600">
                  Advanced Search{" "}
                  <FontAwesomeIcon
                    icon={
                      shouldShowFilteringOptions
                        ? faChevronUp
                        : faChevronDown
                    }
                    size="sm"
                  />
                </h5>
              </button>

              {shouldShowFilteringOptions ? (
                <PatientFilteringOptions
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                  filterBy={filterBy}
                  setFilterBy={setFilterBy}
                  ascending={ascending}
                  setAscending={setAscending}
                  refetch={refetch}
                />
              ) : null}
            </div>

            <PatientSearchBar
              onPatientClick={onPatientClick}
              isForPatientSelectionAction={
                isForPatientSelectionAction
              }
              setSelectedPatientUuid={setSelectedPatientUuid}
              setSelectedPatientName={setSelectedPatientName}
              itemsAsObjects={filteredItems}
              loadingTrigger={loadingTrigger}
            />
          </div>
          <div
            id="newPatient"
            className="flex flex-grow-0 self-end md:self-center"
          >
            <NewPatient
              isForPatientSelectionAction={
                isForPatientSelectionAction
              }
              setSelectedPatientUuid={setSelectedPatientUuid}
              setSelectedPatientName={setSelectedPatientName}
              shouldShow={shouldShow}
              setShouldShow={setShouldShow}
              dataCy="newPatientBtn"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Patients;
