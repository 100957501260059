import axios from "axios";

import { getTempScribenoteFlask } from "../utils";
// import { SCRIBENOTE_FLASK } from "../constants.js";

async function postS3(endpoint) {
  const csrf_token = localStorage.getItem("accessCsrf");

  return await axios.get(endpoint, {
    headers: {
      "Access-Control-Allow-Origin": true,
      "X-CSRF-Token": csrf_token,
    },
    withCredentials: true,
  });
}

export default async function signS3() {
  const SCRIBENOTE_FLASK = getTempScribenoteFlask();

  return await postS3(`${SCRIBENOTE_FLASK}/sign_s3/`);
}

export async function signS3Attachment(fileType) {
  const SCRIBENOTE_FLASK = getTempScribenoteFlask();

  return await postS3(
    `${SCRIBENOTE_FLASK}/sign_s3_attachment/?fileType=${fileType}`,
  );
}
