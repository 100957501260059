import {
  faCheck,
  faEdit,
  faEnvelope,
  faFileDownload,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import Twemoji from "../../Twemoji.js";

const textClassName = "text-xs md:text-sm";

export const inboxStepsBasic = [
  {
    target: "body",
    placement: "center",
    content:
      "On this page, you can see all of your drafts and completed notes ready to be exported.",
    disableBeacon: true,
  },
  {
    target: "#inboxTabs",
    spotlightClicks: true,
    content:
      "Here, you can see the different statuses separated into tabs.",
  },
  {
    target: "#savedDrafts",
    content:
      "These are notes you've saved as drafts. You can select one to keep editing it if you wish.",
  },
  {
    target: "#inboxTabs",
    spotlightClicks: true,
    content:
      "Now, click the 'Ready to Export' tab, and then press Next to continue the tour.",
  },
  {
    target: "#forExport",
    content: (
      <span className={textClassName}>
        When a note has been approved, it will show up here and on the
        Export Notes page. You can export it using the export icons ({" "}
        <FontAwesomeIcon icon={faCheck} />{" "}
        <FontAwesomeIcon icon={faFileDownload} />{" "}
        <FontAwesomeIcon icon={faEnvelope} /> ) in the top right
        corner.
      </span>
    ),
  },
  {
    target: "#forExport",
    placement: "center",
    content: (
      <span className={textClassName}>
        Exporting a note or clicking{" "}
        <FontAwesomeIcon icon={faCheck} /> will mark it as exported.
        Don&#39;t worry, it&#39;s not lost, simply go to the Export
        Notes page to see all your previously exported notes.
      </span>
    ),
  },
];

export const inboxStepsAuto = [
  {
    target: "body",
    placement: "center",
    content:
      "On this page, you can see all of your notes and recordings which have yet to be exported, organized by their status.",
    disableBeacon: true,
  },
  {
    target: "#inboxTabs",
    spotlightClicks: true,
    content:
      "Here, you can see the different statuses separated into tabs.",
  },
  {
    target: "#savedDrafts",
    content:
      "These are notes you've saved as drafts. You can select one to keep editing it if you wish.",
  },
  {
    target: "#inboxTabs",
    spotlightClicks: true,
    content:
      "Click the 'Submitted for Scribing' tab, and then press Next to continue the tour.",
  },
  {
    target: "#nowScribing",
    content:
      "These are your summary recordings that have been submitted to us to be scribed. You can't edit them at the moment, but you'll be able to do that when they are available for review.",
  },
  {
    target: "#inboxTabs",
    spotlightClicks: true,
    content:
      "Now, click the 'Available for Review' tab, and then press Next to continue the tour.",
  },
  {
    target: "#forReview",
    content:
      "These are the notes that have been created by our Scribes from your summary recordings. You can click on any of the notes here to view and edit it, then you can approve or reject it. Rejecting will send it back to our Scribes to be edited.",
  },
  {
    target: "#inboxTabs",
    spotlightClicks: true,
    content:
      "Finally, click the 'Ready to Export' tab, and then press Next to continue the tour.",
  },
  {
    target: "#forExport",
    content: (
      <span className={textClassName}>
        When a note has been approved, it will show up here and on the
        Export Notes page. You can export it using the export icons ({" "}
        <FontAwesomeIcon icon={faCheck} />{" "}
        <FontAwesomeIcon icon={faFileDownload} />{" "}
        <FontAwesomeIcon icon={faEnvelope} /> ) in the top right
        corner.
      </span>
    ),
  },
  {
    target: "#forExport",
    placement: "center",
    content: (
      <span className={textClassName}>
        Exporting a note or clicking{" "}
        <FontAwesomeIcon icon={faCheck} /> will mark it as exported.
        Don&#39;t worry, it&#39;s not lost, simply go to the Export
        Notes page to see all your previously exported notes.
      </span>
    ),
  },
];

export const dashboardSteps = [
  {
    target: "#header",
    content:
      "First of all, this is the navbar. This is where you'll be able to find and access important tools quickly, such as your account.",
    placement: "bottom",
    disableBeacon: true,
  },
  {
    target: "#exportNotesHeader",
    content:
      "Use this button to navigate to the Export Notes page, where you can see all your recorded notes and export them to PDF in bulk.",
  },
  {
    target: "#quicknoteHeader",
    content:
      "Use this button to navigate to the Quicknotes page, where you can quickly create a new recording without having to navigate to a patient's page.",
  },
  {
    target: "#quickSOAPHeader",
    content:
      "Use this button to quickly start a new typed SOAP note.",
  },
  {
    target: "#noteUsageCounter",
    content:
      "This counter will show the number of notes and recordings you have created out of your total limit.",
  },
  {
    target: "#options-menu",
    content:
      "Click here to open a useful dropdown menu. You'll find quick links to all of Scribenote's main pages, as well as the Log out button.",
  },
  {
    target: "#patients",
    content:
      "Click here to navigate to the patient notebook. This is where you'll be able to see your existing patients and their respective notes, or create new patients.",
  },
  {
    target: "#newNote",
    content:
      "Click here to create a new typed note. Quickly jot down any notes using the Quicknote feature, or select a patient and begin writing notes for them.",
  },
  {
    target: "#templates",
    content:
      "Click here to go to the templates page. This is where you can view all your preset and/or custom templates, as well as create new ones.",
  },
  {
    target: "#exports",
    content:
      "This button will also take you to the export notes page where you can see all your recorded notes and export them to PDF in bulk.",
  },
  {
    target: "#recorderTitle",
    content:
      "Add a custom title here. If you don't we will auto-generate an accurate title for you!",
  },
  {
    target: "#dashboardRecorder",
    content:
      "Generate a medical record, recap, callback, or dictation using this button!",
  },
  {
    target: "#dashboardInbox",
    placement: "left",
    content:
      "Here is the Inbox, where you will see recordings and drafts you have recently saved. Select any of them to begin writing a SOAP note for that particular note.",
  },
  {
    target: "#inboxExpandButton",
    placement: "left",
    content:
      "You can click this button to expand the inbox to a full-page view, where your notes are neatly organized into categories.",
  },
  {
    target: "#superSOAPButton",
    placement: "left",
    content:
      "Super SOAP will allow you to see all your unfinished notes stacked up in one convenient place, so you can easily knock them out one-by-one without having to navigate back and forth. This will only show if you have more than one note in your inbox.",
  },
  {
    target: "#dashboardJoyride",
    content:
      "Last but not least, if you need to see this tour again at any time, you can use this icon.",
  },
];

export const sharedExportSteps = [
  {
    target: "#exportNoteIcon",
    content: (
      <span className={textClassName}>
        Click here to download a PDF of this note! You will then be
        able to import it into your EMR, attach/merge it to existing
        patient files, or just print it as a paper copy. Whatever
        floats your documentation boat! <Twemoji emoji="😄" />
      </span>
    ),
    disableBeacon: true,
  },
];

export const exportNotesSteps = [
  {
    target: "body",
    placement: "center",
    content:
      "On this page, you can export your notes individually or in bulk. It's also handy for viewing which notes you've already exported.",
    disableBeacon: true,
  },
  {
    target: "#exportNotesTabs",
    content:
      "These tabs allow you to switch between your new, never-before-exported notes, and the notes you've exported in the past.",
  },
  {
    target: "#newNotesSection",
    content:
      "In this area you can find newly completed notes that haven't been exported yet.",
  },
  {
    target: "#exportNotesTabs",
    spotlightClicks: true,
    content:
      "Now, click the 'Previously Exported Notes' tab, and then press Next to continue the tour.",
  },
  {
    target: "#prevExportedNotesSection",
    content:
      "In this area, you'll find notes you've exported before.",
  },
  {
    target: "#exportAllButton",
    placement: "top",
    content:
      "Clicking this button will allow you to export notes in bulk to a .zip file.",
    disableBeacon: true,
  },
];

export const notebookStepsCore = [
  {
    target: "#patientTitle",
    content: (
      <span className={textClassName}>
        Congrats, you&apos;ve made a patient on Scribenote!{" "}
        <Twemoji emoji="🎉" /> If you hover over their name,
        you&apos;ll be able to see more details about them.{" "}
      </span>
    ),
    disableBeacon: true,
    placement: "right",
  },
  {
    target: "#editPatientButton",
    content:
      "If you click here, you'll be taken to a pop-up where you can edit any existing patient data, such as their name, age, intact status, and more. This is also where you can delete this patient if need be.",
  },
  {
    target: "#patientSummary",
    content:
      "This is where you can see key patient data such as medications and vaccination history. Relevant data from categorized notes is automatically added here.",
  },
  {
    target: "#patientSummary",
    content:
      "You can also add key info here manually by clicking 'Add Key Info'. This type of key info will be populated at the top of the sections.",
  },
  {
    target: "#patientNotebookTabs",
    content:
      "Here, you can switch between the patient's key info and their attachments. Try clicking on 'Attachments'! Then, click 'Next →' to proceed.",
    spotlightClicks: true,
  },
  {
    target: "#patientAttachments",
    content:
      "Here, you'll see a preview of all the patient's attachments, including those associated with their notes. You can click 'View All Attachments' to navigate to the attachments page, where you can view attachments in full-scale and upload new ones.",
  },
  {
    target: "#patientNotes",
    content:
      "Here's where complete notes show up when they've been tagged and approved by you.",
  },
  {
    target: "#notesCard-0",
    content:
      "Here is an approved note. You can see the tagged SOAP fields, any attachments on the note, as well as listen to the audio recording (if one exists). 🎶",
  },
  {
    target: "#notesCard-0",
    placement: "top",
    content: (
      <span className={textClassName}>
        See the three little icons ( <FontAwesomeIcon icon={faEdit} />{" "}
        <FontAwesomeIcon icon={faFileDownload} />{" "}
        <FontAwesomeIcon icon={faEnvelope} /> ) in the top right
        corner? Click them to edit, download, or email this note,
        respectively.
      </span>
    ),
  },
  {
    target: "#recordButtonNotebook",
    placement: "right",
    content: (
      <span className={textClassName}>
        Last but certainly not least, here&apos;s where the magic
        happens! <Twemoji emoji="😉" /> Click this button to start
        recording an appointment! As soon as you click stop, the
        appointment audio will be uploaded to our servers and
        categorized by scribes.
      </span>
    ),
  },
];

export const notebookStepsBasic = [
  {
    target: "#patientTitle",
    content: (
      <span className={textClassName}>
        Congrats, you&apos;ve made a patient on Scribenote!{" "}
        <Twemoji emoji="🎉" /> If you hover over their name,
        you&apos;ll be able to see more details about them.{" "}
      </span>
    ),
    disableBeacon: true,
    placement: "right",
  },
  {
    target: "#editPatientButton",
    content:
      "If you click here, you'll be taken to a pop-up where you can edit any existing patient data, such as their name, age, intact status, and more. This is also where you can delete this patient if need be.",
  },
  {
    target: "#patientSummary",
    content:
      "This is where you can see key patient data such as medications and vaccination history. Relevant data from the notes you've created gets added here.",
  },
  {
    target: "#patientSummary",
    content:
      "You can also add key info here manually by clicking 'Add Key Info'. This type of key info will be populated at the top of the sections.",
  },
  {
    target: "#patientNotes",
    content: "Here's where the notes you've created will show up.",
  },
  {
    target: "#createNewPatientSOAPButton",
    content:
      "If you click here, you'll be able to fill out a new blank SOAP note for this patient without creating a recording.",
  },
  {
    target: "#notesCard-0",
    content:
      "Here is a note. You can see the SOAP fields, as well as listen to the audio recording below if you've recorded an appointment for that note. 🎶",
  },
  {
    target: "#notesCard-0",
    placement: "top",
    content: (
      <span className={textClassName}>
        See the three little icons ( <FontAwesomeIcon icon={faEdit} />{" "}
        <FontAwesomeIcon icon={faFileDownload} />{" "}
        <FontAwesomeIcon icon={faEnvelope} /> ) in the top right
        corner? Click them to edit, download, or email this note,
        respectively.
      </span>
    ),
  },
  {
    target: "#recordButtonNotebook",
    placement: "right",
    content: (
      <span className={textClassName}>
        Last but certainly not least, here&apos;s where the magic
        happens! <Twemoji emoji="😉" /> Click this button to start
        recording an appointment! As soon as you click stop, the
        appointment audio will be uploaded to our servers. You can
        then write up a SOAP note based on that recording.
      </span>
    ),
  },
  // {
  //   target: "#recordButtonNotebook",
  //   placement: "right",
  //   content: (
  //     <iframe
  //       width="280"
  //       height="200"
  //       src="https://www.youtube.com/embed/feMwFuihX2o"
  //       frameBorder="0"
  //       allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  //       title="Embedded youtube"
  //     />
  //   ),
  // },
];

export const attachmentSteps = [
  {
    target: "#attachmentViewer",
    placement: "center",
    content:
      "On this page, you can view and compare attachments, download them, or upload new attachments for this patient.",
    disableBeacon: true,
  },
  {
    target: "#patientAttachmentUpload",
    content:
      "To begin, let's upload an attachment. Click the attachment dropbox to browse for a file. When you've selected a file, click 'Submit'.",
    spotlightClicks: true,
  },
  {
    target: "#patientAttachmentPreview",
    content:
      "You'll see it has been added to this patient's attachments. Click on that attachment to view it full-scale.",
    spotlightClicks: true,
  },
  {
    target: "#attachmentViewer",
    content:
      "Here it is! Depending on the type of file you've selected, you'll be able to interact with it here.",
  },
  {
    target: "#attachmentActionButtons",
    content: (
      <span className={textClassName}>
        You&apos;ll have several options for this attachment, such as
        downloading it or deleting it. Here&apos;s another neat thing,
        if you want to compare two files side-by-side, toggle the
        &apos;Split View&apos; option on. You will then be able to
        select two files and view them together! <br /> Note: if you
        don&apos;t see this option, your screen size is too small{" "}
        <Twemoji emoji="😢" />
      </span>
    ),
  },
];

export const patientSteps = [
  {
    target: "#patientSearch",
    content:
      "Start typing here to search for a patient, then select from the filtered list. Or, you can click the down arrow on the right to see a list of all your patients.",
    disableBeacon: true,
  },
  {
    target: "#newPatient",
    content: (
      <span className={textClassName}>
        Click here to add a patient to Scribenote{" "}
        <Twemoji emoji="🦄" />
      </span>
    ),
  },
];

export const quicknoteSteps = [
  {
    target: "#quicknoteRecord",
    content: "Click this button to start recording a Quicknote!",
    disableBeacon: true,
  },
  {
    target: "#quicknoteNotesSection",
    content:
      "This section shows the quicknotes you have created. You can move, copy, or delete one or more quicknotes to a patient by clicking the checkboxes beside each note, then selecting an option.",
  },
];

export const selectNoteSteps = [
  {
    target: "#selectNoteCard-0",
    content: "Select a note to start tagging it.",
    disableBeacon: true,
  },
];

export const accountSteps = [
  {
    target: "#accountOverview",
    placement: "center",
    content:
      "Here's your account, where you can see all of your information such as your profile, subscription details, and billing details.",
    disableBeacon: true,
  },
  {
    target: "#accountSubscriptionOptions",
    content: (
      <span className={textClassName}>
        Upgrade your Scribenote plan here, or cancel your subscription
        if you need to. <Twemoji emoji="😿" />
      </span>
    ),
  },
  {
    target: "#accountAutoExport",
    content:
      "In this section, you can specify an email address to which notes will automatically be sent when they are submitted.",
  },
  {
    target: "#headerImageUpload",
    content:
      "These settings will allow you to change the default logo that shows on PDF exports. You may upload your own image to replace it, such as your own clinic logo.",
  },
  {
    target: "#preferences",
    content: (
      <span className={textClassName}>
        Do you do a lot of work at night? Ease the strain on your eyes
        by enabling dark mode here. (We like this feature a lot){" "}
        <Twemoji emoji="😎" />
      </span>
    ),
  },
  {
    target: "#revisionsSection",
    content:
      "You can see all your note and patient revision history by clicking the button here.",
  },
  {
    target: "#accountDelete",
    content: (
      <span className={textClassName}>
        If you really don&apos;t need Scribenote anymore... click here{" "}
        <Twemoji emoji="😿" />
      </span>
    ),
  },
];

export const newNoteOptionSteps = [
  {
    target: "#newNoteQuicknoteOption",
    content:
      "Click here to create a new Quicknote. Select this option if you don't have a profile created for your patient yet.",
    disableBeacon: true,
  },
  {
    target: "#newNotePatientOption",
    content:
      "Click here to create a new note for a specific patient. You will be prompted to select that patient from your patient list.",
  },
];

export const newNoteSteps = [
  {
    target: "body",
    placement: "center",
    content:
      "On this page you will be able to write up a SOAP note. Fill out any information you need to, whether it's Subjective, Objective, Assessment, or Plan.",
    disableBeacon: true,
  },
  {
    target: "#autocompleteToggle",
    content:
      "This switch toggles the autocomplete functionality. With this ON, you will see autocomplete suggestions appear as you type in the SOAP form fields. You can press Enter or Tab when these suggestions appear and the rest of the word will be entered for you. ",
  },
  {
    target: "#useTemplate",
    content:
      "Click here to select a template to pre-fill the SOAP form with. Be careful, as selecting a template will overwrite anything already in the fields.",
  },
  {
    target: "#editDate",
    content:
      "By default, the note's date will be set when it is created. In case you are writing a note for a past appointment, clicking here will allow you to edit the note's date.",
  },
  {
    target: "#quicknoteInfo",
    content:
      "In this section, you can fill in any relevant data about your patient, just to remind yourself who this appointment is for.",
  },
  {
    target: "#useBlankObjectiveToggle",
    content:
      "This toggle will switch the objective section into a blank text box, like the ones you see for the Subjective, Assessment, and Plan sections.",
  },
  {
    target: "#editFieldsToggle",
    spotlightClicks: true,
    content:
      "This toggle will allow you to enter Edit Fields mode, where you can add, remove, or edit objective fields. You can even remove the default objective fields if you wish. Try toggling it on!",
  },
  {
    target: "#addNewCustomFieldButton",
    spotlightClicks: true,
    content: "Click here to add a new custom field to this note.",
  },
  {
    target: "#customFieldItem-0",
    spotlightClicks: true,
    content:
      "Here, you can fill out the details for your new custom field. Enter a name, select a symmetry type (if applicable), and fill out your notes. Note that selecting a symmetry option other than 'None' will split up your field into two. Try it for yourself!",
  },
  {
    target: "#additionalInfo",
    spotlightClicks: true,
    content:
      "If you have any additional information about the patient, such as medications or vaccinations, feel free to add it here and it will automatically show up under your patients' profile as soon as you submit the note. If you are writing a quicknote, these fields will be displayed only if you pair the note with a patient.",
  },
  {
    target: "#attachmentSelection",
    content:
      "If you'd like to attach a file such as an image, video, document, or PDF to this note, you may drag it to this box, or click to browse for files.",
  },
  {
    target: "#noteEmailSection",
    content:
      "Finally, you may choose to export this note directly to an email address, which can be specified here. Click the toggle to expand the options.",
    spotlightClicks: true,
  },
  {
    target: "#noteEmailSection",
    content:
      "As you can see, you may specify which email addresses you would like to send the note to, as well as any special instructions for the recipient. You may also specify whether to include the note's attachments or not.",
  },
  {
    target: "#trashNote",
    placement: "top",
    content:
      "If you don't need this note anymore, feel free to delete it by clicking here.",
  },

  {
    target: "#saveDraftButton",
    content: (
      <span className={textClassName}>
        If you can&apos;t finish up your note in one session (we know,
        you&apos;re busy <Twemoji emoji="😔" />
        ), click this button to save the note as a draft. It will be
        in your Dashboard Inbox waiting for you to get back to it at
        any time.
      </span>
    ),
  },
  {
    target: "#submitNoteButton",
    content: (
      <span className={textClassName}>
        When this note is complete, this button will submit it. Then,
        make sure to give yourself a pat on the back for your
        excellent work <Twemoji emoji={"🤩"} />
      </span>
    ),
  },
];

export const recordedNoteSteps = [
  {
    target: "body",
    placement: "center",
    content:
      "On this page you will be able to write up a SOAP note for an appointment you have already recorded. Fill out any information you need to, whether it's Subjective, Objective, Assessment, or Plan.",
    disableBeacon: true,
  },
  {
    target: "#existingRecordingControls",
    content: (
      <span className={textClassName}>
        Here&apos;s the appointment audio you recently recorded.
        Listen along as you write up your note, the audio player will
        follow you on your journey. <Twemoji emoji="🤠" />
      </span>
    ),
    disableBeacon: true,
  },
  {
    target: "#autocompleteToggle",
    content:
      "This switch toggles the autocomplete functionality. With this ON, you will see autocomplete suggestions appear as you type in the SOAP form fields. You can press Enter or Tab when these suggestions appear and the rest of the word will be entered for you. ",
  },
  {
    target: "#useTemplate",
    content:
      "Click here to select a template to pre-fill the SOAP form with. Be careful, as selecting a template will overwrite anything already in the fields.",
  },
  {
    target: "#editDate",
    content:
      "By default, the note's date will be set when it is created. In case you are writing a note for a past appointment, clicking here will allow you to edit the note's date.",
  },
  {
    target: "#useBlankObjectiveToggle",
    content:
      "This toggle will switch the objective section into a blank text box, like the ones you see for the Subjective, Assessment, and Plan sections.",
  },
  {
    target: "#editFieldsToggle",
    spotlightClicks: true,
    content:
      "This toggle will allow you to enter Edit Fields mode, where you can add, remove, or edit objective fields. You can even remove the default objective fields if you wish. Try toggling it on!",
  },
  {
    target: "#addNewCustomFieldButton",
    spotlightClicks: true,
    content: "Click here to add a new custom field to this note.",
  },
  {
    target: "#customFieldItem-0",
    spotlightClicks: true,
    content:
      "Here, you can fill out the details for your new custom field. Enter a name, select a symmetry type (if applicable), and fill out your notes. Note that selecting a symmetry option other than 'None' will split up your field into two. Try it for yourself!",
  },

  {
    target: "#additionalInfo",
    content:
      "If you have any additional information about the patient, feel free to add it here and it will automatically show up under your patients' profile as soon as you submit the note. If you are writing a quicknote, these fields will be displayed only if you pair the note with a patient.",
  },
  {
    target: "#addAttachments",
    content:
      "If you'd like to attach a file such as an image, video, document, or PDF to this note, you may drag it to this box, or click to browse for files.",
  },
  {
    target: "#clientSummary",
    content:
      "If you'd like to generate a client summary for this note, click here. This will generate a PDF with the note's details, which you can then send to your client.",
  },
  {
    target: "#trashNote",
    content:
      "If you don't need this note anymore, feel free to delete it by clicking here.",
  },
  {
    target: "#saveDraftButton",
    content: (
      <span className={textClassName}>
        If you can&apos;t finish up your note in one session (we know,
        you&apos;re busy <Twemoji emoji="😔" />
        ), click this button to save the note as a draft. It will be
        in your Dashboard Inbox waiting for you to get back to it at
        any time.
      </span>
    ),
  },
  {
    target: "#submitNoteButton",
    content: (
      <span className={textClassName}>
        When this note is complete, this button will submit it. Then,
        make sure to give yourself a pat on the back for your
        excellent work <Twemoji emoji={"🤩"} />
      </span>
    ),
  },
];

export const superSOAPSteps = [
  {
    target: "body",
    placement: "center",
    content: (
      <span className={textClassName}>
        On this page you will be able to see all your uncompleted
        quicknote and patient recordings. When you&apos;re done
        filling out one SOAP note, you can easily move on to the next
        one without having to navigate back and forth between pages.
        Pretty super! <Twemoji emoji="🤩" />
      </span>
    ),
    disableBeacon: true,
  },
];

export const templateSteps = [
  {
    target: "#presetTemplates",
    content: (
      <span className={textClassName}>
        In this section, you can find Scribenote&#39;s preset
        templates. You cannot edit or delete them, instead you can
        create your own templates in the section below.
      </span>
    ),
    disableBeacon: true,
  },
  {
    target: "#customTemplates",
    content: (
      <span className={textClassName}>
        In this section, you will find the templates you have made.
        Click &#39;New Template&#39; to start creating one. You can
        also edit the templates you&#39;ve made by clicking on them,
        or delete one of your templates by hovering over it and
        pressing the <FontAwesomeIcon icon={faTrash} /> icon.
      </span>
    ),
    disableBeacon: true,
  },
];

export const newTemplateSteps = [
  {
    target: "body",
    placement: "center",
    content: (
      <span className={textClassName}>
        On this page you can create a custom template. Give the
        template a name, colour, and an optional description, then
        fill in the SOAP fields as you would for a note. None of the
        SOAP fields are required, so fill in only what you need. Then
        save the template, and you&#39;ll be able to apply this
        template to any subsequent SOAPs you create. Easy peasy!
      </span>
    ),
    disableBeacon: true,
  },
];
