import { gql, useLazyQuery } from "@apollo/client";
import mixpanel from "mixpanel-browser";
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { useSpring, animated, config } from "react-spring";
import { useIntercom } from "react-use-intercom";

import { NoteUsageCounter } from "./header/NoteUsageCounter.js";
import LogoIcon from "../../assets/imgs/Logo_icon.png";
import { useAccount, useWindowDimensions } from "../../hooks";
import { useAuth } from "../../hooks";
import { useTeamMember } from "../../hooks/use-team-member.js";
import { useTeam } from "../../hooks/use-team.js";
import SelectTeam from "../account/Teams/SelectTeam.js";
import { alert } from "../common/Alert.js";
import { BasicButton } from "../common/buttons/BasicButton.js";
import { ConfirmModal } from "../common/modals/PopupModals.js";
import AuthNav from "../layout/header/AuthNav.js";
import Logo from "../layout/header/Logo.js";

const GET_USER = gql`
  {
    user {
      email
      firstName
      lastName
      uuid
      createdAt
      account {
        isSubscribed
      }
    }
    rootUser {
      firstName
      lastName
      teams {
        uuid
        name
        users {
          firstName
          lastName
          email
          uuid
        }
      }
    }
  }
`;

const Header = () => {
  const [isNavbarShrunk, setIsNavbarShrunk] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const { width, height } = useWindowDimensions();
  const [isModalShowing, setIsModalShowing] = useState(false);
  const { currentTeam, setCurrentTeam } = useTeam();
  const { returnToRootUser } = useTeamMember();
  const { update, show } = useIntercom();
  const account = useAccount();

  const [getUser, { data }] = useLazyQuery(GET_USER, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (auth?.isUserAuthenticated) {
      getUser().then((response) => {
        update({
          email: response?.data?.user?.email,
          userId: response?.data?.user?.uuid,
          name: `${response?.data?.user?.firstName} ${response?.data?.user?.lastName}`,
          createdAt: response?.data?.user.createdAt,
          customAttributes: {
            is_subscribed:
              response?.data?.user?.account?.isSubscribed,
          },
        });
        ReactGA.set({
          userId: response?.data?.rootUser?.uuid,
        });
      });
    }
  }, [auth?.isUserAuthenticated]);

  const propHandleChange = (event) => {
    const team = data?.rootUser?.teams.find(
      (team) => team?.name === event.target.value,
    );
    mixpanel.track("Switched Team", {
      newTeam: team?.name,
      previousTeam: currentTeam?.name,
    });
    setCurrentTeam(team);
    if (
      localStorage.getItem("user_uuid") !==
      localStorage.getItem("root_logged_in_user_uuid")
    ) {
      returnToRootUser();
      if (location.pathname !== "/dashboard") {
        alert(
          "warning",
          "Returned to root user, so you will be redirected to the dashboard.",
        );
        navigate("/dashboard");
      }
    }
  };

  useEffect(() => {
    const handler = () => {
      setIsNavbarShrunk((isNavbarShrunk) => {
        if (
          !isNavbarShrunk &&
          (document.body.scrollTop > 20 ||
            document.documentElement.scrollTop > 20)
        ) {
          return true;
        }

        if (
          isNavbarShrunk &&
          document.body.scrollTop < 10 &&
          document.documentElement.scrollTop < 10
        ) {
          return false;
        }

        return isNavbarShrunk;
      });
    };
    window.addEventListener("scroll", handler);
    return () => window.removeEventListener("scroll", handler);
  }, []);

  const headerAnim = useSpring({
    config: config.stiff,
    paddingTop: isNavbarShrunk ? "1px" : "10px",
    paddingBottom: isNavbarShrunk ? "1px" : "10px",
    // borderBottom: isNavbarShrunk
    //   ? `1px solid ${darkModeEnabled[0] ? "#3F3F46" : "#F7F7F7"}`
    //   : "1px solid transparent",
  });

  const logoShrinkAnim = useSpring({
    config: config.slow,
    width: isNavbarShrunk ? "130px" : "150px",
  });

  return (
    <>
      <ConfirmModal
        shouldShow={isModalShowing}
        hideModal={() => setIsModalShowing(false)}
        title={"Switch Current Team"}
        cancelText={"Exit"}
        cancelAction={() => {
          setIsModalShowing(false);
        }}
        confirmAction={() => setIsModalShowing(false)}
      >
        <div className="flex flex-col mx-2">
          <h5 className="mb-2">
            You are currently using {currentTeam?.name}. Please select
            and confirm a new team to switch to.
          </h5>
          {data?.rootUser ? (
            <SelectTeam
              propHandleChange={propHandleChange}
              rootUser={data.rootUser}
            />
          ) : (
            <h5 className="mb-2">
              You can change your team in the account page!
            </h5>
          )}
        </div>
      </ConfirmModal>
      <animated.div
        id="header"
        style={headerAnim}
        className={`sticky top-0 z-30 w-full mx-auto px-4 md:px-8 border-b border-transparent ${
          isNavbarShrunk
            ? "bg-white/90 dark:bg-gray-800/90 backdrop-filter backdrop-blur-sm"
            : "bg-white/100 dark:bg-gray-800/100 backdrop-filter backdrop-blur-none"
        }`}
      >
        <div className="relative flex items-center justify-between h-16">
          <div className="flex-1 flex items-center sm:items-stretch sm:justify-start">
            <animated.div
              id="logo"
              style={logoShrinkAnim}
              className="flex-shrink-0 cursor-pointer"
            >
              {width > 500 ? (
                <Logo />
              ) : (
                <Link to="/dashboard">
                  <img width={40} src={LogoIcon} />
                </Link>
              )}
            </animated.div>
          </div>

          {/* {account?.isConfirmed ? ( */}
          <div className="absolute inset-y-0 right-0 flex items-center space-x-2">
            {account?.accountType !== "unlimited" &&
            auth?.isUserAuthenticated &&
            currentTeam?.users.length == 0 ? (
              <BasicButton
                onClick={() => {
                  navigate("/subscriptions");
                  mixpanel.track("Upgrade Plan Clicked");
                }}
              >
                Upgrade Plan
              </BasicButton>
            ) : null}
            {width > 600 && height > 600 ? (
              <NoteUsageCounter key={"counter"} />
            ) : null}
            <AuthNav
              user={data?.user}
              show={show}
              setIsModalShowing={setIsModalShowing}
            />
          </div>
          {/* ) : (
          <div className="absolute inset-y-0 right-0 flex items-center space-x-3">
            {account?.isConfirmed ? renderExportNotesButton() : null}
            {auth?.isUserAuthenticated ? renderLogoutText() : null}
          </div>
        )} */}
        </div>
      </animated.div>
    </>
  );
};

export default Header;
