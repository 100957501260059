import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useFieldArray } from "react-hook-form";

import CustomObjectiveFieldItem from "./CustomObjectiveFieldItem.js";
import { ObjectiveTypeMainSeparator } from "./ObjectiveTypeSeparators.js";
import AddFieldButton from "../edit/form/AddFieldButton.js";

export default function CustomObjectiveFields({
  control,
  setValue,
  isEditFieldModeActive,
}) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "customObjectiveFieldArray",
  });

  return (
    <div className="mt-4">
      <ObjectiveTypeMainSeparator title="Custom Fields" />
      {!isEditFieldModeActive && fields.length === 0 && (
        <h5 className="text-sm text-center text-gray-500 dark:text-gray-400 py-3">
          There are no custom fields yet. Try adding one by turning on
          the &apos;Edit Fields&apos; mode above, and then clicking
          the &apos;Add New Field&apos; button.
        </h5>
      )}
      {fields.length > 0 && (
        <div className="hidden md:flex flex-row mb-2">
          <h5 className="text-xs md:text-sm text-gray-500 underline">
            Name
          </h5>
          <h5
            className={` ${
              isEditFieldModeActive
                ? "ml-14 md:ml-48"
                : "ml-7 md:ml-40"
            }  flex-1 text-xs md:text-sm text-gray-500 underline`}
          >
            Value
          </h5>
          {isEditFieldModeActive && (
            <h5 className="text-xs md:text-sm text-gray-500 underline">
              Symmetry
            </h5>
          )}
        </div>
      )}

      {fields.map((item, index) => {
        return (
          <div
            className="group relative flex flex-row items-center"
            key={`${index}-group`}
          >
            {isEditFieldModeActive && (
              <div
                onClick={() => {
                  remove(index);
                }}
                className="h-5 w-5 bg-pink-100 dark:bg-gray-600 group-hover:bg-red-400 dark:group-hover:bg-red-400 mr-2 rounded-full flex items-center justify-center transition-all cursor-pointer"
              >
                <FontAwesomeIcon icon={faMinus} color="white" />
              </div>
            )}
            <CustomObjectiveFieldItem
              key={item.id} // necessary for proper field removal at correct index https://github.com/react-hook-form/react-hook-form/issues/1571#issuecomment-690882455
              id={`customFieldItem-${index}`}
              index={index}
              customFieldName={`customObjectiveFieldArray[${index}].name`}
              customFieldValue={`customObjectiveFieldArray[${index}].value`}
              customFieldSymmetricalValueLeft={`customObjectiveFieldArray[${index}].symmetricalValueLeft`}
              customFieldSymmetricalValueRight={`customObjectiveFieldArray[${index}].symmetricalValueRight`}
              customFieldSymmetryType={`customObjectiveFieldArray[${index}].symmetryType`}
              symmetryType={item.symmetryType}
              symmetryLabels={item.symmetryLabels}
              control={control}
              setValue={setValue}
              type={"text"}
              isEditFieldModeActive={isEditFieldModeActive}
            />
          </div>
        );
      })}

      {isEditFieldModeActive && (
        <AddFieldButton
          onClick={() => {
            append({
              id: null,
              uuid: null,
              name: "",
              value: "",
              symmetricalValueLeft: "",
              symmetricalValueRight: "",
              symmetryType: "None",
            });
          }}
        />
      )}
    </div>
  );
}
