import {
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { config, animated, useSpring } from "react-spring";

export function RecordingTranscriptDrawer({
  transcript,
  isMedicalRecord,
}) {
  const [shouldShow, setShouldShow] = useState(false);
  const slideAnim = useSpring({
    transform: shouldShow ? "translateY(10px)" : "translateY(425px)",
    config: config.default,
  });

  const parseTranscript = (transcript) => {
    //Split on <>{timestamp information}<>
    const parts = transcript.split(/<>([^<>]+)<>(?!.*<>)/g);

    return parts.map((part, index) => {
      //If the index is odd, it's a timestamp
      //This % logic is important in case there are multiple timestamps
      if (index % 2 === 1) {
        return (
          <strong key={index} className="mt-2 block italic">
            {part.trim()}
          </strong>
        );
      }
      //Otherwise, it's just regular transcript content
      return (
        <span className="mt-2 block" key={index}>
          {part.trim()}
        </span>
      );
    });
  };

  return (
    <animated.div
      style={slideAnim}
      className={`fixed bottom-0 ${
        isMedicalRecord ? "left-28" : "left-0"
      } z-30 w-full flex flex-col items-center justify-center pointer-events-none ${
        shouldShow ? "z-40" : null
      }`}
    >
      <div className="flex flex-col items-center pointer-events-auto">
        <div
          className="relative flex flex-col items-center bg-white dark:bg-gray-700 pt-6 pb-4 px-6 rounded-t-2xl"
          style={{
            boxShadow: "0px -5px 50px -10px rgba(0,0,0,0.40)",
          }}
        >
          <button
            onClick={() => setShouldShow(!shouldShow)}
            className="absolute -top-10 z-40 bg-indigo-400 dark:bg-indigo-500 py-2 px-8 rounded-t-2xl"
          >
            <h4 className="text-white">
              {shouldShow ? "Hide Transcript" : "Show Transcript"}{" "}
              <FontAwesomeIcon
                icon={shouldShow ? faChevronDown : faChevronUp}
              />
            </h4>
          </button>
          <h5 className="text-xs md:text-sm w-[300px] md:w-[600px] h-96 overflow-y-auto whitespace-break-spaces">
            {parseTranscript(transcript)}
          </h5>
        </div>
      </div>
    </animated.div>
  );
}
