import { gql } from "@apollo/client";

const UPDATE_USER_FF_SEGMENT = gql`
  mutation UpdateUserFFSegment(
    $action: ActionEnum!
    $segmentKey: String!
  ) {
    updateUserFfSegment(action: $action, segmentKey: $segmentKey) {
      success
      message
    }
  }
`;

export default UPDATE_USER_FF_SEGMENT;
