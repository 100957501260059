import { useQuery, useMutation, gql } from "@apollo/client";
import { useWindowVirtualizer } from "@tanstack/react-virtual";
import moment from "moment";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";

import QuicknoteItem from "./QuicknoteItem.js";
import { quicknoteSteps } from "../../assets/data/JoyrideTourSteps.js";
import { NOTE_FIELDS } from "../../graphql/fragments/note.js";
import { useAccount } from "../../hooks";
import { alert } from "../common/Alert.js";
import FullScreenLoading from "../common/loading/FullScreenLoading";
import JoyrideTour from "../common/modals/JoyrideTour.js";
import { DeleteMultiModal } from "../common/multiselect/DeleteMultiModal.js";
import { MultiSelectActionContainer } from "../common/multiselect/MultiSelectActionContainer.js";
import { QuickActionInstructionText } from "../common/multiselect/QuickActionInstructionText.js";
import Record from "../common/record/Record";
import PageTitle from "../layout/PageTitle";
import NewNoteButton from "../notebook/note/NewNoteButton.js";
import { CREATE_QUICK_NOTE } from "../notes/edit/api/mutations.js";

export const GET_NOTES = gql`
  ${NOTE_FIELDS}
  query getUsersNotes(
    $isApproved: Boolean
    $isTagged: Boolean
    $isQuickNote: Boolean
  ) {
    user {
      firstName
      lastName
      notes(
        isApproved: $isApproved
        isTagged: $isTagged
        isQuickNote: $isQuickNote
      ) {
        ...NoteFields
        keyHistory
        keyMedications
        vaccinationStatus
        weight
        attachments {
          uuid
          fileName
          fileLink
          createdAt
        }
      }
    }
  }
`;

function EmptyState() {
  return (
    <div key={0}>
      <div className="p-5 bg-gray-50 dark:bg-gray-700 rounded-xl">
        <div className="flex flex-col self-center justify-self-center space-y-5">
          <h4 className="text-center text-base text-gray-500 dark:text-gray-400">
            There&apos;s nothing here yet!
          </h4>
          <h5 className="text-center text-sm text-gray-400 dark:text-gray-500">
            You have no Quicknotes at the moment, or you haven&#39;t
            completed any yet. <br /> Try recording one above, then
            check the Inbox on your Dashboard to begin SOAPing it!
          </h5>
        </div>
      </div>
    </div>
  );
}

export default function Quicknotes() {
  const [isRecording, setIsRecording] = useState(false);
  const navigate = useNavigate();

  const [runJoyrideTour, setRunJoyrideTour] = useState(false);
  const { accountType } = useAccount();

  const { control, getValues, setValue } = useForm();

  const [createQuickNote] = useMutation(CREATE_QUICK_NOTE);
  const { data, loading, refetch } = useQuery(GET_NOTES, {
    variables: {
      isQuickNote: true,
    },
    fetchPolicy: "network-only",
  });

  function setNewQuickNote() {
    alert("success", "Audio file uploaded!");
    setValue("title", "");
    refetch();
  }

  async function createNewNote(audioStorageLink, storageObjectName) {
    const values = getValues();
    const jobType = JSON.parse(
      localStorage.getItem("recording-type"),
    );

    createQuickNote({
      variables: {
        noteInput: {
          audioStorageLink: audioStorageLink,
          storageObjectName: storageObjectName,
          title: values.title,
          createdAt: moment().format(),
          lastUpdatedAt: moment().format(),
          jobType,
        },
      },
    }).then(() => {
      setNewQuickNote();
    });

    return true;
  }

  if (loading) {
    <FullScreenLoading loadingTrigger={loading} />;
  }

  return (
    <div>
      <DeleteMultiModal notes={data?.user?.notes} refetch={refetch} />
      <FullScreenLoading loadingTrigger={loading} />
      <JoyrideTour
        steps={quicknoteSteps}
        runJoyrideTour={runJoyrideTour}
        setRunJoyrideTour={setRunJoyrideTour}
      />
      <PageTitle
        title="Quicknotes"
        onClick={() => {
          setRunJoyrideTour(true);
        }}
        showHelpButton
      />
      <div>
        <div className="flex-grow my-20" id="quicknoteRecord">
          <Record
            createNewNote={createNewNote}
            isRecording={isRecording}
            setIsRecording={setIsRecording}
            control={control}
          />
        </div>
      </div>
      <div id="quicknoteNotesSection">
        <div className="flex flex-row items-end space-x-3 my-3">
          <h2 className="text-left flex-1">Notes</h2>
        </div>
        {data?.user?.notes?.length > 0 ? (
          <QuickActionInstructionText />
        ) : null}
        <div className="w-full mb-4" id="newNotePatientNotebook">
          <NewNoteButton
            onClick={() => {
              navigate(`/new_note/quicknote/`);
            }}
            disabled={isRecording}
          />
        </div>

        {data?.user?.notes ? (
          <RowVirtualizerDynamicWindow
            notes={data?.user?.notes}
            accountType={accountType}
          />
        ) : (
          <EmptyState />
        )}

        <MultiSelectActionContainer notes={data?.user?.notes} />
      </div>
    </div>
  );
}

const RowVirtualizerDynamicWindow = ({ notes = [], accountType }) => {
  const parentRef = React.useRef();

  const parentOffsetRef = React.useRef(0);

  React.useLayoutEffect(() => {
    parentOffsetRef.current = parentRef.current?.offsetTop ?? 0;
  }, []);

  const virtualizer = useWindowVirtualizer({
    count: notes.length,
    estimateSize: () => 45,
    scrollMargin: parentOffsetRef.current,
  });

  const items = virtualizer.getVirtualItems();

  if (!items[0]) {
    return <EmptyState />;
  }

  return (
    <div ref={parentRef} className="List">
      <div
        style={{
          height: virtualizer.getTotalSize(),
          width: "100%",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            transform: `translateY(${
              items[0].start - virtualizer.options.scrollMargin
            }px)`,
          }}
        >
          <div className="space-y-5">
            {items.map((virtualRow) => (
              <div
                key={virtualRow.key}
                data-index={virtualRow.index}
                ref={virtualizer.measureElement}
              >
                <QuicknoteItem
                  key={notes[virtualRow.index].uuid}
                  quicknoteData={notes[virtualRow.index]}
                  accountType={accountType}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
