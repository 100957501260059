import {
  faEye,
  faEyeSlash,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { config, useTransition, animated } from "react-spring";

import { Button } from "./form/Button.js";
import { Input } from "./form/Input.js";
import OttoScribblesPeek from "../../assets/imgs/otto-scribbles-peek.png";
import { useAuth } from "../../hooks";

function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [shouldShowOttoScribbles, setShouldShowOttoScribbles] =
    useState(true);

  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.isUserAuthenticated) {
      navigate("/dashboard");
    }
  }, [auth.isUserAuthenticated]);

  function togglePassword() {
    setShowPassword(!showPassword);
  }
  const popUpAnim = useTransition(shouldShowOttoScribbles, {
    from: { y: 0 },
    enter: { y: 0 },
    leave: { y: 40 },
    delay: 200,
    config: config.gentle,
  });

  const handleLogin = async (event) => {
    event.preventDefault();
    event.persist(); // <- Prevent pooling from clearing form during setstate

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    const { email, password } = event.target;
    await auth.signin(email.value, password.value);
  };

  return (
    <div className="h-full flex flex-col items-center justify-center space-y-10 pb-10">
      <div>
        <h2 className="mb-10 text-center text-3xl font-extrabold dark:text-gray-300 text-gray-900">
          Sign in to your account
        </h2>
      </div>

      <form
        className="relative flex flex-col items-center w-[300px] md:w-[400px] mx-auto p-10 rounded-xl shadow-md shadow-indigo-100 dark:shadow-gray-900 bg-white dark:bg-gray-800"
        data-cy="loginForm"
        onSubmit={(event) => handleLogin(event)}
      >
        {popUpAnim(
          (styles, item) =>
            item && (
              <animated.div
                style={styles}
                className="absolute -z-10 -top-10"
              >
                <img src={OttoScribblesPeek} width="200px" />
              </animated.div>
            ),
        )}

        <div className="flex flex-col space-y-10 w-full">
          <Input
            name="email"
            type="email"
            data-cy="email"
            placeholder="Email Address"
          />
          <div className="relative">
            <Input
              name="password"
              data-cy="password"
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              onFocus={() => setShouldShowOttoScribbles(false)}
              onBlur={() => setShouldShowOttoScribbles(true)}
            />
            <button
              type="button"
              onClick={() => togglePassword()}
              className="group absolute -top-5 right-2 z-20 flex items-center space-x-1 text-gray-500 dark:text-gray-500"
            >
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                size="sm"
                className="group-hover:text-indigo-500 group-hover:dark:text-indigo-200"
              />
              <h5 className="text-xs group-hover:text-indigo-500 group-hover:dark:text-indigo-200">
                {showPassword ? "Hide" : "Show"}
              </h5>
            </button>
          </div>
        </div>

        <div className="flex self-end pb-5">
          <Link
            to="/forgot_password"
            className="font-medium text-indigo-500 hover:text-indigo-400 text-xs"
          >
            Forgot your password?
          </Link>
        </div>

        <Button dataCy="login" type="submit">
          Sign In
        </Button>
      </form>
      <p
        className="mt-2 text-center text-sm dark:text-gray-400 text-gray-600"
        data-cy="registerFromLogin"
      >
        Or{" "}
        <Link
          to="/auth/register"
          className="font-medium text-indigo-500 hover:text-indigo-400"
        >
          register a new account
        </Link>
      </p>
    </div>
  );
}

export default Login;
