import { gql, useLazyQuery } from "@apollo/client";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  useState,
  useEffect,
  useContext,
  createContext,
} from "react";

import { useAuth } from "./use-auth";

// commented out due to the commented out logic below
// const RETRY_DELAY = 5000;

const watchNoteUpdatesContext = createContext(null);

const SHOULD_UPDATE_POLL_QUERY = gql`
  query shouldUpdatePoll {
    shouldUpdatePoll {
      lastUpdated
    }
  }
`;

/**
 *
 * Uses SSE (Server Sent Events) to watch for updates to the user's notes
 *
 * @param {*} param0
 * @returns
 */
export function WatchNoteUpdatesProvider({ children }) {
  const [lastUpdated, setLastUpdated] = useState(0);
  const [
    startPollingForNoteUpdates,
    { data, startPolling, stopPolling },
  ] = useLazyQuery(SHOULD_UPDATE_POLL_QUERY);

  const { shouldUseNewPollingScheme } = useFlags();

  const auth = useAuth();

  useEffect(() => {
    if (data?.shouldUpdatePoll?.lastUpdated) {
      const nextUpdate = data?.shouldUpdatePoll?.lastUpdated;

      setLastUpdated(nextUpdate);
    }
  }, [data]);

  useEffect(() => {
    if (shouldUseNewPollingScheme && auth?.isUserAuthenticated) {
      startPollingForNoteUpdates();
    }
  }, [auth, shouldUseNewPollingScheme]);

  useEffect(() => {
    // Respond to auth state changes
    if (auth?.isUserAuthenticated) {
      startPollingForNoteUpdates();
    } else {
      stopPolling();
    }
  }, [auth]);

  useEffect(() => {
    if (shouldUseNewPollingScheme && auth?.isUserAuthenticated) {
      startPolling(5000);
    } else {
      stopPolling();
    }
    return () => stopPolling(); // Clean up by stopping polling when the component unmounts
  }, [stopPolling, shouldUseNewPollingScheme, auth]);

  // const [eventSource, setEventSource] = useState(null);
  // const auth = useAuth();

  // COMMENT OUT BECAUSE IT IS CURRENTLY RUNNING UNUSED
  // AND COULD RISK SWAMPING THE SERVER
  // useEffect(() => {
  //   const userChannelKey = localStorage.getItem("user_uuid");
  //   const SCRIBENOTE_FLASK = getTempScribenoteFlask();

  //   const connect = () => {
  //     if (!auth?.isUserAuthenticated) {
  //       return;
  //     }
  //     if (!userChannelKey) {
  //       return;
  //     }

  //     // Only create a new EventSource if one doesn't exist or if it's not open
  //     if (
  //       !eventSource ||
  //       eventSource.readyState !== EventSource.OPEN
  //     ) {
  //       const es = new EventSource(
  //         `${SCRIBENOTE_FLASK}/stream?channel=user_note_updates:${userChannelKey}`,
  //         {
  //           withCredentials: true,
  //         },
  //       );

  //       es.onmessage = function (event) {
  //         console.log("EVENT RECIEVED");

  //         const newMessage = event?.data;

  //         // Ensure the new message exists and is in json readable format
  //         if (!newMessage || !JSON?.parse(newMessage)?.lastUpdated) {
  //           return;
  //         }

  //         const nextUpdate =
  //           JSON.parse(newMessage)?.lastUpdated || "";

  //         if (nextUpdate != lastUpdated) {
  //           setLastUpdated(nextUpdate);
  //         }

  //         es.onerror = function () {
  //           es.close();
  //           // Retry connection if the maximum retry limit has not been reached
  //           setTimeout(connect, RETRY_DELAY);
  //         };
  //       };

  //       setEventSource(es);
  //     }
  //   };

  //   connect(); // Initial connection
  // }, [auth]);

  return (
    <watchNoteUpdatesContext.Provider
      value={{
        lastUpdated,
      }}
    >
      {children}
    </watchNoteUpdatesContext.Provider>
  );
}

export function useWatchNoteUpdates({ refetch }) {
  // Last refetched is tracked at the hook level to avoid race conditions globally.
  // Ie - the last refetch only really pertains to the last time a particular query refetched
  // which is why its state is hook level and not in the global context.
  const [lastRefetched, setLastRefetched] = useState(0);

  const { lastUpdated } = useContext(watchNoteUpdatesContext);

  useEffect(() => {
    if (lastUpdated && lastRefetched != lastUpdated) {
      refetch();
      setLastRefetched(lastUpdated);
    }
  }, [lastUpdated]);
}
