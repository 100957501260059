import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { EmailExportTypeForm } from "./EmailExportTypeForm";
import HeadingWrapper from "../common/HeadingWrapper";
import { Input } from "../common/inputs/Input";
import { ErrorMessage } from "../common/inputs/Input";
import { ExportNoteInline } from "../notes/ExportNoteInline";

export default function ExportForm({
  getValues,
  control,
  isClientSummaryMode,
  isClientSummaryModalOpen,
  clientSummary,
  frontmatter,
  backmatter,
  note,
  shouldOnlyShowEmailForm = null,
  isInboxModalMode = false,
}) {
  const {
    watch,
    formState: { errors },
  } = useFormContext(); // this is formcontext of the export form

  const exportType = watch("exportType");

  const shouldValidateEmail =
    isInboxModalMode || isClientSummaryModalOpen;

  return (
    <>
      {!shouldOnlyShowEmailForm ? (
        <ExportNoteInline
          isClientSummaryMode={isClientSummaryMode}
          getValues={getValues}
          clientSummary={clientSummary}
          frontmatter={frontmatter}
          backmatter={backmatter}
          note={note}
        />
      ) : null}
      <HeadingWrapper heading={<h4>Email Export Options</h4>} />
      <div className="space-y-2">
        {!isClientSummaryMode && (
          <EmailExportTypeForm control={control} />
        )}
        <h4 className="text-sm">
          Enter email address(es) you would like to send this note to:
        </h4>
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <Input
              placeholder="e.g. doctor@someclinic.com, staff@someclinic.com"
              onChange={(e) => onChange(e.target.value)}
              value={value}
            />
          )}
          rules={
            shouldValidateEmail
              ? {
                  required: true,
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message:
                      "Entered value does not match email format",
                  },
                }
              : {
                  required: false,
                }
          }
          name="toEmail"
          defaultValue=""
        />
      </div>
      {!isClientSummaryMode && (
        <div className="space-y-2 pt-4">
          <h4 className="text-sm">
            Enter any special instructions for the recipient{" "}
            <small>(optional)</small>:
          </h4>
          <Controller
            control={control}
            rules={{
              required: false,
            }}
            render={({ field: { onChange, value } }) => (
              <Input
                placeholder={
                  "e.g. Please see below for the medical record we discussed."
                }
                onChange={(e) => onChange(e.target.value)}
                value={value}
              />
            )}
            name="specialInstructions"
            defaultValue=""
          />
        </div>
      )}
      {exportType !== "embeddedText" && !isClientSummaryMode && (
        <div className="flex flex-row items-center space-x-3 mt-4">
          <Controller
            control={control}
            rules={{
              required: false,
            }}
            render={({ field: { onChange, value } }) => (
              <input
                type="checkbox"
                className="hover:cursor-pointer"
                onChange={(e) => onChange(e.target.checked)}
                checked={value}
                disabled={exportType === "embeddedText"}
              />
            )}
            name="includeAttachments"
            defaultValue={true}
          />
          <h4>Include attachments?</h4>
        </div>
      )}
      {errors?.toEmail && shouldValidateEmail && (
        <ErrorMessage message="Please enter a valid email address!" />
      )}
    </>
  );
}
