import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function HelpButton({ onClick, id, helpText }) {
  return (
    <div
      id={id}
      onClick={onClick}
      title={helpText || "Click me for a tutorial!"}
    >
      {/* <Tooltip
        content={
          <div className="bg-indigo-400 py-2 px-3 rounded-lg transform -translate-x-full -translate-y-8">
            <h4 className="text-sm text-gray-100 font-normal">
              
            </h4>
          </div>
        }
      > */}
      <FontAwesomeIcon
        className="inline-block cursor-help fill-current text-gray-400 hover:text-indigo-400 ml-2"
        icon={faQuestionCircle}
        size="lg"
      />
      {/* </Tooltip> */}
    </div>
  );
}
