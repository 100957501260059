import PropTypes from "prop-types";
import React from "react";
import { Controller } from "react-hook-form";

import { defaultList } from "../../../assets/data/Dictionary.js";
import { BAR, NORMAL_ON_EXAM } from "../../../constants.js";
import PredictiveInput from "../../common/inputs/PredictiveInput";

export default function ObjectiveFieldItem({
  fieldName,
  fieldLabel,
  control,
  formFieldValue,
  dataCy,
  placeholder = null,
  isForSOAPForm = true,
}) {
  return (
    <div className="flex flex-row items-end dark:border-gray-600 space-x-4 space-y-1 w-full">
      <h3
        className={`${
          isForSOAPForm
            ? "border-pink-200 text-pink-500"
            : "border-gray-200 text-gray-800"
        } w-20 md:w-44 max-w-sm overflow-hidden text-ellipsis border-b dark:border-gray-500 text-xs md:text-sm`}
      >
        {fieldLabel}
      </h3>
      <div className="flex flex-1 flex-row items-end space-x-4">
        <div
          className={`${
            isForSOAPForm ? "border-pink-200" : "border-gray-200"
          } flex flex-1 flex-row border-b  dark:border-gray-500`}
          data-cy={dataCy}
        >
          <Controller
            name={formFieldValue}
            control={control}
            render={({ field }) => (
              <PredictiveInput
                inputClassName={`w-full px-2 font-normal text-xs md:text-sm bg-transparent resize-none`}
                positionStyles="px-2"
                isForSOAPForm={isForSOAPForm}
                placeholder={
                  placeholder
                    ? placeholder
                    : fieldName !== "overall"
                    ? NORMAL_ON_EXAM
                    : BAR
                }
                field={field}
                dictionary={defaultList}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}

ObjectiveFieldItem.propTypes = {
  fieldName: PropTypes.string,
  fieldLabel: PropTypes.string,
  control: PropTypes.any,
};

ObjectiveFieldItem.propTypes = {
  control: PropTypes.any,
};
