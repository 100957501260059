import React from "react";

import { ChangeStepButton } from "./FirstLoginTour";

function ContentWrapper({ img, children, video, title }) {
  return (
    <div className="group flex flex-col items-center space-y-4 py-8">
      <div className="flex flex-row items-center space-x-4">
        <h2>{title}</h2>
      </div>
      <div className="flex flex-row items-center space-x-4">
        {video && (
          <div className="w-[300px] md:w-[600px] rounded-xl overflow-hidden">
            <video
              width="100%"
              muted
              loop
              controls
              src={video}
              type="video/mp4"
            />
          </div>
        )}
      </div>
      <div className="flex flex-row items-center space-x-4">
        {img && (
          <img
            src={img}
            className="md:w-[400px] md:h-[260px] object-cover w-[300px] rounded-2xl overflow-clip shadow-md"
          />
        )}
        <p className="text-md max-w-xl">{children}</p>
      </div>
    </div>
  );
}

export default function ExplanationStep({
  goToNextStep,
  goToPreviousStep,
}) {
  return (
    <>
      {/* <h3 className="flex flex-row items-center">
        Creating Auto-Notes with Scribenote
      </h3> */}

      <div className="flex flex-col items-center text-center space-y-10">
        {/* <div className="flex flex-col items-center space-y-4">
          <p>
            Auto-Notes are audio recordings made in Scribenote that
            are automatically turned into SOAP notes. <br />
            There are three types of Auto-Notes: Appointments,
            Summaries, and Callbacks.
          </p>
        </div> */}
        {/* <div className="w-[300px] md:w-[800px] bg-indigo-100 dark:bg-indigo-600 h-0.5 rounded-full"></div> */}
        <div className="flex flex-col items-start space-y-10">
          <ContentWrapper
            title={"Medical Records"}
            video={
              "https://d1247xmi17o3bz.cloudfront.net/AppointmentsAnimationCompressed.mp4"
            }
          >
            Like having an automatic scribe to write a SOAP note for
            you, based on a recording of your full appointment.
            Alternatively, you can describe the appointment afterwards
            to generate the SOAP note. Scribenote will only extract
            relevant information to transcribe into the SOAP.
          </ContentWrapper>
          {/* <ContentWrapper
            title={"Summaries"}
            video={
              "https://d1247xmi17o3bz.cloudfront.net/SummariesAnimationCompressed.mp4"
            }
          >
            Dictation-like summaries of your consultation, as if you
            were telling another vet what happened that day.
            Scribenote will transcribe what you say word-for-word into
            the SOAP.
          </ContentWrapper> */}
        </div>
      </div>
      <div className="pt-6 flex justify-center gap-4">
        <ChangeStepButton
          onClick={goToPreviousStep}
          text={"← Back"}
        />
        <ChangeStepButton onClick={goToNextStep} text={"Next →"} />
      </div>
    </>
  );
}
