import {
  faBookMedical,
  faGlobe,
  faList,
  faSms,
  faStethoscope,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const mapStateToPreferences = (state) => {
  return {
    visitSummary: state?.visitSummary?.include,
    abnormalPhysicalFindings:
      state?.abnormalPhysicalFindings?.include,
    vitals: state?.vitals?.include,
    allFindings: state?.allFindings?.include,
    pointFormSummary: state?.pointFormSummary?.include,
    oneLineSummary: state?.oneLineSummary?.include,
    ownerInstructions: state?.ownerInstructions?.include,
    medications: state?.medications?.include,
    vaccinations: state?.vaccinations?.include,
    diagnostics: state?.diagnostics?.include,
    diet: state?.diet?.include,
    followUpAppointments: state?.followUpAppointments?.include,
    homeCare: state?.homeCare?.include,
  };
};

export const generateParams = (
  parentValue,
  childValue,
  fieldName,
) => {
  switch (parentValue) {
    case "show_abnormals":
      switch (childValue) {
        case "show_abnormals":
          return { show_abnormals: true };
        default:
          return {};
      }

    case "change_structure":
      switch (childValue) {
        case "bullet_point":
          return { format: "bullet" };
        case "paragraph_form":
          return { format: "paragraph" };
        case "subheadings":
          return { format: "subheadings", category: fieldName };
        default:
          return {};
      }

    case "change_tone":
      switch (childValue) {
        case "medical_formal":
          return { tone: "medical" };
        case "conversational":
          return { tone: "conversational" };
        default:
          return {};
      }

    case "use_abbreviations":
      switch (childValue) {
        case "abbreviations":
          return { abbreviations: "medical" };
        case "full_words":
          return { abbreviations: "full" };
        default:
          return {};
      }

    case "translate_to":
      switch (childValue) {
        case "en":
          return { language_code: "en" };
        case "fr":
          return { language_code: "fr" };
        case "es":
          return { language_code: "es" };
        case "pt":
          return { language_code: "pt" };
        case "de":
          return { language_code: "de" };
        default:
          return {};
      }

    default:
      return {};
  }
};

export const languageCodeToLanguageName = (languageCode) => {
  switch (languageCode) {
    case "en":
      return "English";
    case "fr":
      return "French";
    case "es":
      return "Spanish";
    case "pt":
      return "Portuguese";
    case "de":
      return "German";
    default:
      return "";
  }
};

export const getOptions = (
  fieldName,
  languageAiAssistantEnabled = false,
  subheadingsAiAssistantEnabled = false,
) => {
  const allOptions = [
    {
      label: (
        <span>
          <FontAwesomeIcon icon={faStethoscope} /> Objective
          Categories
        </span>
      ),
      value: "show_abnormals",
      items: [
        { label: "Only Show Abnormals", value: "show_abnormals" },
      ],
      includedFieldNames: ["objective"],
    },
    {
      label: (
        <span>
          <FontAwesomeIcon icon={faList} /> Formatting
        </span>
      ),
      value: "change_structure",
      items: [
        { label: "Bullet Point", value: "bullet_point" },
        { label: "Paragraph Form", value: "paragraph_form" },
        // Conditionally include Subheadings based on ff
        ...(subheadingsAiAssistantEnabled &&
        (fieldName === "plan" || fieldName === "subjective")
          ? [{ label: "Add Subheadings", value: "subheadings" }]
          : []),
      ],
      excludedFieldNames: ["additionalNotes", "objective"],
    },
    {
      label: (
        <span>
          <FontAwesomeIcon icon={faBookMedical} /> Terminology
        </span>
      ),
      value: "change_tone",
      items: [
        { label: "Medical", value: "medical_formal" },
        { label: "Laymans", value: "conversational" },
      ],
      excludedFieldNames: ["additionalNotes"],
    },
    {
      label: (
        <span>
          <FontAwesomeIcon icon={faSms} /> Abbreviations
        </span>
      ),
      value: "use_abbreviations",
      items: [
        {
          label: "Include Medical Abbreviations",
          value: "abbreviations",
        },
        { label: "Include Full Words", value: "full_words" },
      ],
      excludedFieldNames: ["additionalNotes"],
    },
    // Conditionally include Language option based on ff
    ...(languageAiAssistantEnabled
      ? [
          {
            label: (
              <span>
                <FontAwesomeIcon icon={faGlobe} /> Language
              </span>
            ),
            value: "translate_to",
            items: [
              { label: "English", value: "en" },
              { label: "French", value: "fr" },
              { label: "Spanish", value: "es" },
              { label: "Portuguese", value: "pt" },
              { label: "German", value: "de" },
            ],
          },
        ]
      : []),
  ];

  // Filter options based on included and excluded field names
  const filteredOptions = allOptions.filter((option) => {
    if (option.excludedFieldNames) {
      return !option.excludedFieldNames.some(
        (field) => fieldName === field,
      );
    }
    if (option.includedFieldNames) {
      return option.includedFieldNames.some(
        (field) => fieldName === field,
      );
    }
    return true;
  });

  return filteredOptions;
};
