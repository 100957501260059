import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { ReactComponent as Paw } from "../../assets/imgs/paw.svg";
import { useWindowDimensions } from "../../hooks";
import { EditablePatientModal as CreatePatientModal } from "./modal/EditablePatientModal";

function NewPatient({
  isForPatientSelectionAction = false,
  setSelectedPatientName,
  setSelectedPatientUuid,
  shouldShow,
  setShouldShow,
  onAddPatient,
  dataCy,
}) {
  const { width } = useWindowDimensions();

  const navigate = useNavigate();
  const location = useLocation();

  const isForPatientNoteCreation =
    location.pathname === "/new_note/select";

  function redirectToNotebook(uuid) {
    setShouldShow(!shouldShow);

    if (!isForPatientSelectionAction && !isForPatientNoteCreation) {
      navigate(`/notebook/${uuid}`);
    }
  }

  function hideModal() {
    setShouldShow(false);
  }

  const createProps = {
    shouldShow,
    redirectToNotebook,
    hideModal,
    isForPatientSelectionAction,
    isForPatientNoteCreation,
    setSelectedPatientName,
    setSelectedPatientUuid,
    onAddPatient,
  };

  const shouldShowSmallButton =
    width < 800 || isForPatientNoteCreation;

  return (
    <div>
      <div
        onClick={() => setShouldShow(true)}
        className="cursor-pointer"
        data-cy={dataCy ? dataCy : "newPatient"}
      >
        <div
          className={` ${
            shouldShowSmallButton
              ? "py-4 px-4 flex-row space-x-2"
              : "h-44 w-44 flex-col"
          } rounded-full relative flex items-center justify-center transition-all bg-gradient-to-br from-indigo-400 to-indigo-800`}
        >
          {shouldShowSmallButton ? null : (
            <Paw className="w-20 mb-2" />
          )}

          <h3 className="text-center text-white dark:text-white text-xs md:text-sm">
            {shouldShowSmallButton ? "+ Patient" : "New Patient"}
          </h3>
        </div>
      </div>

      <CreatePatientModal {...createProps} />
    </div>
  );
}

export default NewPatient;
