import React from "react";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router";

import Twemoji from "../../../Twemoji.js";
import { truncate } from "../../../utils.js";
import { AudioPlayer } from "../../notebook/note/AudioPlayer.js";
import NoteCreatedAt from "../../notebook/note/NoteCreatedAt";
import NoteStatus from "../../notebook/note/NoteStatus.js";
import PatientTitle from "../../notebook/PatientTitle.js";

function NoteItem(props) {
  const {
    note,
    shouldShowAudioPlayer = false,
    style,
    handleActionWithPrevention = (fn) => fn(),
  } = props;
  const navigate = useNavigate();

  function handleNoteItemClick() {
    ReactGA.set({
      userId: localStorage.getItem("root_logged_in_user_uuid"),
    });

    navigate(`/edit_note/${note.uuid}`);
  }

  return (
    <div style={style} className="w-full">
      <a
        href={`/edit_note/${note.uuid}`}
        rel="noopener noreferrer"
        onClick={(e) => e.preventDefault()}
        className="!no-underline"
      >
        <div
          className={`cursor-pointer hover:bg-indigo-100 hover:border-indigo-100 
            dark:border-gray-600 dark:hover:bg-gray-600 p-3 bg-gray-50 
            border border-gray-200 dark:bg-gray-700 rounded-lg 
            self-center flex flex-col transition-all shadow-sm`}
          onClick={() =>
            handleActionWithPrevention(handleNoteItemClick)
          }
        >
          <div className="flex flex-col items-start w-full">
            <div className="flex flex-row items-center w-full">
              <div className="flex-1 mr-2">
                <div className="text-xs text-gray-500 dark:text-gray-300">
                  {note?.patient?.name ? (
                    <div>
                      {note?.jobType
                        ? `${note?.jobTypeName} for `
                        : "Note for "}
                      <PatientTitle
                        sex={note?.patient?.sex}
                        species={note?.patient?.species}
                        name={note?.patient?.name}
                        patientUUID={note?.patient?.uuid}
                        lastNameOfOwner={
                          note?.patient?.lastNameOfOwner
                        }
                        size="sm"
                        jobType={note?.jobType}
                      />
                    </div>
                  ) : (
                    <div className="text-xs text-gray-500 dark:text-gray-300">
                      {note?.jobTypeName || "Quicknote"}
                    </div>
                  )}
                  <NoteCreatedAt
                    createdAt={note?.createdAt}
                    size="sm"
                  />
                </div>
              </div>
              <NoteStatus note={note} />
            </div>

            {note?.title ? (
              <h5 className="text-xs md:text-sm font-medium mt-2 truncate">
                <Twemoji emoji="📝" /> {truncate(note?.title, 64)}
              </h5>
            ) : null}
          </div>

          {shouldShowAudioPlayer && note?.audioStorageLink ? (
            <AudioPlayer
              noteUuid={note?.uuid}
              shouldShowDeleteButton={false}
              shouldShowAddButton={false}
            />
          ) : null}
        </div>
      </a>
    </div>
  );
}

export default NoteItem;
