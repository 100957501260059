import {
  faDownload,
  faNotesMedical,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import {
  Document,
  Page,
  pdfjs,
} from "react-pdf/dist/esm/entry.webpack5";

import { getFileExtensionType } from "./attachment-utils.js";
import OttoViewer from "../../assets/imgs/otto-magnifying-glass.svg";
import { BasicButton } from "../common/buttons/BasicButton.js";
import ToggleSwitch from "../common/buttons/ToggleSwitch.js";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function FileView({ selectedAttachments, fileIndex }) {
  const [numPages, setNumPages] = useState(null);

  const fileLink = selectedAttachments[fileIndex]?.fileLink;
  const fileType = getFileExtensionType(
    selectedAttachments[fileIndex]?.fileName,
  );

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function getViewContent() {
    switch (fileType) {
      case "jpg":
      case "png":
      case "bmp":
      case "jpeg":
      case "gif":
        return <img src={fileLink} className="p-4 max-h-full" />;
      case "mp4":
      case "mov":
      case "mpeg":
      case "webm":
        return (
          <video
            controls
            type={`video/${fileType}`}
            src={fileLink}
            className="p-4 max-h-full"
          />
        );
      case "pdf":
        return (
          <div className="p-4 h-full overflow-y-auto">
            <Document
              width="100%"
              height="100%"
              file={fileLink}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  className={"mb-2"}
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                />
              ))}
            </Document>
          </div>
        );
      case "doc":
      case "docx":
      case "DOC":
        return (
          <iframe
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
              fileLink,
            )}`}
            width="100%"
            height="100%"
            className="p-4 flex-1"
          />
        );
      default:
        return (
          <iframe
            src={fileLink}
            width="100%"
            height="100%"
            className="p-4 flex-1"
          />
        );
    }
  }

  return (
    <div className="flex-1 flex flex-row items-center justify-center h-full w-full relative overflow-x-scroll">
      {getViewContent()}
    </div>
  );
}

function ViewWindow({ selectedAttachments, isSplitViewActive }) {
  return (
    <div className="h-[500px] w-full flex flex-row items-center justify-center space-x-2 border-2 rounded-lg border-indigo-100 dark:border-gray-700 divide-x-2 divide-indigo-100 dark:divide-gray-700">
      {!selectedAttachments ? (
        <div className="flex flex-col items-center justify-center space-y-5">
          <img
            className="flex self-center"
            width="120px"
            src={OttoViewer}
          />
          <h5 className="text-center text-sm text-gray-400 dark:text-gray-500">
            {isSplitViewActive
              ? "Please select 2 attachments from below to view them here."
              : "Please select an attachment from below to view it here."}
          </h5>
        </div>
      ) : (
        <>
          <FileView
            selectedAttachments={selectedAttachments}
            fileIndex={0}
          />
          {isSplitViewActive ? (
            <FileView
              selectedAttachments={selectedAttachments}
              fileIndex={1}
            />
          ) : null}
        </>
      )}
    </div>
  );
}

export function AttachmentViewer({
  navigate,
  isSplitViewActive,
  setIsSplitViewActive,
  selectedAttachments,
  setSelectedAttachments,
  setShouldShowDeleteAttachmentModal,
}) {
  function renderAttachmentActionButtons() {
    return (
      <div
        id="attachmentActionButtons"
        className={`flex flex-row items-center h-10 ${
          !isSplitViewActive && selectedAttachments
            ? "justify-between"
            : "justify-end"
        }`}
      >
        {!isSplitViewActive && selectedAttachments ? (
          <div className="flex flex-row items-center justify-start space-x-2">
            <BasicButton>
              <a
                href={selectedAttachments[0]?.fileLink || ""}
                download
              >
                <FontAwesomeIcon icon={faDownload} className="mr-1" />{" "}
                Download Attachment
              </a>
            </BasicButton>
            {selectedAttachments[0]?.note?.uuid ? (
              <BasicButton
                onClick={() => {
                  navigate(
                    `/edit_note/${selectedAttachments[0].note.uuid}`,
                  );
                }}
              >
                <FontAwesomeIcon
                  icon={faNotesMedical}
                  className="mr-1"
                />{" "}
                Jump to note
              </BasicButton>
            ) : null}
            <button
              onClick={() => setShouldShowDeleteAttachmentModal(true)}
              className="w-7 h-7 flex items-center justify-center text-white bg-red-400 rounded-full cursor-pointer hover:bg-red-500 transition-colors"
            >
              <FontAwesomeIcon icon={faTrash} size="xs" />
            </button>
          </div>
        ) : null}
        <div className="hidden lg:flex lg:flex-row lg:justify-end">
          <h5 className="text-xs md:text-sm mr-2">Split View</h5>
          <ToggleSwitch
            toggleState={isSplitViewActive}
            setToggleState={setIsSplitViewActive}
            toggleCallback={() => setSelectedAttachments(null)}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      {renderAttachmentActionButtons()}

      <ViewWindow
        isSplitViewActive={isSplitViewActive}
        selectedAttachments={selectedAttachments}
      />
    </>
  );
}
