import { useCombobox } from "downshift";
import React, { useState } from "react";

function ArrowIcon({ isOpen }) {
  return (
    <svg
      viewBox="0 0 20 20"
      preserveAspectRatio="true"
      width={11}
      fill="transparent"
      stroke="#6b7280"
      strokeWidth="3px"
      transform={isOpen ? "rotate(180)" : undefined}
    >
      <path d="M1,6 L10,15 L19,6" />
    </svg>
  );
}

export default function CreatePatientBreedDropdown({
  items,
  onChange,
  value,
  inputClassName,
}) {
  const [inputItems, setInputItems] = useState(items);
  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    dataCy,
  } = useCombobox({
    onIsOpenChange: () => {
      setInputItems(items);
    },
    onSelectedItemChange: ({ inputValue }) => {
      onChange(inputValue);
    },
    items: inputItems,
    onInputValueChange: ({ inputValue }) => {
      setInputItems(
        items.filter((item) =>
          item.toLowerCase().includes(inputValue.toLowerCase()),
        ),
      );
      onChange(inputValue);
    },
  });

  return (
    <div className="relative">
      <div {...getComboboxProps()} className="relative">
        <input
          name="breed"
          {...getInputProps({
            value,
            placeholder: "Select or type in breed...",
          })}
          className={inputClassName}
          data-cy="pBreedInput"
        />
        <button
          type="button"
          className="absolute z-10 right-4 top-3.5 focus:outline-none"
          {...getToggleButtonProps()}
          aria-label="toggle menu"
          data-cy={dataCy ? dataCy : "pBreedDropdown"}
        >
          <ArrowIcon isOpen={isOpen} />
        </button>
      </div>
      <ul
        {...getMenuProps()}
        className={
          isOpen
            ? "absolute z-20 w-full shadow-md border bg-gray-100 dark:bg-gray-700 border-gray-100 dark:border-gray-800 rounded-lg max-h-12 overflow-y-auto transform -translate-y-2"
            : "hidden"
        }
      >
        {inputItems.map((item, index) => (
          <li
            className={
              highlightedIndex === index
                ? "py-1 px-2 bg-indigo-50 dark:bg-indigo-800 dark:text-gray-200 text-xs"
                : "py-1 px-2 bg-gray-100 dark:bg-gray-700 text-xs dark:text-gray-200"
            }
            key={`${item}${index}`}
            {...getItemProps({ item, index })}
          >
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
}
