import React from "react";
import {
  Controller,
  useFormContext,
  useFormState,
} from "react-hook-form";

import { isDateInputValid } from "./utils.js";
import { Input, ErrorMessage } from "../inputs/Input.js";

export function DateInput({ control }) {
  const { errors } = useFormState({ control });

  return (
    <div className="flex flex-col items-center space-x-2">
      <div className="flex flex-row space-x-2" data-cy="dateInput">
        <DateInputPart
          name={"dd"}
          placeholder={"DD"}
          control={control}
        />
        <DateInputPart
          name={"mm"}
          placeholder={"MM"}
          control={control}
        />
        <DateInputPart
          name={"yyyy"}
          placeholder={"YYYY"}
          control={control}
        />
      </div>

      {errors.dd || errors.mm || errors.yyyy ? (
        <ErrorMessage
          message="This date is not valid. Please ensure you've
          entered a valid, past date in the format of DD, MM, YYYY"
        />
      ) : null}
    </div>
  );
}

function DateInputPart({ control, name, placeholder }) {
  const { getValues } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        validate: {
          dateValidation: () => {
            const { dd, mm, yyyy } = getValues();
            return isDateInputValid({
              dd,
              mm,
              yyyy,
            });
          },
        },
      }}
      render={({ field }) => (
        <Input placeholder={placeholder} {...field} />
      )}
    />
  );
}
