import { useMutation } from "@apollo/client";
import React, {
  useState,
  createContext,
  useContext,
  useEffect,
} from "react";
import { useLocation } from "react-router-dom";

import { alert } from "../components/common/Alert";
import { getShouldAllowCopyAction } from "../components/common/multiselect/utils";
import { DELETE_NOTES } from "../components/notes/edit/api/mutations";

const noteMultiselectContext = createContext();

export const useNoteMultiselectContext = () => {
  return useContext(noteMultiselectContext);
};

/**
 * @typedef {Object} NoteMultiselectContext
 * @property {Object} selectedNoteUuids - an object with keys as uuids of the currently selected notes in state
 * @property {number} numSelectedNotes - count of selected notes
 * @property {boolean} shouldAllowCopyAction - determine if the "copy" button is disabled.
 * @property {boolean} shouldShowDeleteNoteModal - determine if the delete modal should show
 * @property {number} numPatientNotes - count of selected patient notes
 * @property {function} setSelectedNoteUuids - set state
 * @property {function} setNumSelectedNotes - set state
 * @property {function} setShouldAllowCopyAction - set state
 * @property {function} setShouldShowDeleteNoteModal - set state
 * @property {function} setNumPatientNotes - set state
 */

/**
 *
 * @returns {NoteMultiselectContext} context
 */
export function useProvideMultiselect() {
  const location = useLocation();
  const [selectedNoteUuids, setSelectedNoteUuids] = useState({});
  const [numPatientNotes, setNumPatientNotes] = useState(0);
  const [numSelectedNotes, setNumSelectedNotes] = useState(0);
  const [shouldAllowCopyAction, setShouldAllowCopyAction] =
    useState(true);
  const [shouldShowDeleteNoteModal, setShouldShowDeleteNoteModal] =
    useState(false);

  function resetSelectedNotesList() {
    setSelectedNoteUuids({});
    setNumSelectedNotes(0);
    setNumPatientNotes(0);
  }

  // Reset on new page
  useEffect(() => {
    resetSelectedNotesList();
  }, [location]);

  return {
    selectedNoteUuids,
    numSelectedNotes,
    shouldAllowCopyAction,
    shouldShowDeleteNoteModal,
    numPatientNotes,
    setSelectedNoteUuids,
    setNumSelectedNotes,
    setShouldAllowCopyAction,
    setShouldShowDeleteNoteModal,
    resetSelectedNotesList,
    setNumPatientNotes,
  };
}

// Available to any child component that calls useNoteMultiselectContext().
export function NoteMultiselectProvider({ children }) {
  const noteMultiselect = useProvideMultiselect();

  return (
    <noteMultiselectContext.Provider value={noteMultiselect}>
      {children}
    </noteMultiselectContext.Provider>
  );
}

export function useNoteMultiselect({ notes, refetch }) {
  const {
    shouldAllowCopyAction,
    setShouldAllowCopyAction,
    selectedNoteUuids,
    numSelectedNotes,
    setShouldShowDeleteNoteModal,
    resetSelectedNotesList,
  } = useNoteMultiselectContext();

  const [deleteNotes, { loading }] = useMutation(DELETE_NOTES);

  useEffect(() => {
    if (!notes) {
      return;
    }

    // Check within selectedNoteUuids if any notes are not able to be copied.
    // filters for notes that are selected and are not submitted or new recordings.

    const nextShouldAllowCopyAction = getShouldAllowCopyAction({
      notes,
      selectedNoteUuids,
      numSelectedNotes,
    });

    if (shouldAllowCopyAction !== nextShouldAllowCopyAction) {
      // Avoid updating state unless necessary (ie, has changed)
      setShouldAllowCopyAction(nextShouldAllowCopyAction);
    }
  }, [selectedNoteUuids, numSelectedNotes]);

  function handleDeleteNotes() {
    deleteNotes({
      variables: { noteUuids: Object.keys(selectedNoteUuids) },
    })
      .then(() => {
        setShouldShowDeleteNoteModal(false);
        resetSelectedNotesList();

        alert(
          "success",
          `Note${
            numSelectedNotes > 1 ? "s" : ""
          } deleted successfully.`,
        );
        refetch();
      })
      .catch((response) => {
        const errors = response.graphQLErrors.map((error) =>
          alert("error", error.message),
        );
        // need to catch errors to prevent exception
        // might as well console log to debug if client has issue
        console.log(errors);
      });
  }

  return {
    handleDeleteNotes,
    resetSelectedNotesList,
    loading,
    shouldAllowCopyAction,
  };
}
