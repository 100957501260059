import { gql, useQuery } from "@apollo/client";
import React from "react";
import { useParams } from "react-router";

import { AuditTrailList } from "./AuditTrailList";
import FullScreenLoading from "../common/loading/FullScreenLoading";
import FourohFour from "../home/FourohFour";
import PageTitle from "../layout/PageTitle";

const GET_PATIENT_AUDIT_TRAIL = gql`
  query patient($uuid: String) {
    patient(uuid: $uuid) {
      name
      lastNameOfOwner
      auditTrail {
        uuid
        fileName
        createdAt
      }
    }
  }
`;

export function AuditTrailPatient() {
  const { patientUUID } = useParams();

  const { data, loading } = useQuery(GET_PATIENT_AUDIT_TRAIL, {
    variables: { uuid: patientUUID },
  });

  if (!data && loading) {
    <FullScreenLoading loadingTrigger={loading} />;
  }

  if (!data) {
    return <FourohFour />;
  }

  const title =
    "Past patient revisions for " +
    data?.patient?.name +
    " " +
    data?.patient?.lastNameOfOwner;

  return (
    <div>
      <PageTitle className="mb-6" title={title} />
      <AuditTrailList auditTrail={data?.patient?.auditTrail} />
    </div>
  );
}
