import { useMutation } from "@apollo/client";
import { faCheck, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { createPortal } from "react-dom";
import { useLocation } from "react-router-dom";

import MARK_NOTES_EXPORTED from "../../graphql/mutations/MarkNotesExported.js";
import { useWindowDimensions } from "../../hooks";
import Twemoji from "../../Twemoji.js";
import { alert } from "../common/Alert.js";
import { SoapEditButton } from "../common/clinical/SoapEditButton.js";
import { ConfirmModal } from "../common/modals/PopupModals.js";
import ExistingNoteEmailExportButton from "../email/ExistingNoteEmailExportButton.js";
import CopyFullSOAP from "../notebook/note/CopyFullSOAP.js";
import ExportToPDF from "../notebook/note/ExportToPDF.js";
import NoteCreatedAt from "../notebook/note/NoteCreatedAt";
import NotesExpandableSection from "../notebook/note/NotesExpandableSection.js";
import PatientTitle from "../notebook/PatientTitle.js";
import { DELETE_NOTE } from "../notes/edit/api/mutations.js";

const ExportNoteItem = ({
  exportNoteData,
  refetch,
  isForSharedExport = false,
  isAlreadyExported = false,
  activeTab,
}) => {
  const [shouldShowDeleteNoteModal, setShouldShowDeleteNoteModal] =
    useState(false);
  const [modifyNotes] = useMutation(MARK_NOTES_EXPORTED);
  const [deleteNote] = useMutation(DELETE_NOTE);

  const { width } = useWindowDimensions();

  const location = useLocation();

  function handleDeleteNote(noteUUID) {
    deleteNote({ variables: { noteUuid: noteUUID } }).then(() => {
      alert("success", "Note deleted.");
      refetch();
      setShouldShowDeleteNoteModal(false);
    });
  }

  return (
    <>
      {createPortal(
        <ConfirmModal
          shouldShow={shouldShowDeleteNoteModal}
          hideModal={() => setShouldShowDeleteNoteModal(false)}
          title="Delete this note?"
          confirmText="Delete"
          confirmAction={() => handleDeleteNote(exportNoteData?.uuid)}
          cancelAction={() => setShouldShowDeleteNoteModal(false)}
        >
          <h5>
            Are you sure you&apos;d like to{" "}
            <strong>delete this note</strong>?
          </h5>
          <h5 className="text-sm font-semibold mt-4">
            <Twemoji emoji={"📝"} /> {exportNoteData?.title}
          </h5>

          <NoteCreatedAt createdAt={exportNoteData?.createdAt} />
        </ConfirmModal>,
        document.getElementById("root-node"),
      )}

      <div className="pt-3 px-3 pb-5 rounded-lg border border-gray-200 dark:border-gray-600 dark:bg-gray-700 bg-gray-50 shadow-sm">
        <div className="flex items-start">
          <div className="flex flex-1 items-center">
            {!exportNoteData?.patient ? (
              <h5 className="font-bold text-indigo-400 text-xl">
                {exportNoteData?.jobTypeName
                  ? exportNoteData?.jobTypeName
                  : "Quicknote"}
              </h5>
            ) : (
              <PatientTitle
                sex={exportNoteData?.patient?.sex}
                species={exportNoteData?.patient?.species}
                name={exportNoteData?.patient?.name}
                patientUUID={exportNoteData?.patient?.uuid}
                lastNameOfOwner={
                  exportNoteData?.patient?.lastNameOfOwner
                }
              />
            )}
            <div className="ml-3">
              <NoteCreatedAt createdAt={exportNoteData?.createdAt} />
            </div>
          </div>
          {width > 500 ? (
            <div className="flex flex-row items-center space-x-2 mb-2">
              {!isForSharedExport && (
                <>
                  {!isAlreadyExported ? (
                    <button
                      title="Mark note as exported"
                      id="markCompletedButton"
                      className="flex-grow-0 self-center border rounded-full w-8 h-8 bg-indigo-500 border-indigo-500 hover:bg-indigo-700 text-white transition-all font-medium outline-none text-sm focus:outline-none"
                      onClick={() => {
                        modifyNotes({
                          variables: {
                            noteUuids: [exportNoteData?.uuid],
                            action: "mark_exported",
                          },
                        }).then(() => {
                          if (location.pathname == "/inbox") {
                            refetch({});
                          } else {
                            if (refetch !== null) {
                              refetch({ isApproved: true });
                            }
                          }
                        });
                        alert("success", "Note marked as exported.");
                      }}
                    >
                      <FontAwesomeIcon icon={faCheck} />
                    </button>
                  ) : (
                    <button
                      title="Delete note"
                      id="deleteExportedNoteButton"
                      className="flex-grow-0 shadow-md self-center border rounded-full w-8 h-8 bg-red-400 border-red-400 hover:bg-red-500 text-white transition-all font-medium outline-none text-sm focus:outline-none"
                      onClick={() =>
                        setShouldShowDeleteNoteModal(true)
                      }
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  )}
                </>
              )}

              {!isForSharedExport && (
                <SoapEditButton note={exportNoteData} />
              )}
              {/* {isForSharedExport ? (
                <> */}
              <CopyFullSOAP
                note={exportNoteData}
                refetch={refetch}
                isForSharedExport={isForSharedExport}
              />

              <ExportToPDF
                note={exportNoteData}
                patient={exportNoteData?.patient}
                user={exportNoteData?.user}
                refetch={refetch}
                activeTab={activeTab}
              />
              {/* </>
              ) : (
                <ExportButton note={exportNoteData} size="sm" />
              )} */}
              {!isForSharedExport ? (
                <ExistingNoteEmailExportButton
                  note={exportNoteData}
                  refetch={refetch}
                  shouldOnlyShowEmailForm={true}
                />
              ) : null}
            </div>
          ) : null}
        </div>

        {exportNoteData?.title ? (
          <h4 className="font-semibold text-sm text-gray-600 dark:text-gray-300">
            <Twemoji emoji="📝" /> {exportNoteData?.title}
          </h4>
        ) : null}

        <NotesExpandableSection data={exportNoteData} />
      </div>
    </>
  );
};

export default ExportNoteItem;
