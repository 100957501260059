import hotkeys from "hotkeys-js";
import { useEffect, useCallback, useState } from "react";

const tagFilter = ({ target, srcElement }, enableOnTags) => {
  const targetTagName =
    (target && target.tagName) || (srcElement && srcElement.tagName);

  return Boolean(
    targetTagName &&
      enableOnTags &&
      enableOnTags.includes(targetTagName),
  );
};

const isKeyboardEventTriggeredByInput = (ev) => {
  return tagFilter(ev, ["INPUT", "TEXTAREA", "SELECT"]);
};

/**
 * Heavily inspired by https://github.com/JohannesKlauss/react-hotkeys-hook
 */
export const useHotkeys = (props) => {
  const { keys, callback } = props;

  const memoisedCallback = useCallback(
    (keyboardEvent, hotkeysEvent) => {
      if (!isKeyboardEventTriggeredByInput(keyboardEvent)) {
        callback(keyboardEvent, hotkeysEvent);
        return true;
      }

      return false;
    },
  );

  useEffect(() => {
    hotkeys(keys, {}, memoisedCallback);

    return () => hotkeys.unbind(keys, memoisedCallback);
  }, [memoisedCallback, keys]);
};

export const useHotkeysHold = (props) => {
  const [isDown, setIsDown] = useState(false);
  const { keys, callbackDown, callbackUp } = props;

  const memoisedCallbackDown = useCallback(
    (keyboardEvent, hotkeysEvent) => {
      callbackDown(keyboardEvent, hotkeysEvent);
      return true;
    },
  );

  const memoisedCallbackUp = useCallback(
    (keyboardEvent, hotkeysEvent) => {
      callbackUp(keyboardEvent, hotkeysEvent);
      return true;
    },
  );

  useEffect(() => {
    hotkeys(keys, { keyup: true }, function (event) {
      if (!isDown) {
        if (event.type === "keydown") {
          memoisedCallbackDown();
          setIsDown(true);
        }
      }

      if (event.type === "keyup") {
        memoisedCallbackUp();
        setIsDown(false);
      }
    });

    return () => {
      hotkeys.unbind(keys);
    };
  }, [memoisedCallbackDown, memoisedCallbackUp, keys]);
};
