import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import MARK_NOTES_EXPORTED from "../../../graphql/mutations/MarkNotesExported.js";
import { alert } from "../../common/Alert.js";
import { checkisAllowedAndDownload } from "../../common/clinical/NotePDF.js";

export const GET_HEADER_INFO = gql`
  query noteUserInfo($uuid: String) {
    note(uuid: $uuid) {
      user {
        firstName
        lastName
        account {
          logoFileLink
          headerInfoPdf
        }
      }
    }
  }
`;

function ExportToPDF({
  note,
  patient,
  user = null,
  refetch = null,
  activeTab,
}) {
  const [getHeaderInfo] = useLazyQuery(GET_HEADER_INFO);
  const [modifyNotes] = useMutation(MARK_NOTES_EXPORTED);
  const location = useLocation();
  const [isCooldown, setIsCooldown] = useState(false);

  const handleClick = async () => {
    if (isCooldown) {
      return;
    }
    setIsCooldown(true);

    setTimeout(() => {
      setIsCooldown(false);
    }, 5000);

    try {
      if (!user) {
        await getHeaderInfo({
          variables: { uuid: note?.uuid },
          onCompleted: async (data) => {
            try {
              await checkisAllowedAndDownload(
                note,
                patient,
                data?.note?.user,
              );
            } catch (response) {
              console.log(response);
            }
          },
        });
      } else {
        try {
          await checkisAllowedAndDownload(note, patient, user);
        } catch (response) {
          console.log(response);
        }
      }
    } catch (e) {
      alert(
        "error",
        "An error has occurred. Please try again later.",
      );
      console.log(e);
      return;
    }

    try {
      await modifyNotes({
        variables: {
          noteUuids: [note.uuid],
          action: "mark_exported",
        },
      });
      alert(
        "info",
        `Check your browser's downloads for your note. ${
          note?.hasBeenExported
            ? ""
            : "Your note has now been marked as exported."
        }`,
      );
    } catch (e) {
      alert(
        "error",
        "Failed to mark the note as exported. Please try again later.",
      );
      console.log(e);
      return;
    }
    if (
      location.pathname === "/inbox" &&
      activeTab !== "exportedItems"
    ) {
      refetch();
    } else {
      if (refetch !== null) {
        refetch({ isApproved: true });
      }
    }
  };

  if (note) {
    return (
      <button
        title="Download note as PDF"
        id="exportNoteIcon"
        disabled={
          note?.isNewRecording ||
          note?.isMarkedAsProcessing ||
          note?.isMarkedAsFailed ||
          isCooldown
        }
        className={
          !note?.isNewRecording &&
          !note?.isMarkedAsProcessing &&
          !note?.isMarkedAsFailed &&
          !isCooldown
            ? "flex-grow-0 self-center border rounded-full w-8 h-8 bg-indigo-500 border-indigo-500 hover:bg-indigo-700 text-white transition-all font-medium outline-none text-sm shadow-md focus:outline-none"
            : "flex-grow-0 self-center border rounded-full w-8 h-8 bg-gray-300 border-gray-300 dark:bg-gray-600 dark:border-gray-600 text-white transition-all font-medium outline-none text-sm focus:outline-none cursor-not-allowed"
        }
        onClick={handleClick}
      >
        <FontAwesomeIcon icon={faFileDownload} />
      </button>
    );
  } else {
    return null;
  }
}

export default ExportToPDF;
