import { faSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import styles from "./RecordButton.module.css";

export const StopButton = ({ isPaused, onClick }) => {
  return (
    <div
      className={`${isPaused ? "" : styles.stopButton}
       ${
         isPaused
           ? " from-gray-400 to-gray-80 "
           : " from-indigo-400 to-indigo-800 "
       }
      absolute z-20 w-3/4 h-3/4 cursor-pointer flex text-center rounded-full transition-all bg-gradient-to-br place-items-center place-content-center focus:outline-none shadow-lg`}
      onClick={onClick}
    >
      <FontAwesomeIcon
        icon={faSquare}
        size="3x"
        color="white"
        className="mx-auto"
      />
    </div>
  );
};
