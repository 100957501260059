import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";

import { EmailExportTypeRadioButton } from "./EmailExportTypeRadioButton";
import EDIT_USER from "../../graphql/mutations/EditUser.js";
import { alert } from "../common/Alert";

export function EmailExportTypeForm({
  control,
  setValue,
  data,
  isForAccountSetting = false,
  refetch,
}) {
  const [embedEmailExports, setEmbedEmailExports] = useState(
    data?.user?.shouldEmbedEmailExports,
  );

  const [editUser] = useMutation(EDIT_USER);

  useEffect(() => {
    if (
      data &&
      embedEmailExports !== data?.user?.shouldEmbedEmailExports
    ) {
      editUser({
        variables: {
          shouldEmbedEmailExports: embedEmailExports,
        },
      })
        .then(() => {
          alert("success", "Account updated successfully.");
          refetch();
        })
        .catch((err) => alert("error", err.message));
    }
  }, [embedEmailExports]);

  useEffect(() => {
    if (data?.user?.shouldEmbedEmailExports) {
      setValue(
        "exportType",
        data?.user?.shouldEmbedEmailExports ? "embeddedText" : "link",
      );
    }
  }, [data]);

  return (
    <Controller
      control={control}
      rules={{
        required: true,
      }}
      render={({ field: { onChange, value } }) => (
        <div className="md:flex-1 flex flex-col lg:flex-row lg:items-center lg:space-x-4 mb-6">
          <EmailExportTypeRadioButton
            title={"Email as link"}
            description={`${
              isForAccountSetting
                ? "Automatically exported notes"
                : "This note"
            } will be exported as an email containing
                    a secure link that will direct to a webpage
                    from which the note can be accessed.`}
            onChange={(e) => {
              onChange(e);
              setEmbedEmailExports(false);
            }}
            value={"link"}
            currentValue={value}
          />
          <EmailExportTypeRadioButton
            title={"Email as embedded text"}
            description={`${
              isForAccountSetting
                ? "Automatically exported notes"
                : "This note"
            } will be exported as an email containing the
                note's text content. Attachments cannot be included in
                the email.`}
            onChange={(e) => {
              onChange(e);
              setEmbedEmailExports(true);
            }}
            value={"embeddedText"}
            currentValue={value}
          />
        </div>
      )}
      name="exportType"
      defaultValue={
        data?.user?.shouldEmbedEmailExports ? "embeddedText" : "link"
      }
    />
  );
}
