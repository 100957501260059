import { React } from "react";
import { createPortal } from "react-dom";

import { ConfirmModal } from "../modals/PopupModals";

export function CancelRecordingModal({
  shouldShowCancelRecordingModal,
  setShouldShowCancelRecordingModal,
  cancelRecording,
}) {
  if (document.readyState === "complete") {
    return createPortal(
      <ConfirmModal
        shouldShow={shouldShowCancelRecordingModal}
        hideModal={() => setShouldShowCancelRecordingModal(false)}
        title={"Cancel this recording?"}
        confirmText="Yes, cancel"
        cancelText="Keep recording"
        confirmAction={() => {
          cancelRecording();
          setShouldShowCancelRecordingModal(false);
        }}
        cancelAction={() => setShouldShowCancelRecordingModal(false)}
      >
        <h5>
          Are you sure you&apos;d like to cancel this recording? You
          will lose all the audio you&apos;ve recorded so far.
        </h5>
      </ConfirmModal>,
      document.body,
    );
  } else {
    return null;
  }
}
