import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Controller } from "react-hook-form";

import { TEMPLATE_COLOR_SCHEMES } from "../../constants";

export function ColorSchemePicker({ control }) {
  const colors = Object.keys(TEMPLATE_COLOR_SCHEMES);

  return (
    <Controller
      control={control}
      rules={{
        required: false,
      }}
      render={({ field: { onChange, value } }) => (
        <div className="flex flex-row flex-wrap max-w-28 md:ml-4">
          {colors.map((color, index) => {
            let colorPickerItemClassName =
              "m-1 flex justify-center items-center text-gray-100 dark:text-gray-600 rounded-full w-8 h-8 border-2 border-transparent hover:border-gray-100 dark:hover:border-gray-600 transition-all focus:outline-none cursor-pointer";

            colorPickerItemClassName += ` ${TEMPLATE_COLOR_SCHEMES[color].headerColor}`;

            return (
              <div
                key={index}
                className={colorPickerItemClassName}
                onClick={() => {
                  onChange(colors[index]);
                }}
              >
                {colors.indexOf(value) === index ? (
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="fill-current"
                  />
                ) : null}
              </div>
            );
          })}
        </div>
      )}
      name="colorScheme"
    />
  );
}
