import { gql } from "@apollo/client";

export default gql`
  mutation editUser(
    $email: String
    $firstName: String
    $lastName: String
    $isOnboardingComplete: Boolean
    $autoExportEmail: String
    $emailExpiryDays: Int
    $shouldEmbedEmailExports: Boolean
    $shouldUseAutoSoaps: Boolean
    $isAppointmentsBetaUser: Boolean
  ) {
    editUser(
      email: $email
      firstName: $firstName
      lastName: $lastName
      isOnboardingComplete: $isOnboardingComplete
      autoExportEmail: $autoExportEmail
      emailExpiryDays: $emailExpiryDays
      shouldEmbedEmailExports: $shouldEmbedEmailExports
      shouldUseAutoSoaps: $shouldUseAutoSoaps
      isAppointmentsBetaUser: $isAppointmentsBetaUser
    ) {
      user {
        email
        firstName
        lastName
        isOnboardingComplete
        autoExportEmail
        emailExpiryDays
        shouldEmbedEmailExports
        shouldUseAutoSoaps
        isAppointmentsBetaUser
      }
    }
  }
`;
