import React from "react";
import { Controller, useFormState } from "react-hook-form";

import { FormatHint } from "./FormatHint.js";
import { Input, ErrorMessage } from "../inputs/Input.js";

export function AgeInput({ control }) {
  const { errors } = useFormState({ control });

  return (
    <div className="flex flex-col items-center space-x-2">
      <FormatHint>Format: # weeks/months/years</FormatHint>

      <div className="flex flex-row space-x-2">
        <AgeInputPart
          control={control}
          name={"weeks"}
          minValue={0}
          maxValue={52}
        />
        <AgeInputPart
          control={control}
          name={"months"}
          minValue={0}
          maxValue={12}
        />
        <AgeInputPart
          control={control}
          name={"years"}
          minValue={0}
          maxValue={100}
        />
      </div>

      {errors.weeks && (
        <ErrorMessage message="Weeks must be a number less than 52. " />
      )}
      {errors.months && (
        <ErrorMessage message="Months must be a number less than 12. " />
      )}
      {errors.years && (
        <ErrorMessage message="Years must be a number less than 100. " />
      )}
    </div>
  );
}

function AgeInputPart({ control, minValue, maxValue, name }) {
  function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        validate: {
          positiveNumber: (value) => {
            parseFloat(value) >= minValue &&
              parseFloat(value) < maxValue;
          },
        },
      }}
      render={({ field }) => (
        <Input
          placeholder={capitalize(name)}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          {...field}
        />
      )}
    />
  );
}
