import { useMutation } from "@apollo/client";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import mixpanel from "mixpanel-browser";
import { useState } from 'react';
import { useNavigate } from "react-router-dom";

import EDIT_USER from "../../graphql/mutations/EditUser.js";
import { useAuth } from "../../hooks";
import { alert } from "../common/Alert.js";
import { AccountItem, AccountItemLine } from "./AccountItem";

export default function ProfileSettings({ user }) {
  const navigate = useNavigate();

  return (
    <AccountItem
      title="Profile"
      icon={<FontAwesomeIcon icon={faUser} size="lg" color="gray" />}
    >
      <div className="flex-1 flex mr-4 md:space-x-4">
        <div className="flex-1 divide-y dark:divide-gray-700">
          <AccountItemLine title="Name:">
            <ProfileNameSection user={user} />
          </AccountItemLine>
          <AccountItemLine title="Email:">
            <Tooltip
              arrow
              placement="right"
              title={
                user?.isConfirmed
                  ? "You have successfully confirmed your email."
                  : "You have not confirmed your email yet."
              }
            >
              <div className="flex flex-row items-center">
                {user?.email}{" "}
                {user?.isConfirmed ? (
                  <span className="text-green-500 text-xs ml-1">
                    ✓
                  </span>
                ) : (
                  <>
                    <div className="flex flex-row items-center space-x-3">
                      <span className="text-red-500 text-xs ml-1">
                        ✗
                      </span>

                      <button
                        className="text-sm font-medium text-indigo-400 transition-all hover:text-indigo-700 focus:outline-none"
                        onClick={() =>
                          navigate("/registration_confirmation")
                        }
                      >
                        Manage Email
                      </button>
                    </div>
                  </>
                )}
              </div>
            </Tooltip>
          </AccountItemLine>
          <AccountItemLine title="User ID:">
            {user?.uuid}
          </AccountItemLine>
        </div>
      </div>
    </AccountItem>
  );
}

function ProfileNameSection({ user }) {
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [editedFirstName, setEditedFirstName] = useState('');
  const [editedLastName, setEditedLastName] = useState('');
  const [editUser] = useMutation(EDIT_USER);
  const auth = useAuth();

  const onSaveClicked = async () => {
    setIsSaving(true);

    try {
      await editUser({
        variables: {
          firstName: editedFirstName,
          lastName: editedLastName,
        },
      });
      await auth.refetchUserDetails();
      setIsEditing(false);

      alert("success", "Account updated successfully.");
    } catch (err) {
      mixpanel.track("Update Name Failed", {
        error: err.message || err,
      });
      alert('error', err.message);
    } finally {
      setIsSaving(false);
    }
  };

  if (isEditing) {
    const isValid = editedFirstName.length > 0 && editedLastName.length > 0;
    return <form className={`flex flex-col md:flex-row md:items-center md:space-x-2 md:space-y-0 space-y-2`}>
      <input
        type="text"
        placeholder="First name"
        maxLength={100}
        value={editedFirstName}
        onChange={e => setEditedFirstName(e.target.value)}
        className={`appearance-none relative px-3 py-2 border dark:bg-gray-700 dark:border-gray-700 dark:text-gray-200 border-gray-100 bg-gray-100 placeholder-gray-400 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-300 focus:z-10 text-xs md:text-sm`}
        disabled={isSaving} />
      <input
        type="text"
        placeholder="Last name"
        maxLength={100}
        value={editedLastName}
        onChange={e => setEditedLastName(e.target.value)}
        className={`appearance-none relative px-3 py-2 border dark:bg-gray-700 dark:border-gray-700 dark:text-gray-200 border-gray-100 bg-gray-100 placeholder-gray-400 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-300 focus:z-10 text-xs md:text-sm`}
        disabled={isSaving} />
      <button
        type='reset'
        className={`w-full font-medium text-red-400 bg-red-50 dark:bg-gray-600 dark:hover:bg-gray-700 hover:bg-red-100 rounded-full text-sm text-center cursor-pointer py-1 px-3 transition-all focus:outline-none`}
        disabled={isSaving}
        onClick={() => setIsEditing(false)}
      >
        Cancel
      </button>
      <button
        type="submit"
        className={`w-full font-medium text-white bg-indigo-500 hover:bg-indigo-600 rounded-full text-sm text-center cursor-pointer py-1 px-3 transition-all focus:outline-none`}
        disabled={isSaving || !isValid}
        onClick={onSaveClicked}
      >
        Save
      </button>
    </form>
  }

  return <>
    {user?.firstName} {user?.lastName}
    <button
      className="text-sm font-medium text-indigo-400 transition-all hover:text-indigo-700 focus:outline-none"
      onClick={() => {
        setIsEditing(true);
        setEditedFirstName(user?.firstName || '');
        setEditedLastName(user?.lastName || '');
      }}
    >
      Update
    </button>
  </>
}
