import { gql, useMutation } from "@apollo/client";
import { faToolbox } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import UPDATE_USER_FF_SEGMENT from "../../graphql/mutations/UpdateUserFfSegment.js";
import { AccountItem, AccountItemLine } from "./AccountItem.js";
import DarkModeToggle from "./DarkModeToggle.js";
import { useLocalStorage } from "../../hooks/use-local-storage.js";
import ToggleSwitch from "../common/buttons/ToggleSwitch";
import { useFlags } from "launchdarkly-react-client-sdk";
import { alert } from "../common/Alert.js";

const EDIT_ACCOUNT_PREFERENCES = gql`
  mutation editAccountPreferences(
    $shouldHidePresetTemplates: Boolean
  ) {
    editAccountPreferences(
      shouldHidePresetTemplates: $shouldHidePresetTemplates
    ) {
      ok
    }
  }
`;

export default function PreferencesSettings({ data, refetch }) {
  const [shouldHidePresetTemplates, setShouldHidePresetTemplates] =
    useState(false);

  const [editAccountPreferences] = useMutation(
    EDIT_ACCOUNT_PREFERENCES,
  );

  useEffect(() => {
    setShouldHidePresetTemplates(
      data?.user?.account?.shouldHidePresetTemplates,
    );
  }, [data]);

  return (
    <div id="preferences">
      <AccountItem
        title="Preferences"
        icon={
          <FontAwesomeIcon icon={faToolbox} size="lg" color="gray" />
        }
      >
        <div className="flex flex-col flex-1 w-full space-y-4 divide-y dark:divide-gray-700">
          <AccountItemLine>
            <div className="dark:text-gray-300 text-xs md:text-sm lg:w-96 mr-4">
              <strong>Opt out of NEW Medical Record AI: </strong>
              Do you want to revert to your old Medical Record AI?
              Turn this on!
            </div>
            <OptOutNewSoapPromptToggle />
          </AccountItemLine>
          <AccountItemLine>
            <div className="dark:text-gray-300 text-xs md:text-sm lg:w-96 mr-4">
              <strong>Appearance:</strong> Using Scribenote late at
              night? Turn on dark mode and your eyes will thank you!
              👀
            </div>
            <DarkModeToggle />
          </AccountItemLine>
          <AccountItemLine>
            <div className="dark:text-gray-300 text-xs md:text-sm lg:w-96 mr-4">
              <strong>Capitalize SOAP Titles:</strong> When you copy
              an entire SOAP, this setting will determine if the S, O,
              A, and P titles are capitalized for readability.
            </div>
            <CapitalFormatToggle />
          </AccountItemLine>
          <AccountItemLine>
            <div className="dark:text-gray-300 text-xs md:text-sm lg:w-96 mr-4">
              <strong>Hide Preset Templates:</strong> This setting
              will turn off the default Scribenote templates.
            </div>
            <div>
              <ToggleSwitch
                toggleState={shouldHidePresetTemplates}
                setToggleState={setShouldHidePresetTemplates}
                toggleCallback={() => {
                  editAccountPreferences({
                    variables: {
                      shouldHidePresetTemplates:
                        // state has already changed, so save the opposite
                        !shouldHidePresetTemplates,
                    },
                  });

                  refetch();
                }}
              />
            </div>
          </AccountItemLine>
          <AccountItemLine>
            <div className="dark:text-gray-300 text-xs md:text-sm lg:w-96 mr-4">
              <strong>Advanced Name Correction:</strong> Notice your
              name being misspelled consistently? Enable this to
              automatically correct name misspellings in your future
              notes based on your account name.
            </div>
            <NameCorrectionToggle />
          </AccountItemLine>
          <AccountItemLine>
            <div className="dark:text-gray-300 text-xs md:text-sm lg:w-96 mr-4">
              <strong>Copy Note on Submit:</strong> Turning this
              setting on will automatically copy the note to your
              clipboard when you submit it.
            </div>
            <CopySubmitToggle />
          </AccountItemLine>
        </div>
      </AccountItem>
    </div>
  );
}

function CapitalFormatToggle() {
  const [
    shouldUseCapitalSubtitlesCopy,
    setShouldUseCapitalSubtitlesCopy,
  ] = useLocalStorage("should-use-capital-subtitles-copy");

  return (
    <div className="flex flex-row items-center space-x-2 mx-2 cursor-pointer">
      <p className="text-sm">Don't Capitalize</p>
      <ToggleSwitch
        toggleState={shouldUseCapitalSubtitlesCopy}
        setToggleState={setShouldUseCapitalSubtitlesCopy}
      />
      <p className="text-sm">Capitalize</p>
    </div>
  );
}

function OptOutNewSoapPromptToggle() {
  const { useSonnet: initialUseSonnetState } = useFlags();
  const [usingSonnetPrompt, setOptOutOfSonnet] = useState(
    initialUseSonnetState,
  );

  const [updateUserFfSegment, { loading }] = useMutation(
    UPDATE_USER_FF_SEGMENT,
  );

  useEffect(() => {
    setOptOutOfSonnet(initialUseSonnetState);
  }, [initialUseSonnetState]);

  const setUseSonnetState = async () => {
    if (loading) return;
    try {
      //if usingSonnetPrompt is true, then we want to opt out of sonnet by adding them to the segment
      const response = await updateUserFfSegment({
        variables: {
          action: usingSonnetPrompt ? "ADD" : "REMOVE",
          segmentKey: "opt-out-sonnet",
        },
      });

      if (!response.data.updateUserFfSegment.success) {
        alert(
          "error",
          "Failed to update setting. Please try again later or contact support.",
        );
      }
      setOptOutOfSonnet(!usingSonnetPrompt);
    } catch (error) {
      alert(
        "error",
        "An error has occurred. Please try again later or contact support.",
      );
    }
  };

  // if usingSonnetPrompt is true we want the toggle to be off ( show they are not currently opting out of sonnet)
  return (
    <div className="flex flex-row items-center space-x-2 mx-2 cursor-pointer">
      <ToggleSwitch
        toggleState={!usingSonnetPrompt}
        setToggleState={setUseSonnetState}
        disabled={loading}
      />
    </div>
  );
}

function NameCorrectionToggle() {
  const { useNameCorrection: initialUseNameCorrection } = useFlags();
  const [useNameCorrection, setUseNameCorrection] = useState(
    initialUseNameCorrection,
  );

  const [updateUserFfSegment, { loading }] = useMutation(
    UPDATE_USER_FF_SEGMENT,
  );

  useEffect(() => {
    setUseNameCorrection(initialUseNameCorrection);
  }, [initialUseNameCorrection]);

  const setNameCorrection = async () => {
    if (loading) return;
    try {
      const response = await updateUserFfSegment({
        variables: {
          action: useNameCorrection ? "REMOVE" : "ADD",
          segmentKey: "name-correction-toggle",
        },
      });

      if (!response.data.updateUserFfSegment.success) {
        alert(
          "error",
          "Failed to update setting. Please try again later or contact support.",
        );
      }
      setUseNameCorrection(!useNameCorrection);
    } catch (error) {
      alert(
        "error",
        "An error has occurred. Please try again later or contact support.",
      );
    }
  };

  return (
    <div className="flex flex-row items-center space-x-2 mx-2 cursor-pointer">
      <ToggleSwitch
        toggleState={useNameCorrection}
        setToggleState={setNameCorrection}
        disabled={loading}
      />
    </div>
  );
}

function CopySubmitToggle() {
  const [shouldCopyOnSubmit, setShouldCopyOnSubmit] = useLocalStorage(
    "should-copy-on-submit",
    true,
  );

  return (
    <div className="flex flex-row items-center space-x-2 mx-2 cursor-pointer">
      <p className="text-sm">Don't Copy</p>
      <ToggleSwitch
        toggleState={shouldCopyOnSubmit}
        setToggleState={setShouldCopyOnSubmit}
      />
      <p className="text-sm">Copy</p>
    </div>
  );
}
