import { useMutation } from "@apollo/client";
import { useEffect } from "react";
import { useNavigate } from "react-router";

import { EDIT_TEMPLATE } from "./mutations.js";
import { TEMPLATE_COLOR_SCHEMES } from "../../constants.js";
import { alert } from "../common/Alert.js";
import { formToNoteInput } from "../notes/common/utils";

function NewTemplate() {
  const navigate = useNavigate();
  const [editTemplate] = useMutation(EDIT_TEMPLATE);

  useEffect(() => {
    const submitAsync = async () => {
      const structuredTemplateData = formToNoteInput({
        data: {},
        shouldSaveAsGenericObjective: true,
        isNewNote: true,
      });
      structuredTemplateData.noteUuid = null;

      // Use near identical logic as in Scribenote Mobile
      const variables = {
        noteInput: { ...structuredTemplateData },
        templateUuid: null,
        templateFields: {
          name: "My template",
          description: "",
          colorScheme: Object.keys(TEMPLATE_COLOR_SCHEMES)[0],
        },
      };

      editTemplate({
        variables,
      })
        .then((response) => {
          const templateUuid =
            response.data.editTemplate.template.uuid;

          alert("success", "New template created. Autosaving...");
          navigate(`/templates/edit/${templateUuid}`, {
            replace: true,
          });
        })
        .catch((error) => {
          alert("error", error.message);
        });
    };

    submitAsync();
  }, []);

  return null;
}

export default NewTemplate;
