import {
  faCheck,
  faCircle,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import React from "react";

import { BasicButton } from "../../../../common/buttons/BasicButton.js";

const CustomizationOptions = ({
  frontmatter,
  setFrontmatter,
  frontmatterRef,
  backmatter,
  setBackmatter,
  backmatterRef,
  clientSummaryOptions,
  handleSave,
  saveButtonText,
  handleGenerateSummary,
  setClientSummaryOptions,
}) => {
  const toggleOption = (option) => {
    setClientSummaryOptions((prevOptions) => {
      if (
        option === "oneLineSummary" ||
        option === "pointFormSummary"
      ) {
        const otherOption =
          option === "oneLineSummary"
            ? "pointFormSummary"
            : "oneLineSummary";
        return {
          ...prevOptions,
          [option]: {
            ...prevOptions[option],
            include: !prevOptions[option].include,
          },
          [otherOption]: {
            ...prevOptions[otherOption],
            include: false,
          },
        };
      }

      return {
        ...prevOptions,
        [option]: {
          ...prevOptions[option],
          include: !prevOptions[option].include,
        },
      };
    });
  };

  return (
    <>
      <h4 className="font-semibold text-gray-700">Opening Notes</h4>
      <textarea
        data-cy="frontmatter-textarea"
        ref={frontmatterRef}
        value={frontmatter}
        onChange={(e) => {
          setFrontmatter(e.target.value);
        }}
        className="border-gray-400 bg-gray-50 dark:bg-gray-700 dark:border-gray-600 border p-4 my-2 rounded-xl w-full text-xs md:text-sm align-top text-gray-700 dark:text-gray-300 resize-none overflow-hidden"
        placeholder="Thank you for bringing your beloved furry friend to the clinic! Here is some take-home information about how Buddy is doing:"
      />

      <div className="grid grid-cols-2 gap-4 mt-4">
        <div className="space-y-3">
          <h4 className="font-semibold text-gray-700">
            Appointment Info
          </h4>
          {Object.keys(clientSummaryOptions)
            .slice(
              0,
              Object.keys(clientSummaryOptions).indexOf(
                "ownerInstructions",
              ),
            )
            .map((option, index) => (
              <div
                key={index}
                className="flex items-center my-2 text-white"
              >
                <div
                  data-cy={`option-${option}`}
                  id={`${option}-${clientSummaryOptions[option].include}`}
                  className="flex justify-center items-center mr-2 bg-indigo-500 w-6 h-6 rounded-full"
                >
                  <FontAwesomeIcon
                    icon={
                      clientSummaryOptions[option].include
                        ? faCheck
                        : faCircle
                    }
                    className="cursor-pointer"
                    onClick={() => toggleOption(option)}
                  />
                </div>
                <h5>{clientSummaryOptions[option].description}</h5>
              </div>
            ))}
        </div>
        <div className="space-y-3">
          <h4 className="font-semibold text-gray-700">
            Instructions and Recommendations
          </h4>
          {Object.keys(clientSummaryOptions)
            .slice(
              Object.keys(clientSummaryOptions).indexOf(
                "ownerInstructions",
              ),
            )
            .map((option, index) => (
              <div
                key={index}
                className="flex items-center my-2 text-white"
              >
                <div
                  data-cy={`option-${option}`}
                  id={`${option}-${clientSummaryOptions[option].include}`}
                  className="flex justify-center items-center mr-2 bg-indigo-500 w-6 h-6 rounded-full"
                >
                  <FontAwesomeIcon
                    icon={
                      clientSummaryOptions[option].include
                        ? faCheck
                        : faCircle
                    }
                    className="cursor-pointer"
                    onClick={() => {
                      //always make them include the instructions for the owner
                      if (option !== "ownerInstructions") {
                        toggleOption(option);
                      }
                    }}
                  />
                </div>
                <h5>{clientSummaryOptions[option].description}</h5>
              </div>
            ))}
        </div>
      </div>

      <h4 className="mt-4 font-semibold text-gray-700">
        Closing Notes
      </h4>
      <textarea
        data-cy="backmatter-textarea"
        ref={backmatterRef}
        value={backmatter}
        onChange={(e) => {
          setBackmatter(e.target.value);
        }}
        className="border-gray-400 bg-gray-50 dark:bg-gray-700 dark:border-gray-600 border p-4 my-2 rounded-xl w-full text-xs md:text-sm align-top text-gray-700 dark:text-gray-300 resize-none overflow-hidden"
        placeholder="If you have any questions or concerns, please don't hesitate to reach out..."
      />

      <div className="flex justify-between mt-6">
        <BasicButton
          onClick={handleSave}
          dataCy="save-button"
          importance="secondary"
        >
          <FontAwesomeIcon icon={faSave} className="mr-2" />
          {saveButtonText}
        </BasicButton>
        <Tooltip title="Make sure all information is included.">
          <span>
            <BasicButton
              dataCy="generate-summary-button"
              onClick={() => {
                handleGenerateSummary(false);
              }}
            >
              <FontAwesomeIcon icon={faCheck} className="mr-2" />
              Generate Client Summary
            </BasicButton>
          </span>
        </Tooltip>
      </div>
    </>
  );
};

export default CustomizationOptions;
