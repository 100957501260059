import React from "react";

import Patients from "../../patients/Patients";
import { ConfirmModal } from "../modals/PopupModals";

export function PatientSelectForAction({
  selectedPatientUuid,
  setSelectedPatientName,
  setSelectedPatientUuid,
  modalTitle,
  modalText,
  confirmAction,
  additionalOptions,
  loading,
}) {
  return (
    <>
      <Patients
        isForPatientSelectionAction={true}
        setSelectedPatientUuid={setSelectedPatientUuid}
        setSelectedPatientName={setSelectedPatientName}
      />

      <ConfirmModal
        shouldShow={selectedPatientUuid !== ""}
        hideModal={() => setSelectedPatientUuid("")}
        title={modalTitle}
        cancelText={"Whoops, wrong patient"}
        confirmText={"Yes, purrfect pairing!"}
        cancelAction={() => setSelectedPatientUuid("")}
        confirmAction={confirmAction}
        disabled={loading}
      >
        <h5>{modalText}</h5>
        {additionalOptions ? <div>{additionalOptions}</div> : null}
      </ConfirmModal>
    </>
  );
}
