import { gql, useLazyQuery } from "@apollo/client";
import { useFlags } from "launchdarkly-react-client-sdk";
import { createContext, useContext, useEffect } from "react";

import { useAuth } from "./use-auth";
import { useCurrentUser } from "./use-current-user";
import { useWatchNoteUpdates } from "./use-watch-note-updates.js";

const query = gql`
  {
    user {
      shouldUseAutoSoaps
      numAppointmentsUsed
      numAppointmentsAllowed
      hasReachedAutoSoapLimit
      shouldShowAutoSoapCounter
      isAppointmentsBetaUser
      account {
        isSubscribed
      }
    }
  }
`;

const noteUsageContext = createContext();

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useNoteUsage = () => {
  return useContext(noteUsageContext);
};

export function useProvideNoteUsage() {
  const { pollInterval } = useFlags();

  const [getNoteUsage, { data, refetch, loading }] = useLazyQuery(
    query,
    {
      fetchPolicy: "no-cache",
      pollInterval: pollInterval ?? 10000,
    },
  );

  useWatchNoteUpdates({ refetch: () => refetch(), loading });

  const currentUser = useCurrentUser();
  const auth = useAuth();

  useEffect(() => {
    if (auth?.isUserAuthenticated) {
      getNoteUsage();
    }
  }, [auth]);

  useEffect(() => {
    if (currentUser && auth?.isUserAuthenticated) {
      refetch();
    }
  }, [currentUser, auth]);

  return {
    shouldUseAutoSoaps: data?.user?.shouldUseAutoSoaps,
    isAppointmentsBetaUser:
      data?.user?.isAppointmentsBetaUser || false,
    numAppointmentsUsed: data?.user?.numAppointmentsUsed || 0,
    numAppointmentsAllowed: data?.user?.numAppointmentsAllowed || 0,
    hasReachedAutoSoapLimit: data?.user?.hasReachedAutoSoapLimit || 0,
    shouldShowAutoSoapCounter:
      data?.user?.shouldShowAutoSoapCounter || false,
  };
}

export function NoteUsageProvider({ children }) {
  const noteUsage = useProvideNoteUsage();

  return (
    <noteUsageContext.Provider value={noteUsage}>
      {children}
    </noteUsageContext.Provider>
  );
}
