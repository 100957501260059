import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { AccountItem } from "./AccountItem";
import { SubscriptionDetails } from "./SubscriptionDetails";

export default function SubscriptionSettings({
  user,
  setShouldShowCancelSubscriptionModal,
}) {
  function renderSubscriptionDetails() {
    if (user?.account?.isTeamsCustomer) {
      return (
        <h5 className="text-xs md:text-sm">
          You are currently using a paid team account which is managed
          by someone else. Please contact them if you need to make any
          changes to your subscription.
        </h5>
      );
    } else if (user?.account?.isStudent) {
      return (
        <h5 className="text-xs md:text-sm">
          {user?.account?.accountDetails?.nextBillingDate}
        </h5>
      );
    } else {
      return (
        <SubscriptionDetails
          user={user}
          setShouldShowCancelSubscriptionModal={
            setShouldShowCancelSubscriptionModal
          }
        />
      );
    }
  }

  return (
    <AccountItem
      title="Subscription Details"
      icon={<FontAwesomeIcon icon={faSync} size="lg" color="gray" />}
    >
      {renderSubscriptionDetails()}
    </AccountItem>
  );
}
