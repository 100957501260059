import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import React, { useEffect, useState } from "react";

import { usePreferences } from "../../../hooks";

const fieldsWhereMinRowsIsThree = [
  "subjective",
  "objective",
  "assessment",
  "plan",
  "additionalNotes",
];

const fieldsWhereMinRowsIsTen = ["customText"];

export default function PredictiveInput({
  dictionary,
  inputClassName,
  placeholder,
  field,
  positionStyles,
  onMouseUp = null,
  isForSOAPForm = true,
  disabled = false,
  isStreaming = false,
}) {
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([""]);
  const { isAutosuggestEnabled } = usePreferences();

  useEffect(() => {
    if (value == "") {
      setSuggestions([""]);
    }
  }, [value]);

  function handleKeyDown(e) {
    if (isAutosuggestEnabled) {
      // Handle Enter and Tab
      if (e.keyCode === 9 || e.keyCode === 13) {
        if (suggestions.length > 1) {
          e.preventDefault();
          let val = e.target.value + suggestions;
          setValue(val);
          field.onChange(val);
          setSuggestions([""]);
        } else {
          setValue(e.target.value);
          field.onChange(e.target.value);
          setSuggestions([""]);
        }
      }

      // Handle Space or Backspace
      else if (e.keyCode === 32 || e.keyCode === 8) {
        setSuggestions([""]);
      }
    }
  }

  function handleInputChange(e) {
    const wordsArray = e.target.value.split(/\n|\r|\s/);
    //const lastWord = wordsArray.at(-1); CANT USE THIS YET ITS NOT SUPPORTED ON SAFARI, THANKS APPLE
    const lastWord = wordsArray[wordsArray.length - 1];

    const filteredSuggestions = dictionary
      .filter((c) =>
        c.toLowerCase().startsWith(lastWord.toLowerCase()),
      )
      .slice(0, 10);

    if (lastWord.length > 0) {
      for (let i = 0; i < filteredSuggestions.length; i++) {
        let regex = new RegExp("^" + lastWord, "i");
        if (regex.test(filteredSuggestions[i])) {
          setSuggestions(
            filteredSuggestions[i].slice(lastWord.length),
          );
        }
      }
    }

    if (filteredSuggestions.length === 0) {
      setSuggestions([""]);
    }

    field.onChange(e.target.value);
    setValue(e.target.value);
  }

  function getMinRows() {
    if (fieldsWhereMinRowsIsThree.includes(field.name)) {
      return 3;
    } else if (fieldsWhereMinRowsIsTen.includes(field.name)) {
      return 26;
    }
    return 0;
  }

  return (
    <div id="input-predict-container" className="relative w-full">
      <TextareaAutosize
        minRows={getMinRows()}
        value={
          field.value || field.value === "" ? field.value : value
        }
        id="input-predict-input"
        className={inputClassName}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onBlur={() => setSuggestions([""])}
        placeholder={isStreaming ? "" : placeholder}
        onMouseUp={onMouseUp}
        disabled={disabled}
      />
      {isAutosuggestEnabled && isForSOAPForm && (
        <TextareaAutosize
          minRows={getMinRows()}
          id="input-predict-suggestion"
          className={`${positionStyles} top-0 left-0 absolute z-10 w-full bg-transparent dark:text-gray-50 opacity-40 pointer-events-none text-xs md:text-sm resize-none`}
          type="text"
          disabled={true}
          value={
            field.value || field.value === ""
              ? field.value + suggestions
              : value + suggestions
          }
          onMouseUp={onMouseUp}
        />
      )}
    </div>
  );
}
