import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { useNavigate } from "react-router";

import PageTitle from "../layout/PageTitle.js";
import { NewNoteTypePrompt } from "./new/NewNoteTypePrompt.js";
import { newNoteOptionSteps } from "../../assets/data/JoyrideTourSteps";
import getPatientsQuery from "../../graphql/queries/GetPatients.js";
import JoyrideTour from "../common/modals/JoyrideTour";
import NewPatient from "../patients/NewPatient.js";
import { PatientSearchBar } from "../patients/PatientSearchBar.js";

export default function CreateNoteSelect() {
  const [runJoyrideTour, setRunJoyrideTour] = useState(false);
  const [isPatientNote, setIsPatientNote] = useState(false);
  const [shouldShowPatientModal, setShouldShowPatientModal] =
    useState(false);

  const navigate = useNavigate();

  const { data } = useQuery(getPatientsQuery, {
    fetchPolicy: "no-cache",
  });

  function onPatientClick(patientUUID) {
    navigate(`/new_note/patient/${patientUUID}`);
  }

  function onQuicknoteClick() {
    navigate(`/new_note/quicknote`);
  }
  return (
    <div>
      <PageTitle
        title="New Note"
        onClick={() => {
          setRunJoyrideTour(true);
        }}
        showHelpButton
      />
      <JoyrideTour
        runJoyrideTour={runJoyrideTour}
        setRunJoyrideTour={setRunJoyrideTour}
        steps={newNoteOptionSteps}
      />
      <NewNoteTypePrompt
        onQuicknoteClick={onQuicknoteClick}
        setIsPatientNote={setIsPatientNote}
      />

      {isPatientNote ? (
        <div className="mt-5">
          <h3 className="my-3">Which patient is this for?</h3>
          <div className="flex flex-row items-center space-x-6">
            <div className="flex-1">
              <PatientSearchBar
                onPatientClick={onPatientClick}
                resultsMaxHeight="max-h-44"
                itemsAsObjects={data?.patients}
              />
            </div>
            <NewPatient
              shouldShow={shouldShowPatientModal}
              setShouldShow={setShouldShowPatientModal}
              onAddPatient={onPatientClick}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}
