import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import { useDarkMode } from "../../hooks";
import ToggleSwitch from "../common/buttons/ToggleSwitch";

export default function DarkModeToggle() {
  const [darkMode, setDarkMode] = useDarkMode();
  return (
    <div className="flex flex-row items-center space-x-2 mx-2 cursor-pointer">
      <FontAwesomeIcon icon={faSun} size="1x" />
      <ToggleSwitch
        toggleState={darkMode}
        setToggleState={setDarkMode}
      />
      <FontAwesomeIcon icon={faMoon} size="sm" />
    </div>
  );
}
