import { createContext, useContext, useState } from "react";

import { ConfirmModal } from "../components/common/modals/PopupModals";

const PreventRecordingContext = createContext();

export const usePreventRecordingInterruptions = () =>
  useContext(PreventRecordingContext);

export const PreventRecordingProvider = ({ children }) => {
  const [shouldPreventInterruptions, setShouldPreventInterruptions] =
    useState(false);
  const [shouldShowAreYouSureModal, setShouldShowAreYouSureModal] =
    useState(false);
  const [confirmAction, setConfirmAction] = useState(() => () => {});

  const showAreYouSureModal = (action) => {
    setConfirmAction(() => action);
    setShouldShowAreYouSureModal(true);
  };

  const hideAreYouSureModal = () => {
    setShouldShowAreYouSureModal(false);
  };

  const handleActionWithPrevention = (action) => {
    if (shouldPreventInterruptions) {
      showAreYouSureModal(action);
    } else {
      action();
    }
  };

  return (
    <PreventRecordingContext.Provider
      value={{
        shouldPreventInterruptions,
        setShouldPreventInterruptions,
        shouldShowAreYouSureModal,
        showAreYouSureModal,
        hideAreYouSureModal,
        confirmAction,
        handleActionWithPrevention,
      }}
    >
      {children}
    </PreventRecordingContext.Provider>
  );
};

export const AreYouSureModal = ({
  shouldShowAreYouSureModal,
  hideAreYouSureModal,
  confirmAction,
}) => {
  const { setShouldPreventInterruptions } =
    usePreventRecordingInterruptions();

  return (
    <ConfirmModal
      shouldShow={shouldShowAreYouSureModal}
      hideModal={hideAreYouSureModal}
      title="Are You Sure?"
      cancelText="Return To Recording"
      confirmText="Continue"
      cancelAction={hideAreYouSureModal}
      confirmAction={() => {
        confirmAction();
        hideAreYouSureModal();
        setShouldPreventInterruptions(false);
      }}
    >
      <h5>
        This could interrupt your recording and it will be lost. Are
        you sure you want to continue?
      </h5>
    </ConfirmModal>
  );
};
