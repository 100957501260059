import React from "react";

import { ObjectiveBlock } from "./objective/ObjectiveBlock.js";
import { SoapBlock } from "./SoapBlock.js";
import { getObjectiveSectionText } from "./utils.js";

export function SoapSection(props) {
  const { note, isConsise = false } = props;

  let className =
    "grid gap-3 grid-cols-1 md:grid-cols-2 lg:grid-cols-4";

  if (isConsise) {
    className = "grid gap-1 grid-rows-4 my-2";
  }

  return (
    <div>
      <div className={className}>
        <SoapBlock
          type={"Subjective"}
          name="subjective"
          text={note?.subjective}
          isConsise={isConsise}
        />

        {note?.shouldUseGenericObjective ? (
          <SoapBlock
            type={"Objective"}
            name="objective"
            text={getObjectiveSectionText(note)}
            isConsise={isConsise}
          />
        ) : (
          <ObjectiveBlock isConsise={isConsise} note={note} />
        )}

        <SoapBlock
          type={"Assessment"}
          name="assessment"
          text={note?.assessment}
          isConsise={isConsise}
        />

        <SoapBlock
          type={"Plan"}
          name={"plan"}
          text={note?.plan}
          isConsise={isConsise}
        />

        <div className="col-span-1 md:col-span-2 lg:col-span-4">
          <SoapBlock
            type={"Additional Notes"}
            name={"additional"}
            text={note?.additionalNotes}
            isConsise={isConsise}
          />
        </div>
      </div>
    </div>
  );
}
