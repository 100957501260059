import { faAddressBook } from "@fortawesome/free-regular-svg-icons";
import {
  faBirthdayCake,
  faBroadcastTower,
  faHandScissors,
  faMicrochip,
  faPalette,
  faPaw,
  faWeight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import PatientTitle from "./PatientTitle.js";
import Tooltip from "../common/modals/Tooltip";
import { getPatientIntactText } from "../patients/utils.js";

function QuickFactItem({ icon, title, text }) {
  return (
    <div className="flex flex-row items-start">
      <FontAwesomeIcon
        icon={icon}
        size={"1x"}
        className="text-gray-400 dark:text-gray-500"
      />
      <div className="flex flex-col">
        {title ? (
          <h4 className="ml-2 font-bold text-xs mb-1">{title}</h4>
        ) : null}
        <h5 className="ml-2 text-xs break-words">{text}</h5>
      </div>
    </div>
  );
}

function Title({ patient, dataCy }) {
  if (!patient) {
    return null;
  }

  const intactState = getPatientIntactText({
    sex: patient.sex,
    intact: patient.isIntact,
  });

  return (
    <div
      id="patientTitle"
      className="w-max"
      data-cy={dataCy ? dataCy : "patientTitle"}
    >
      <Tooltip
        content={
          <div className="shadow-lg rounded-xl transition-all max-w-xs overflow-hidden">
            <h4 className="bg-indigo-100 dark:bg-indigo-700 px-3 py-2 font-bold rounded-t-xl dark:text-gray-300">
              Quick Facts
            </h4>
            <div className="bg-white dark:bg-gray-700 dark:text-gray-300 px-3 pt-2 pb-3 text-sm rounded-b-xl space-y-2">
              {patient?.breed ? (
                <QuickFactItem
                  icon={faPaw}
                  text={patient?.breed ?? "No breed recorded"}
                />
              ) : null}
              <QuickFactItem
                icon={faWeight}
                text={patient.weight || "Not recorded."}
              />
              <QuickFactItem
                icon={faBirthdayCake}
                text={patient.age || "Born today 🎉"}
              />
              <QuickFactItem
                icon={faHandScissors}
                text={intactState}
              />

              {patient.color ? (
                <QuickFactItem
                  icon={faPalette}
                  text={patient.color}
                />
              ) : null}
              {patient.microchip ? (
                <QuickFactItem
                  icon={faMicrochip}
                  text={patient.microchip}
                />
              ) : null}
              {patient.ownerContactInfo ? (
                <QuickFactItem
                  icon={faAddressBook}
                  title="Owner Contact:"
                  text={patient.ownerContactInfo}
                />
              ) : null}
              {patient.emergencyContactInfo ? (
                <QuickFactItem
                  icon={faBroadcastTower}
                  title="Emergency Contact:"
                  text={patient.emergencyContactInfo}
                />
              ) : null}
            </div>
          </div>
        }
      >
        <div className="cursor-pointer">
          <PatientTitle
            sex={patient?.sex}
            species={patient?.species}
            name={patient?.name}
            patientUUID={patient?.uuid}
            lastNameOfOwner={patient?.lastNameOfOwner}
            size="xxl"
          />
        </div>
      </Tooltip>
    </div>
  );
}

export default Title;
