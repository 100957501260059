import { faMagic } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import React from "react";

import { usePreferences } from "../../../hooks";

export default function AutosuggestTextToggle() {
  const { isAutosuggestEnabled, setIsAutosuggestEnabled } =
    usePreferences();

  return (
    <Tooltip
      arrow
      title="Click to toggle Autosuggest. This will give you word suggestions as you type."
    >
      <div id="autosuggestToggle">
        <button
          onClick={() => {
            setIsAutosuggestEnabled(!isAutosuggestEnabled);
          }}
          className={`${
            isAutosuggestEnabled
              ? "bg-indigo-500 text-white hover:bg-indigo-600"
              : "bg-white dark:bg-gray-800 hover:bg-indigo-50 dark:hover:bg-gray-700 text-indigo-500"
          } font-semibold px-2 justify-center w-10 lg:w-32 h-[40px] flex flex-row items-center transition-all focus:outline-none  disabled:bg-gray-300 disabled:hover:bg-gray-300 disabled:cursor-not-allowed rounded-l-lg`}
        >
          <FontAwesomeIcon icon={faMagic} className="lg:mr-2" />
          <span className="hidden lg:block text-xs">Autosuggest</span>
        </button>
      </div>
    </Tooltip>
  );
}
