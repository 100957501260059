import { gql } from "@apollo/client";

const INITIALIZE_STREAM = gql`
  mutation InitializeStream($taskUuid: String!) {
    initializeStream(taskUuid: $taskUuid) {
      ok
    }
  }
`;

export default INITIALIZE_STREAM;
