import { gql, useMutation } from "@apollo/client";
import { faImage } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { AccountItem, AccountItemBlock } from "./AccountItem";
import {
  ExportSOAPKeyInfoToggle,
  ExportSOAPSignalmentToggle,
  PageSaverToggle,
} from "./ExportToggles";
import HeaderImageUpload from "./HeaderImageUpload";
import HeaderTextBox from "./HeaderTextBox";
import { useUploadProgress } from "../../hooks";
import { submitAttachments } from "../attachments/attachment-utils";
import { alert } from "../common/Alert";
import EditDeleteButtons from "../common/buttons/EditDeleteButtons";
import { checkisAllowedAndDownload } from "../common/clinical/NotePDF";

const UPLOAD_HEADER_IMAGE = gql`
  mutation uploadHeaderImage(
    $logoStorageObjectName: String!
    $logoContentType: String!
  ) {
    uploadHeaderImage(
      logoStorageObjectName: $logoStorageObjectName
      logoContentType: $logoContentType
    ) {
      ok
    }
  }
`;

const EDIT_PDF_SETTINGS = gql`
  mutation editPdfSettings($headerInfoPdf: String) {
    editPdfSettings(headerInfoPdf: $headerInfoPdf) {
      ok
    }
  }
`;

function InlineButton({ onClick, text }) {
  return (
    <div
      onClick={onClick}
      className={
        "w-full font-medium text-white bg-indigo-500 hover:bg-indigo-600 rounded-full text-sm text-center cursor-pointer py-1 px-3 transition-all focus:outline-none"
      }
    >
      {text}
    </div>
  );
}

export default function PDFCustomizationSettings({
  data,
  refetch,
  loading,
}) {
  const [isEditing, setIsEditing] = useState(false);

  const { control, getValues, setValue } = useForm();

  const [uploadHeaderImage] = useMutation(UPLOAD_HEADER_IMAGE);
  const [editPdfSettings] = useMutation(EDIT_PDF_SETTINGS);

  const { setPercentageUploaded, setCurrentFileName } =
    useUploadProgress();

  const onSubmit = async () => {
    const values = getValues();

    if (values?.headerInfoPdf) {
      editPdfSettings({
        variables: {
          headerInfoPdf: values.headerInfoPdf,
        },
      })
        .then(() => {
          alert("success", "Updated PDF header text.");
          refetch();
        })
        .catch(() => {
          alert(
            "error",
            "An error has occurred. Please try again later or contact support.",
          );
        });
    }

    if (values?.attachments) {
      const uploadedFiles = await submitAttachments({
        getValues,
        setPercentageUploaded,
        setCurrentFileName,
      });

      if (uploadedFiles) {
        uploadHeaderImage({
          variables: {
            logoStorageObjectName: uploadedFiles[0].storageObjectName,
            logoContentType: uploadedFiles[0].contentType,
          },
        })
          .then(() => {
            alert("success", "Upload successful");
            refetch();
          })
          .catch(() => {
            alert(
              "error",
              "An error has occurred. Please try again later or contact support.",
            );
          });
      } else {
        alert(
          "error",
          "An error has occurred. Please try again later or contact support.",
        );
      }
    }
    setValue("attachments", null);
    setIsEditing(false);
  };

  function renderCancelSaveButtons() {
    return (
      <EditDeleteButtons
        onClickDestructiveButton={() => {
          setIsEditing(false);
        }}
        destructiveButtonText="Cancel"
        onClickActionButton={() => {
          onSubmit();
        }}
        actionButtonText="Save and Upload"
      />
    );
  }

  function renderEditPreviewButtons() {
    return (
      <div className="flex flex-col items-center space-y-2">
        <InlineButton
          text="Edit"
          onClick={() => setIsEditing(true)}
        />
        <InlineButton
          text="Download Preview"
          onClick={() =>
            checkisAllowedAndDownload(
              {
                isTagged: true,
                quickNote: "Your logo looks great here!",
                customObjectiveFields: null,
                shouldUseGenericObjective: true,
                subjective: "Subjective preview",
                objective: "Objective preview",
                assessment: "Assessment preview",
                plan: "Plan preview",
                createdAt: moment().format(),
              },
              {
                name: "Otto (example patient)",
                species: "dog",
                breed: "Golden Retriever",
                sex: "m",
                weight: "70 lbs",
                age: "~2 years old",
                ownerContactInfo: "Scribenote Inc.",
                emergencyContactInfo: "Scribenote's fans.",
              },
              data?.user,
            )
          }
        />
      </div>
    );
  }

  return (
    <div id="headerImageUpload">
      <AccountItem
        title="PDF Customization"
        icon={
          <FontAwesomeIcon icon={faImage} size="lg" color="gray" />
        }
      >
        <div className="flex flex-col flex-1 w-full space-y-4 divide-y dark:divide-gray-700 border-b md:border-0 dark:border-gray-700 pb-4">
          <AccountItemBlock
            title="Additional Info:"
            desc="For including
            information such as clinic name, phone number, or email
            address at the top of your PDF exports."
          >
            <HeaderTextBox
              defaultValue={data?.user?.account?.headerInfoPdf}
              control={control}
              setIsEditing={setIsEditing}
            />
          </AccountItemBlock>

          <AccountItemBlock
            title="Custom Image:"
            desc="Upload a custom logo or
                image which will appear at the top of your PDF
                exports."
          >
            <HeaderImageUpload
              data={data}
              refetch={refetch}
              loading={loading}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              control={control}
            />
          </AccountItemBlock>

          <AccountItemBlock
            title="Include Key Info:"
            desc="Toggling this will
                include key information in SOAP note PDF exports."
          >
            <ExportSOAPKeyInfoToggle />
          </AccountItemBlock>

          <AccountItemBlock
            title="Include Patient Info:"
            desc="Toggling this
                will include patient signalment and contact info in
                your SOAP note PDF exports."
          >
            <ExportSOAPSignalmentToggle />
          </AccountItemBlock>

          <AccountItemBlock
            title="Page Saver:"
            desc="Toggling this setting OFF
                will put each note on a separate page when exporting entire notebooks."
          >
            <PageSaverToggle />
          </AccountItemBlock>
        </div>
        <div className="flex self-center md:justify-self-end md:self-end">
          {isEditing
            ? renderCancelSaveButtons()
            : renderEditPreviewButtons()}
        </div>
      </AccountItem>
    </div>
  );
}
