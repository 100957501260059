import {
  faFilePdf,
  faFileWord,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getFileExtensionType } from "./attachment-utils.js";

function MediaPlaceholder({
  children,
  onClick,
  icon,
  isAttachmentSelected,
}) {
  return (
    <div
      onClick={onClick}
      className={`h-[80px] w-[80px] bg-gradient-to-br from-indigo-300 to-indigo-400 dark:from-indigo-600 dark:to-indigo-900 flex items-center justify-center rounded-md cursor-pointer shadow-sm ${
        isAttachmentSelected
          ? "border-4 border-indigo-500"
          : "border border-gray-200 dark:border-gray-800"
      }`}
    >
      <h4 className="text-indigo-600 dark:text-indigo-300 font-extrabold flex flex-col items-center justify-center">
        <FontAwesomeIcon icon={icon} size="2x" />

        {children}
      </h4>
    </div>
  );
}

export function Thumbnail({
  attachment,
  handleAttachmentClick,
  isAttachmentSelected,
}) {
  switch (getFileExtensionType(attachment.fileName)) {
    case "pdf":
      return (
        <MediaPlaceholder
          onClick={(e) => {
            e.preventDefault();
            handleAttachmentClick(attachment);
          }}
          icon={faFilePdf}
          isAttachmentSelected={isAttachmentSelected}
        >
          PDF
        </MediaPlaceholder>
      );
    case "doc":
    case "docx":
      return (
        <MediaPlaceholder
          onClick={(e) => {
            e.preventDefault();
            handleAttachmentClick(attachment);
          }}
          icon={faFileWord}
          isAttachmentSelected={isAttachmentSelected}
        >
          DOC
        </MediaPlaceholder>
      );
    case "mp4":
    case "mov":
    case "webm":
    case "mpeg":
    case "ogv":
    case "ts":
    case "avi":
      return (
        <video
          type={`video/${attachment.fileType}`}
          onClick={(e) => {
            e.preventDefault();
            handleAttachmentClick(attachment);
          }}
          src={attachment.fileLink}
          className={`object-cover h-[80px] w-[80px] rounded-md bg-white shadow-sm cursor-pointer ${
            isAttachmentSelected
              ? "border-4 border-indigo-500"
              : "border border-gray-200 dark:border-gray-800"
          }`}
        />
      );
    default:
      return (
        <img
          src={attachment.fileLink}
          className={`object-cover h-[80px] w-[80px] rounded-md bg-white shadow-sm cursor-pointer ${
            isAttachmentSelected
              ? "border-4 border-indigo-500"
              : "border border-gray-200 dark:border-gray-800"
          }`}
          onClick={(e) => {
            e.preventDefault();
            handleAttachmentClick(attachment);
          }}
        />
      );
  }
}
