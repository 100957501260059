// import { Controller } from "react-hook-form";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useMemo } from "react";

import { SOAPInput } from "../SOAPInput";

export function EditClientSummaryResult({
  control,
  generatedSummaryRef,
  noteUuid,
  isAutoSoap,
}) {
  const {
    aiAssistantEnabled,
    languageAiAssistantEnabled,
    subheadingsAiAssistantEnabled,
  } = useMemo(() => useFlags(), []);

  return (
    <SOAPInput
      control={control}
      fieldName="clientSummary"
      label="Client Summary"
      inputRef={generatedSummaryRef}
      title={"Client Summary"}
      headerStyle="bg-gray-400 dark:bg-gray-600"
      boxStyle="border-gray-400 bg-gray-50 dark:bg-gray-700 dark:border-gray-600 border my-1 rounded-xl w-full text-xs md:text-sm align-top text-gray-700 dark:text-gray-300 divide-gray-400 dark:divide-gray-600"
      noteUuid={noteUuid}
      aiAssistantEnabled={aiAssistantEnabled}
      languageAiAssistantEnabled={languageAiAssistantEnabled}
      subheadingsAiAssistantEnabled={subheadingsAiAssistantEnabled}
      isAutoSoap={isAutoSoap}
    />
  );
}
