import React from "react";

import HelpButton from "../common/buttons/HelpButton";

export default function PageTitle({
  title,
  onClick,
  id,
  showHelpButton = false,
  renderAdditionalHeaderComponent,
}) {
  return (
    <div className="flex flex-row items-center mt-6 mb-2 relative">
      <h1 className="text-left flex-1 text-gray-700 dark:text-gray-300">
        {title}
      </h1>
      {renderAdditionalHeaderComponent &&
        renderAdditionalHeaderComponent()}
      {showHelpButton && <HelpButton id={id} onClick={onClick} />}
    </div>
  );
}
