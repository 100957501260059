import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";

import { TemplateItem } from "./TemplateItem";
import { TemplateList } from "./TemplateList";
import { templateSteps } from "../../assets/data/JoyrideTourSteps";
import { GET_USERS_TEMPLATES } from "../../graphql/queries/GetUsersTemplates.js";
import { Banner } from "../common/Banner";
import HeadingWrapper from "../common/HeadingWrapper";
import JoyrideTour from "../common/modals/JoyrideTour";
import PageTitle from "../layout/PageTitle";

export default function Templates() {
  const { data, refetch } = useQuery(GET_USERS_TEMPLATES);
  const [runJoyrideTour, setRunJoyrideTour] = useState(false);

  const location = useLocation();

  const checkIfAnyPresetTemplatesExist = (templates) => {
    let presetTemplatesCount = 0;

    templates?.forEach((template) => {
      if (template.isPreset === true) {
        presetTemplatesCount += 1;
      }
    });

    return presetTemplatesCount;
  };
  useEffect(() => {
    if (location?.state?.shouldRefetch) {
      refetch();
    }
  }, []);

  return (
    <div>
      <JoyrideTour
        steps={templateSteps}
        runJoyrideTour={runJoyrideTour}
        setRunJoyrideTour={setRunJoyrideTour}
        // variables={{
        //   isTemplatesOnboardingComplete: true,
        // }}
      />
      <PageTitle
        className="mb-6"
        title="Templates"
        onClick={() => {
          setRunJoyrideTour(true);
        }}
        showHelpButton
      />
      <Banner bgStyle="bg-yellow-400">
        Please note that Templates currently only apply to typed notes
        and not to Auto-Notes. We are working on Templates
        functionality for Auto-Notes.
      </Banner>
      {checkIfAnyPresetTemplatesExist(data?.user?.templates) && (
        <HeadingWrapper heading={<h3>Preset Templates</h3>} />
      )}
      <div
        id="presetTemplates"
        className="flex-1 flex flex-row flex-wrap my-4"
      >
        <TemplateList
          data={data}
          refetch={refetch}
          isForTemplateSelection={false}
          isPreset={true}
        />
      </div>
      <HeadingWrapper heading={<h3>Your Templates</h3>} />

      <h5>
        Select a template to edit it, or create a new template using
        &quot;New Template&quot;
      </h5>

      <div
        id="customTemplates"
        className="flex-1 flex flex-row flex-wrap my-4"
      >
        <TemplateItem
          name={"+ New template"}
          description={"Create your own custom template"}
        />

        <TemplateList
          data={data}
          refetch={refetch}
          isForTemplateSelection={false}
          isPreset={false}
        />
      </div>
    </div>
  );
}
