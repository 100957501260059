import React from "react";

import { AgeInput } from "../../common/date/AgeInput";
import { DateInput } from "../../common/date/DateInput";
import HeadingWrapper from "../../common/HeadingWrapper";
import { RequiredAsterisk } from "../../common/RequiredAsterisk";

export default function AgeSelection({ isAgeInputTypeDOB, control }) {
  if (isAgeInputTypeDOB) {
    return (
      <div>
        <HeadingWrapper
          heading={
            <h4>
              Date of Birth <RequiredAsterisk />
            </h4>
          }
        />
        <DateInput control={control} />
      </div>
    );
  } else {
    return (
      <div>
        <HeadingWrapper
          heading={
            <h4>
              Age
              <RequiredAsterisk />
            </h4>
          }
        />
        <AgeInput control={control} />
      </div>
    );
  }
}
