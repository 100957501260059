import { useLazyQuery, useMutation } from "@apollo/client";
import { faFileArchive } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useParams } from "react-router-dom";

import { createBlobList, getUuidsOfExportedNotes } from "./utils";
import MARK_NOTES_EXPORTED from "../../graphql/mutations/MarkNotesExported.js";
import GET_PATIENTS_APPROVED_NOTES from "../../graphql/queries/GetPatientsApprovedNotes.js";
import { SpinningYarn } from "../common/loading/Loading.js";
import { MessageModal } from "../common/modals/PopupModals.js";

function ExportMulti(props) {
  const { data, isForPatientNotebook, refetch = null } = props;
  const { patientUUID } = useParams();
  const [exportClickedToggle, setExportClickedToggle] =
    useState(false);
  const [shouldShowModal, setShouldShowModal] = useState(false);
  const [shouldButtonBeDisabled, setShouldButtonBeDisabled] =
    useState(false);
  const [getNotes, { loading, data: allData }] = useLazyQuery(
    GET_PATIENTS_APPROVED_NOTES,
  );
  const [modifyNotes] = useMutation(MARK_NOTES_EXPORTED);

  useEffect(() => {
    const timed = setTimeout(() => {
      async function exportNotes(allData) {
        await createBlobList(allData, true);
        const allUuidsBeingExported = getUuidsOfExportedNotes(
          allData,
          true,
        );

        modifyNotes({
          variables: {
            noteUuids: allUuidsBeingExported,
            action: "mark_exported",
          },
        });
      }
      if (loading && !allData) {
        setExportClickedToggle(true);
        setShouldButtonBeDisabled(true);
      }

      if (data === null && allData) {
        exportNotes(allData);
        setExportClickedToggle(false);
        setShouldButtonBeDisabled(false);
      }
    }, 500);
    return () => clearTimeout(timed);
  }, [exportClickedToggle, loading]);

  function onClickExport(patientUUID, isForPatientNotebook) {
    if (isForPatientNotebook) {
      getNotes({
        variables: {
          uuid: patientUUID,
          isApproved: true,
          last: false,
        },
      });
      setTimeout(() => {
        setShouldShowModal(false);
      }, 4000);
    } else {
      createBlobList(data, isForPatientNotebook);
      const allUuidsBeingExported = getUuidsOfExportedNotes(
        data,
        false,
      );

      modifyNotes({
        variables: {
          noteUuids: allUuidsBeingExported,
          action: "mark_exported",
        },
      });
      if (refetch !== null) {
        refetch({ isApproved: true });
      }
      setTimeout(() => {
        setShouldShowModal(false);
      }, 4000);
    }
  }

  return (
    <div>
      <button
        disabled={shouldButtonBeDisabled}
        onClick={async () => {
          setShouldShowModal(true);
          // this setTimeout is here to make sure that the modal pops up before the export note process begins.
          setTimeout(() => {
            onClickExport(patientUUID, isForPatientNotebook);
          }, 200);
        }}
        className="rounded-full shadow-md font-semibold px-3 py-2 flex flex-row items-center bg-indigo-500 text-white hover:bg-indigo-600 transition-all focus:outline-none border border-indigo-500 disabled:bg-gray-300 disabled:hover:bg-gray-300 disabled:border-gray-300 disabled:cursor-not-allowed"
      >
        <FontAwesomeIcon icon={faFileArchive} className="md:mr-2" />
        <span className="hidden md:block">Export All</span>
      </button>
      {shouldShowModal
        ? createPortal(
            <MessageModal shouldShow={shouldShowModal} title="">
              <div className="flex flex-col self-center">
                <h4 className="text-lg text-center my-2">
                  Hold tight while we wrangle up all your notes...
                </h4>
                <SpinningYarn />
              </div>
            </MessageModal>,
            document.getElementById("root-node"),
          )
        : null}
    </div>
  );
}

export default ExportMulti;
