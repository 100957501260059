import React from "react";

import { BAR, NORMAL_ON_EXAM } from "../../../../constants";
import Tooltip from "../../modals/Tooltip";

export function DefaultObjectiveText({ header, text, name, index }) {
  const Header = () => {
    let classNameHeader = "font-medium mr-3 text-xs";

    if (header) {
      return <div className={classNameHeader}>{header}:</div>;
    } else {
      return <div className={classNameHeader}>No name:</div>;
    }
  };

  return (
    <div
      key={index}
      className={`${
        text !== NORMAL_ON_EXAM && text !== BAR
          ? "bg-pink-300 dark:bg-pink-500 bg-opacity-50 dark:bg-opacity-50 rounded-sm"
          : ""
      } flex flex-row items-center relative w-full my-1`}
    >
      <Header />
      <Tooltip
        tooltipContainerClassName="flex-1 overflow-hidden whitespace-pre-wrap"
        delay={1000}
        content={
          <div className="shadow-md bg-white dark:bg-gray-700 border border-gray-200 dark:border-gray-500 p-1 rounded-lg transform -translate-y-14">
            {text}
          </div>
        }
      >
        <div className={"overflow-hidden text-ellipsis text-xs"}>
          {text ? text : name !== "overall" ? NORMAL_ON_EXAM : BAR}
        </div>
      </Tooltip>
    </div>
  );
}
