import "./styles.css";
import addDays from "date-fns/addDays";
import addMonths from "date-fns/addMonths";
import endOfMonth from "date-fns/endOfMonth";
import endOfWeek from "date-fns/endOfWeek";
import startOfMonth from "date-fns/startOfMonth";
import startOfWeek from "date-fns/startOfWeek";
import subDays from "date-fns/subDays";
import { DateRangePicker } from "rsuite";

const predefinedRanges = [
  {
    label: "Today",
    value: [new Date(), new Date()],
    placement: "left",
  },
  {
    label: "Yesterday",
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    placement: "left",
  },
  {
    label: "This week",
    value: [startOfWeek(new Date()), endOfWeek(new Date())],
    placement: "left",
  },
  {
    label: "Last 7 days",
    value: [subDays(new Date(), 6), new Date()],
    placement: "left",
  },
  {
    label: "Last 30 days",
    value: [subDays(new Date(), 29), new Date()],
    placement: "left",
  },
  {
    label: "This month",
    value: [startOfMonth(new Date()), new Date()],
    placement: "left",
  },
  {
    label: "Last month",
    value: [
      startOfMonth(addMonths(new Date(), -1)),
      endOfMonth(addMonths(new Date(), -1)),
    ],
    placement: "left",
  },
  {
    label: "This year",
    value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
    placement: "left",
  },
  {
    label: "Last year",
    value: [
      new Date(new Date().getFullYear() - 1, 0, 1),
      new Date(new Date().getFullYear(), 0, 0),
    ],
    placement: "left",
  },
  {
    label: "All time",
    value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
    placement: "left",
  },
];

const DateRangeSelection = ({
  selectedRange,
  handleApplyDateRange,
}) => {
  const handleDateRangeConfirm = (range) => {
    if (range && range[0] && range[1]) {
      const startDate = range[0];
      const endDate = range[1];
      handleApplyDateRange(startDate, endDate);
    }
  };

  const handleShortcut = (event) => {
    handleApplyDateRange(event.value[0], event.value[1]);
  };

  const handleClean = () => {
    handleApplyDateRange(null, null);
  };

  return (
    <div className="">
      <DateRangePicker
        format="MMM dd, yyyy"
        character=" to "
        onOk={handleDateRangeConfirm}
        onShortcutClick={handleShortcut}
        onClean={handleClean}
        value={[
          selectedRange.startDate
            ? new Date(selectedRange.startDate)
            : null,
          selectedRange.endDate
            ? new Date(selectedRange.endDate)
            : null,
        ]}
        ranges={predefinedRanges}
        placeholder="Filter by Date Range"
        editable={false}
        showOneCalendar
      />
    </div>
  );
};

export default DateRangeSelection;
