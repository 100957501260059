import {
  faEye,
  faEyeSlash,
} from "@fortawesome/free-regular-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { config, useTransition, animated } from "react-spring";

import { Button } from "./form/Button.js";
import { Input } from "./form/Input.js";
import OttoScribblesPeek from "../../assets/imgs/otto-scribbles-peek.png";
import { useAuth } from "../../hooks";
import Twemoji from "../../Twemoji.js";
import { openInNewTab } from "../../utils.js";
import { alert } from "../common/Alert.js";
import FullScreenLoading from "../common/loading/FullScreenLoading.js";
import mixpanel from "mixpanel-browser";

export default function Register() {
  const auth = useAuth();
  const navigate = useNavigate();

  const [isPasswordValidated, setIsPasswordValidated] =
    useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [shouldShowOttoScribbles, setShouldShowOttoScribbles] =
    useState(true);

  useEffect(() => {
    if (auth?.isUserAuthenticated) {
      navigate("/dashboard");
    }
  }, [auth?.isUserAuthenticated]);

  function togglePassword() {
    setShowPassword(!showPassword);
  }

  const { loading } = auth;

  const popUpAnim = useTransition(shouldShowOttoScribbles, {
    from: { y: 0 },
    enter: { y: 0 },
    leave: { y: 40 },
    delay: 200,
    config: config.gentle,
  });

  const params = new URLSearchParams(window.location.search);
  const vlReferredCode = params?.get("referralCode");

  const handleRegister = (event) => {
    event.preventDefault();
    event.persist(); // <- Prevent pooling from clearing form during setstate

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    const { email, password, confirmPassword, firstName, lastName } =
      event.target;

    if (password.value !== confirmPassword.value) {
      event.preventDefault();
      event.stopPropagation();

      setIsPasswordValidated(false);
      return;
    } else {
      setIsPasswordValidated(true);
    }

    // validate if MVP email includes @mvetpartners.com
    if (vlReferredCode?.includes("MVP")) {
      const emailDomain = email.value.split("@")[1];
      if (emailDomain !== "mvetpartners.com") {
        alert(
          "warning",
          "Please use your @mvetpartners.com email address.",
        );
        return;
      }
    }

    //general email validation
    const emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(email.value)) {
      alert("warning", "Please enter a valid email address.");
      return;
    }

    auth.register(
      email.value,
      password.value,
      firstName.value,
      lastName.value,
      true,
      vlReferredCode,
    );

    mixpanel.track("Signed Up", {
      email: email.value,
      referralCode: vlReferredCode,
    });
  };

  return (
    <>
      <div className="flex-1 flex flex-col md:flex-row md:items-start md:space-x-10 space-y-20 md:space-y-0 md:pt-20 lg:px-10 max-w-7xl md:mx-auto">
        <FullScreenLoading loadingTrigger={loading} />
        <div className="flex-1 flex flex-col items-center md:items-start space-y-8">
          <h2 className="text-center md:text-left text-3xl md:text-5xl pb-1 text-transparent dark:text-transparent bg-clip-text bg-gradient-to-br from-indigo-400 to-indigo-600 dark:from-indigo-500 dark:to-indigo-300">
            Sign up for Scribenote
          </h2>
          <Link to="/auth/login">
            <h4 className="font-medium text-indigo-500 hover:text-indigo-400 underline">
              Already have an account? Sign In
            </h4>
          </Link>
          <div className="text-2xl mx-6 md:mx-0 md:pt-10 flex flex-col space-y-4">
            <h4>
              You&apos;re 20 seconds away from totally automatic
              medical records <Twemoji emoji="🎉" />
            </h4>
            <ul className="list-disc pl-4 text-sm text-gray-600 dark:text-gray-300 space-y-2">
              <li>
                Get 50 free{" "}
                <a
                  className="cursor-pointer text-indigo-500 underline"
                  href="https://docs.scribenote.com/en/collections/9603654-automated-notes-auto-notes"
                  target="_blank"
                  rel="noreferrer"
                >
                  Automatic Medical Records
                </a>{" "}
                just by signing up.
              </li>
              <li>
                We'll make it easy for you - no demo, onboarding call,
                or credit card is required to get started with
                Scribenote!
              </li>
              <li>If you do need help though, please contact us!</li>
            </ul>
          </div>
        </div>
        <div className="flex flex-1 flex-col items-center md:items-end justify-center space-y-4">
          <form
            className="relative flex flex-col items-center max-w-[500px] md:w-[500px] p-10 rounded-xl shadow-md shadow-indigo-100 dark:shadow-gray-900 bg-white dark:bg-gray-800"
            onSubmit={(event) => handleRegister(event)}
            data-cy="registerForm"
          >
            {popUpAnim(
              (styles, item) =>
                item && (
                  <animated.div
                    style={styles}
                    className="absolute -z-10 -top-10"
                  >
                    <img src={OttoScribblesPeek} width="200px" />
                  </animated.div>
                ),
            )}
            <div className="space-y-5 pb-6 w-full">
              <div className="space-y-4 pb-5">
                <div className="flex flex-row items-center">
                  <div className="flex flex-row items-center space-x-4">
                    <Input
                      type="firstName"
                      placeholder="First Name"
                      dataCy={"firstName"}
                    />
                    <Input
                      type="lastName"
                      placeholder="Last Name"
                      dataCy={"lastName"}
                    />
                  </div>
                </div>

                <Input
                  name="email"
                  type="email"
                  placeholder="Email Address"
                />
              </div>

              <div className="relative">
                <Input
                  name="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  onFocus={() => setShouldShowOttoScribbles(false)}
                  onBlur={() => setShouldShowOttoScribbles(true)}
                />
                <div
                  onClick={() => togglePassword()}
                  className="group absolute -top-5 right-2 z-20 flex items-center space-x-1 cursor-pointer text-gray-500 dark:text-gray-500"
                >
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    size="sm"
                    className="group-hover:text-indigo-500 group-hover:dark:text-indigo-200"
                  />
                  <h5 className="text-xs group-hover:text-indigo-500 group-hover:dark:text-indigo-200">
                    {showPassword ? "Hide" : "Show"}
                  </h5>
                </div>
              </div>
              <Input
                name="confirmPassword"
                type={showPassword ? "text" : "password"}
                placeholder="Confirm password"
                onFocus={() => setShouldShowOttoScribbles(false)}
                onBlur={() => setShouldShowOttoScribbles(true)}
              />
              {isPasswordValidated ? null : (
                <p className="text-red-500 dark:text-red-500 text-center text-xs">
                  These passwords do not match.
                </p>
              )}
            </div>

            <Button type="submit" dataCy={"register"}>
              Register{" "}
              <FontAwesomeIcon className="ml-2" icon={faArrowRight} />
            </Button>
            <div
              className={
                "text-sm text-center pt-6 dark:text-gray-400 text-gray-500"
              }
            >
              By clicking <span className={"italic"}>Register</span>,
              you are agreeing to our{" "}
              <span
                className="text-indigo-500 hover:text-indigo-400 cursor-pointer"
                onClick={() => {
                  openInNewTab(
                    "https://www.scribenote.com/terms-of-service",
                  );
                }}
              >
                Terms of Service
              </span>{" "}
              and{" "}
              <span
                className="text-indigo-500 hover:text-indigo-400 cursor-pointer"
                onClick={() => {
                  openInNewTab(
                    "https://www.scribenote.com/privacy-policy",
                  );
                }}
              >
                Privacy Policy
              </span>
              .
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
