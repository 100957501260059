import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router";

import PaymentForm from "./PaymentForm";
import PricingCard from "./PricingCard.js";
import {
  STRIPE_PUBLISHABLE_KEY,
  UNLIMITED_PRICE_ID_YEARLY,
  UNLIMITED_PRICING_MONTHLY,
  UNLIMITED_PRICING_YEARLY,
} from "../../constants";
import { CardContent } from "./PricingCardContents/CardContent";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

export default function Checkout() {
  // promoCode states
  const [promoCode, setPromoCode] = useState(null);
  const [isPromoCodeValid, setIsPromoCodeValid] = useState(null);
  const [promoCodeErrorMessage, setPromoCodeErrorMessage] = useState(
    "Sorry, this coupon is invalid.",
  );

  const { productName, priceId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Don't go to the last history.goBack() on browser back button
    // or back nav button. If the user switched yearly/monthly pricing,
    // back will result in them going back and forth between those prices.

    // This is confusing, so instead, bring them back to the
    // Screen prior to this (/subscriptions)
    return window.addEventListener("popstate", () => {
      navigate(`/subscriptions`);
    });
  }, [location]);

  const shouldShowYearlyPricing =
    priceId === UNLIMITED_PRICE_ID_YEARLY;

  return (
    <div>
      <div className="mt-10 mb-8">
        <h3 className="text-center my-6">
          Please enter your billing information below:
        </h3>
        <div className="flex flex-col items-center justify-center ">
          <div className="flex flex-col md:flex-row space-y-10 md:space-x-8 md:space-y-0">
            <div className="flex flex-col">
              <PricingCardCheckout
                promoCode={promoCode}
                shouldShowYearlyPricing={shouldShowYearlyPricing}
              />
            </div>

            <div>
              <Elements stripe={stripePromise}>
                <PaymentForm
                  priceId={priceId}
                  planType={productName}
                  promoCode={promoCode}
                  isPromoCodeValid={isPromoCodeValid}
                  setPromoCode={setPromoCode}
                  setIsPromoCodeValid={setIsPromoCodeValid}
                  promoCodeErrorMessage={promoCodeErrorMessage}
                  setPromoCodeErrorMessage={setPromoCodeErrorMessage}
                />
              </Elements>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function PricingCardCheckout({ shouldShowYearlyPricing, promoCode }) {
  return (
    <PricingCard
      price={
        shouldShowYearlyPricing
          ? UNLIMITED_PRICING_YEARLY
          : UNLIMITED_PRICING_MONTHLY
      }
      planName={shouldShowYearlyPricing ? "Yearly" : "Monthly"}
      isForCheckout
      shouldShowYearlyPricing={shouldShowYearlyPricing}
      promoCode={promoCode}
    >
      <CardContent
        shouldShowYearlyPricing={shouldShowYearlyPricing}
      />
    </PricingCard>
  );
}
