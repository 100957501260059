import {
  faNotesMedical,
  faStopwatch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";

import DashboardItem from "../../dashboard/DashboardItem";

export function NewNoteTypePrompt(props) {
  const { onQuicknoteClick, setIsPatientNote } = props;

  return (
    <div
      className="grid md:grid-cols-2 md:gap-6"
      data-cy="newNoteOptions"
    >
      <div
        id="newNotePatientOption"
        onClick={() => {
          setIsPatientNote(true);
        }}
        className="flex-1 h-1/3"
      >
        <h5 className="my-3 text-center text-indigo-400">
          Already have a patient in mind?
        </h5>
        <DashboardItem title={"Patient Note"}>
          <div className="self-center pb-2">
            <FontAwesomeIcon icon={faNotesMedical} size="3x" />
          </div>
        </DashboardItem>
      </div>
      <div
        id="newNoteQuicknoteOption"
        onClick={() => {
          onQuicknoteClick();
        }}
        className="flex-1 h-1/3"
      >
        <h5 className="my-3 text-center text-indigo-400">
          No patient yet? No problem!
        </h5>
        <DashboardItem title={"Quicknote"}>
          <div className="self-center pb-2">
            <FontAwesomeIcon icon={faStopwatch} size="3x" />
          </div>
        </DashboardItem>
      </div>
    </div>
  );
}

NewNoteTypePrompt.propTypes = {
  onQuicknoteClick: PropTypes.func,
  setIsPatientNote: PropTypes.func,
};
