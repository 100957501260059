import { useEffect, useState } from "react";

import { BAR, NORMAL_ON_EXAM, OBJECTIVE_FIELDS } from "../constants";

const DEFAULT_VALUES_TYPE = {
  draft: "draft",
  editTemplate: "editTemplate",
  applyTemplate: "applyTemplate",
};

export function getSavedField(fieldName, fieldValue) {
  // ENSURE that default field values are only populated
  // when they diverge from the placeholder text for
  // an objective field.
  if (
    (fieldName === "overall" && fieldValue === BAR) ||
    (fieldName !== "overall" && fieldValue === NORMAL_ON_EXAM)
  ) {
    return "";
  }
  return fieldValue;
}

function getDefaultObjectiveFieldArray(note) {
  let result = [];
  let previouslyExcludedObjectiveFields = [];
  OBJECTIVE_FIELDS.forEach(({ name, label, type }) => {
    if (!note) {
      // This is a new note or a new template
      result.push({
        name,
        value: "",
        label,
        type,
      });
    } else {
      // This is a note draft or saved template

      if (note[name] === null) {
        // This objective field has been removed and needs to be
        // listed as an excludedObjectiveField to ensure it is not resaved.
        previouslyExcludedObjectiveFields.push(name);
        return;
      }

      result.push({
        name,
        value: getSavedField(name, note[name]),
        label,
        type,
      });
    }
  });

  return result;
}

function getCustomObjectiveFieldArray(note) {
  const result = [];
  if (note?.customObjectiveFields?.length > 0) {
    note?.customObjectiveFields.forEach((field, index) => {
      result.push(field);

      switch (field.symmetryType) {
        case "None": {
          result[index] = {
            ...result[index],
            value: getSavedField(field.name, field.value),
          };
          break;
        }
        default: {
          result[index] = {
            ...result[index],
            symmetricalValueRight: getSavedField(
              field.name,
              field.symmetricalValueRight,
            ),
            symmetricalValueLeft: getSavedField(
              field.name,
              field.symmetricalValueLeft,
            ),
          };

          break;
        }
      }
    });
  }

  return result;
}

/**
 * Function works for both customObjective and customForm fields
 *
 * Useful for applying a template to a note.
 * If the UUIDs aren't deleted from the fields,
 * when sent to the DB, they will be seen as "Existing" fields since they have a UUID.
 * This results in the template custom fields being saved over by note edits.
 */
function dropUuidsFromCustomFields(customFieldArray) {
  return customFieldArray.map((field) => {
    return { ...field, id: null, uuid: null };
  });
}

function formatDefaultFields(
  note,
  defaultObjectiveFieldArray = [],
  customObjectiveFieldArray = [],
  isForTemplateEdit = false,
  templateName = "",
  templateDescription = "",
  colorScheme = "rose",
) {
  let weight = note?.weight || "";

  if (weight === NORMAL_ON_EXAM) {
    weight = "";
  }

  const result = {
    defaultObjectiveFieldArray,
    customObjectiveFieldArray,
    subjective: note?.subjective,
    assessment: note?.assessment,
    plan: note?.plan,
    shouldUseGenericObjective: note?.shouldUseGenericObjective,
    shouldUseCustomForm: note?.shouldUseCustomForm,
    customText: note?.customFormFields[0]?.textValue ?? "",
    additionalNotes: note?.additionalNotes,
    keyHistory: note?.keyHistory,
    keyMedications: note?.keyMedications,
    vaccinationStatus: note?.vaccinationStatus,
    weight,
    title: note?.title,
  };

  if (note?.shouldUseGenericObjective) {
    result.objective = note?.objective;
  }

  if (note?.clientSummary) {
    result.clientSummary = note?.clientSummary;
  }

  if (templateName) {
    result.templateName = templateName;
  }

  if (templateDescription) {
    result.templateDescription = templateDescription;
  }

  if (isForTemplateEdit) {
    result.colorScheme = colorScheme;
  }

  return result;
}

export function getDefaultFields({
  formFields,
  isForTemplateEdit = false,
  templateName = "",
  templateDescription = "",
  colorScheme = "rose",
}) {
  const defaultObjectiveFieldArray =
    getDefaultObjectiveFieldArray(formFields);

  const customObjectiveFieldArray =
    getCustomObjectiveFieldArray(formFields);

  return formatDefaultFields(
    formFields,
    defaultObjectiveFieldArray,
    customObjectiveFieldArray,
    isForTemplateEdit,
    templateName,
    templateDescription,
    colorScheme,
  );
}

function useDefaultValuesForExistingForms({
  note,
  template,
  reset,
  isForTemplateEdit = false,
  hasAppliedDefaultValues,
  setHasAppliedDefaultValues,
}) {
  useEffect(() => {
    if (!reset) {
      return;
    }

    const { draft, applyTemplate, editTemplate } =
      DEFAULT_VALUES_TYPE;

    const defaultValuesType = getDefaultValuesUsageType();

    if (hasAppliedDefaultValues && defaultValuesType == draft) {
      return;
    }

    if (!note && !template) {
      return;
    }

    let defaultFields = {};

    switch (defaultValuesType) {
      case draft:
        defaultFields = getDefaultFields({
          formFields: note,
        });

        // add down here to avoid overwriting original, while not adding to template application
        defaultFields["createdAt"] = note?.createdAt;

        reset({ ...defaultFields });
        setHasAppliedDefaultValues(true);
        break;

      case applyTemplate:
        defaultFields = getDefaultFields({
          formFields: template,
        });

        defaultFields.customObjectiveFieldArray =
          dropUuidsFromCustomFields(
            defaultFields.customObjectiveFieldArray,
          );

        // defaultFields.customFormFieldArray =
        //   dropUuidsFromCustomFields(
        //     defaultFields.customFormFieldArray,
        //   );

        reset({ ...defaultFields });
        break;

      case editTemplate:
        defaultFields = getDefaultFields({
          formFields: template.note,
          isForTemplateEdit: true,
          templateName: template?.name,
          templateDescription: template?.description,
          colorScheme: template?.colorScheme,
        });
        reset({ ...defaultFields });
        setHasAppliedDefaultValues(true);

        break;

      default:
        break;
    }

    function getDefaultValuesUsageType() {
      if (!note && template && !isForTemplateEdit) {
        return applyTemplate;
      }

      if (note && template) {
        // If note and template present, favour template.
        // Since this template will be populated by useApplyTemplate,
        // the template object is really just a note so there is no need to destructure fields
        return applyTemplate;
      }

      if (note && !template) {
        return draft;
      }

      if (template && isForTemplateEdit) {
        return editTemplate;
      }

      return null;
    }
  }, [note, template]);
}

function useDefaultFieldsForNewForms({ note, template, reset }) {
  useEffect(() => {
    if (!note && !template) {
      reset({
        defaultObjectiveFieldArray: OBJECTIVE_FIELDS.map(
          ({ name, label, type }) => {
            return {
              name,
              value: "",
              label,
              type,
            };
          },
        ),
      });
    }
  }, [note, template]);
}

export function useDefaultValues({
  note,
  template,
  reset,
  isForTemplateEdit,
}) {
  const [hasAppliedDefaultValues, setHasAppliedDefaultValues] =
    useState(false);

  useDefaultValuesForExistingForms({
    note,
    template,
    reset,
    isForTemplateEdit,
    hasAppliedDefaultValues,
    setHasAppliedDefaultValues,
  });
  useDefaultFieldsForNewForms({
    note,
    template,
    reset,
  });

  return null;
}
