import { Tooltip } from "@mui/material";
import React from "react";

import { getFillColor, getStrokeColor } from "./utils";

const CanineDentalChart = ({ toothConditions }) => {
  // Generic function to render a shape (path or ellipse) with a tooltip
  const renderShape = (shapeType, toothId, shapeProps) => {
    const ShapeComponent = shapeType === "path" ? "path" : "ellipse";
    return (
      <Tooltip
        title={toothConditions[toothId] || "Normal"}
        key={toothId}
        placement="left"
        arrow
        enterDelay={0}
        disableFocusListener
        slotProps={{
          popper: {
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -10],
                },
              },
            ],
          },
        }}
      >
        <ShapeComponent
          id={toothId}
          {...shapeProps}
          fill={getFillColor({ toothConditions, toothId })}
          stroke={getStrokeColor({ toothConditions, toothId })}
          className="cursor-pointer"
        />
      </Tooltip>
    );
  };

  return (
    <svg
      viewBox="0 0 172 405"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Canine">
        <g id="Canine-Right">
          {renderShape("path", "103", {
            d: "M67.6816 16.6886C67.6816 21.383 64.7715 25.1886 61.1816 25.1886C57.5918 25.1886 54.6816 21.383 54.6816 16.6886C54.6816 11.9941 51.0918 6.68857 54.6816 6.68857C58.2715 6.68857 67.6816 11.9941 67.6816 16.6886Z",
          })}

          {renderShape("path", "102", {
            d: "M66.1816 6.68859C67.114 3.31285 69.5312 2.59465 71.6816 3.18859C73.832 3.78252 75.3874 7.07598 74.455 10.4517C73.5227 13.8275 74.832 18.7825 72.6816 18.1886C70.5312 17.5947 65.2492 10.0643 66.1816 6.68859Z",
          })}

          {renderShape("path", "403", {
            d: "M66.1136 395.238C64.0129 397.701 62.9674 397.351 60.4729 396.303C57.3761 395.002 59.2415 391.899 61.3423 389.436C63.4431 386.974 64.4812 382.481 65.8794 383.674C67.2776 384.867 68.2144 392.776 66.1136 395.238Z",
          })}

          {renderShape("path", "402", {
            d: "M75.2826 395.984C74.2185 398.775 73.987 399.805 71.6513 399.308C67.5792 398.441 68.2378 395.972 69.3019 393.18C70.366 390.389 72.1821 386.326 73.6963 386.904C75.2106 387.481 76.3467 393.193 75.2826 395.984Z",
          })}

          {renderShape("path", "401", {
            d: "M76.7272 395.963C76.8086 398.949 76.6866 399.998 79.0553 400.301C83.1848 400.829 83.3798 398.281 83.2984 395.295C83.217 392.309 82.8464 387.874 81.2265 387.918C79.6065 387.962 76.6458 392.977 76.7272 395.963Z",
          })}

          {renderShape("path", "108", {
            d: "M14.771 130.485C20.7946 125.203 24.2441 128.338 27.1816 131.689C30.1191 135.039 39.6816 140.189 24.1403 146.114C18.1168 151.395 14.1191 159.539 11.1816 156.189C8.24411 152.838 8.74747 135.766 14.771 130.485Z",
          })}

          {renderShape("path", "109", {
            d: "M14.6816 183.189C7.48913 179.661 7.21945 172.689 9.18161 168.689C11.1438 164.688 11.0851 153.057 22.5334 165.122C29.7259 168.65 38.7807 169.207 36.8186 173.207C34.8564 177.207 21.8741 186.716 14.6816 183.189Z",
          })}

          {renderShape("path", "101", {
            d: "M75.8584 3.94636C76.4827 0.500317 77.4216 0.698292 79.6167 1.09596C81.8119 1.49362 82.5934 1.45334 81.9692 4.89939C81.3449 8.34543 82.4859 14.4858 80.2907 14.0881C78.0955 13.6904 76.4443 10.4199 75.8584 3.94636Z",
          })}

          {renderShape("path", "104", {
            d: "M64.1816 57.1886C64.1816 69.8762 58.0926 71.1886 53.6654 71.1886C45.6493 71.1886 45.6493 60.9032 45.6493 48.2156C45.6493 35.528 30.1816 23.1886 36.6816 23.1886C41.1088 23.1886 64.1816 34.6886 64.1816 57.1886Z",
          })}

          {renderShape("path", "404", {
            d: "M64.6614 375.928C66.4922 365.289 60.6478 363.15 56.2607 362.395C48.3171 361.028 46.8329 369.653 45.0021 380.292C43.1712 390.93 26.0631 398.639 32.5042 399.748C36.8913 400.503 61.4146 394.795 64.6614 375.928Z",
          })}

          {renderShape("ellipse", "105", {
            cx: "47.4048",
            cy: "80.9669",
            rx: "3.39473",
            ry: "6.16196",
            transform: "rotate(13.3212 47.4048 80.9669)",
          })}

          {renderShape("ellipse", "106", {
            cx: "41.6806",
            cy: "97.5857",
            rx: "3.64356",
            ry: "7.61427",
            transform: "rotate(19.7184 41.6806 97.5857)",
          })}

          {renderShape("ellipse", "107", {
            cx: "29.6816",
            cy: "116.296",
            rx: "4.77441",
            ry: "9.7726",
            transform: "rotate(29.8018 29.6816 116.296)",
          })}

          {renderShape("ellipse", "110", {
            cx: "28.6214",
            cy: "189.287",
            rx: "5.6809",
            ry: "9.7726",
            transform: "rotate(74.4115 28.6214 189.287)",
          })}

          {renderShape("ellipse", "411", {
            cx: "36.0533",
            cy: "228.704",
            rx: "3.96461",
            ry: "6.26568",
            transform: "rotate(-176.194 36.0533 228.704)",
          })}

          {renderShape("ellipse", "410", {
            cx: "36.6036",
            cy: "242.74",
            rx: "4.51613",
            ry: "6.26568",
            transform: "rotate(-176.194 36.6036 242.74)",
          })}

          {renderShape("ellipse", "408", {
            cx: "50.7477",
            cy: "297.906",
            rx: "4.51613",
            ry: "8.43623",
            transform: "rotate(174.447 50.7477 297.906)",
          })}

          {renderShape("ellipse", "407", {
            cx: "54.6816",
            cy: "318.89",
            rx: "3.29778",
            ry: "7.41692",
            transform: "rotate(174.447 54.6816 318.89)",
          })}

          {renderShape("ellipse", "406", {
            cx: "57.5739",
            cy: "337.782",
            rx: "3.29778",
            ry: "6.30367",
            transform: "rotate(174.447 57.5739 337.782)",
          })}

          {renderShape("ellipse", "405", {
            cx: "58.1226",
            cy: "351.984",
            rx: "2.83367",
            ry: "5.51287",
            transform: "rotate(-179.073 58.1226 351.984)",
          })}

          {renderShape("ellipse", "409", {
            cx: "44.2188",
            cy: "269.597",
            rx: "6.83507",
            ry: "16.0065",
            transform: "rotate(167.165 44.2188 269.597)",
          })}
        </g>
        <g id="Canine-Left">
          {renderShape("path", "203", {
            d: "M101.679 16.4716C101.701 21.166 104.628 24.9583 108.218 24.9419C111.808 24.9255 114.701 21.1066 114.679 16.4123C114.658 11.7179 118.223 6.39598 114.634 6.41238C111.044 6.42877 101.658 11.7773 101.679 16.4716Z",
          })}
          {renderShape("path", "202", {
            d: "M103.134 6.4649C102.186 3.09346 99.7654 2.38631 97.6177 2.99006C95.4701 3.5938 93.9297 6.89433 94.8775 10.2658C95.8253 13.6372 94.5386 18.5982 96.6862 17.9945C98.8339 17.3907 104.081 9.83635 103.134 6.4649Z",
          })}
          {renderShape("path", "303", {
            d: "M104.976 395.01C107.088 397.463 108.132 397.108 110.621 396.049C113.712 394.734 111.833 391.639 109.721 389.187C107.609 386.734 106.55 382.246 105.157 383.445C103.765 384.645 102.864 392.558 104.976 395.01Z",
          })}
          {renderShape("path", "302", {
            d: "M95.8104 395.798C96.8872 398.584 97.1235 399.613 99.4569 399.105C103.525 398.22 102.855 395.753 101.778 392.967C100.701 390.181 98.8668 386.126 97.3552 386.71C95.8436 387.295 94.7336 393.011 95.8104 395.798Z",
          })}
          {renderShape("path", "301", {
            d: "M94.3657 395.784C94.298 398.77 94.4248 399.818 92.0575 400.132C87.9304 400.679 87.7238 398.132 87.7916 395.145C87.8593 392.159 88.2096 387.723 89.8298 387.759C91.4499 387.796 94.4335 392.797 94.3657 395.784Z",
          })}
          {renderShape("path", "208", {
            d: "M155.109 130.025C149.061 124.771 145.626 127.922 142.704 131.286C139.782 134.649 130.243 139.843 145.811 145.697C151.859 150.95 155.894 159.076 158.816 155.712C161.738 152.349 161.157 135.279 155.109 130.025Z",
          })}
          {renderShape("path", "209", {
            d: "M155.439 182.728C162.615 179.167 162.853 172.194 160.873 168.203C158.892 164.212 158.898 152.58 147.505 164.697C140.329 168.258 131.276 168.856 133.257 172.848C135.237 176.839 148.263 186.289 155.439 182.728Z",
          })}
          {renderShape("path", "201", {
            d: "M93.4445 3.76691C92.8045 0.323755 91.8665 0.526015 89.6731 0.933699C87.4798 1.34138 86.6981 1.30468 87.3381 4.74783C87.9781 8.19099 86.8652 14.3365 89.0585 13.9288C91.2519 13.5211 92.8881 10.2431 93.4445 3.76691Z",
          })}
          {renderShape("path", "204", {
            d: "M105.364 56.9553C105.422 69.6427 111.517 70.9273 115.944 70.9071C123.96 70.8705 123.913 60.5852 123.855 47.8978C123.797 35.2103 139.209 22.8004 132.709 22.83C128.282 22.8503 105.261 34.4555 105.364 56.9553Z",
          })}
          {renderShape("path", "304", {
            d: "M106.34 375.693C104.461 365.063 110.295 362.898 114.679 362.123C122.616 360.719 124.14 369.337 126.019 379.967C127.898 390.598 145.042 398.229 138.606 399.366C134.222 400.141 109.673 394.545 106.34 375.693Z",
          })}
          {renderShape("ellipse", "205", {
            cx: "3.39473",
            cy: "6.16196",
            rx: "3.39473",
            ry: "6.16196",
            transform:
              "matrix(-0.972032 0.23485 0.23485 0.972032 124.102 73.8698)",
          })}
          {renderShape("ellipse", "206", {
            cx: "3.64356",
            cy: "7.61427",
            rx: "3.64356",
            ry: "7.61427",
            transform:
              "matrix(-0.939812 0.341692 0.341692 0.939812 128.872 88.8482)",
          })}
          {renderShape("ellipse", "207", {
            cx: "4.77441",
            cy: "9.7726",
            rx: "4.77441",
            ry: "9.7726",
            transform:
              "matrix(-0.865471 0.500959 0.500959 0.865471 139.37 105.055)",
          })}
          {renderShape("ellipse", "210", {
            cx: "5.6809",
            cy: "9.7726",
            rx: "5.6809",
            ry: "9.7726",
            transform:
              "matrix(-0.264325 0.964434 0.964434 0.264325 133.604 180.828)",
          })}
          {renderShape("ellipse", "311", {
            cx: "3.96461",
            cy: "6.26568",
            rx: "3.96461",
            ry: "6.26568",
            transform:
              "matrix(0.997482 -0.0709257 -0.0709257 -0.997482 130.765 234.871)",
          })}
          {renderShape("ellipse", "310", {
            cx: "4.51613",
            cy: "6.26568",
            rx: "4.51613",
            ry: "6.26568",
            transform:
              "matrix(0.997482 -0.0709257 -0.0709257 -0.997482 129.729 248.949)",
          })}
          {renderShape("ellipse", "308", {
            cx: "4.51613",
            cy: "8.43623",
            rx: "4.51613",
            ry: "8.43623",
            transform:
              "matrix(0.995739 0.0922186 0.0922186 -0.995739 114.622 305.593)",
          })}
          {renderShape("ellipse", "307", {
            cx: "3.29778",
            cy: "7.41692",
            rx: "3.29778",
            ry: "7.41692",
            transform:
              "matrix(0.995739 0.0922186 0.0922186 -0.995739 112.091 325.692)",
          })}
          {renderShape("ellipse", "306", {
            cx: "3.29778",
            cy: "6.30368",
            rx: "3.29778",
            ry: "6.30368",
            transform:
              "matrix(0.995739 0.0922186 0.0922186 -0.995739 109.388 343.488)",
          })}
          {renderShape("ellipse", "305", {
            cx: "2.83367",
            cy: "5.51287",
            rx: "2.83367",
            ry: "5.51287",
            transform:
              "matrix(0.999785 -0.0207429 -0.0207429 -0.999785 110.051 357.29)",
          })}
          {renderShape("ellipse", "309", {
            cx: "6.83507",
            cy: "16.0065",
            rx: "6.83507",
            ry: "16.0065",
            transform:
              "matrix(0.976016 0.217697 0.217697 -0.976016 116.141 283.404)",
          })}
        </g>
      </g>
    </svg>
  );
};

export default CanineDentalChart;
