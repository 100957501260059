import { useQuery } from "@apollo/client";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import {
  faPeopleArrows,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FormControl,
  MenuItem,
  Select,
  Tooltip,
  InputLabel,
} from "@mui/material";
import mixpanel from "mixpanel-browser";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { GET_TEAMS_USERS } from "../../../graphql/queries/GetTeamsUsers";
import { useDarkMode } from "../../../hooks";
import { usePreventRecordingInterruptions } from "../../../hooks";
import { useCurrentUser } from "../../../hooks/use-current-user";
import { useTeam } from "../../../hooks/use-team";
import { useTeamMember } from "../../../hooks/use-team-member";
import { alert } from "../../common/Alert";

export default function TeamUserDropdown({
  propHandleChange,
  disableExtraStyles = false,
  onLabelClick,
}) {
  const { currentTeam, setCurrentTeam } = useTeam();
  const {
    selectedLinkedUser,
    selectedUserName,
    linkMember,
    returnToRootUser,
  } = useTeamMember();
  const [enabled] = useDarkMode();
  const { shouldPreventInterruptions } =
    usePreventRecordingInterruptions();

  const { data, loading, error } = useQuery(GET_TEAMS_USERS);
  const { refetch } = useCurrentUser();
  const navigate = useNavigate();
  const location = useLocation();
  const [isRootUserActive, setIsRootUserActive] = useState(
    localStorage.getItem("user_uuid") ===
      localStorage.getItem("root_logged_in_user_uuid"),
  );

  useEffect(() => {
    setIsRootUserActive(
      localStorage.getItem("user_uuid") ===
        localStorage.getItem("root_logged_in_user_uuid"),
    );
  }, [selectedLinkedUser]);

  useEffect(() => {
    if (
      (location?.pathname === "/account" ||
        location?.pathname?.includes("/admin")) &&
      selectedLinkedUser !==
        localStorage.getItem("root_logged_in_user_uuid")
    ) {
      const cleanedPath = location.pathname.slice(1);
      alert(
        "warning",
        `Returning to root user because you are on the ${cleanedPath} page.`,
      );
      returnToRootUser();
      setIsRootUserActive(true);
    }
  }, [location]);

  const handleChange = async (event) => {
    const linkedUserUuid = event.target.value;
    const result = await linkMember(linkedUserUuid);
    if (result.success) {
      alert(
        "success",
        `Switched to user ${result.user.firstName} ${result.user.lastName} successfully.`,
      );
      refetch();
      navigate("/dashboard");
      mixpanel.track("Switched User", {
        newTeamMember: linkedUserUuid,
        previousTeamMember: localStorage.getItem("user_uuid"),
      });
    } else {
      alert("error", result.error);
    }
  };

  const handleSelectChange = propHandleChange || handleChange;

  if (
    loading ||
    error ||
    !data?.rootUser?.teams ||
    !data?.rootUser?.teams.length
  ) {
    return null;
  }

  if (!currentTeam || !currentTeam?.name) {
    setCurrentTeam(data?.rootUser?.teams[0]);
  }

  const labelStyles = {
    color: enabled ? "rgb(209 213 219)" : "black",
    backgroundColor: enabled ? "rgb(55 65 81)" : "rgb(243 244 246)",
    borderTopLeftRadius: "6px",
    borderTopRightRadius: "6px",
    paddingX: "8px",
    position: "absolute",
    top: "-2px",
    left: "-0px",
    transform: "translate(0, -50%)",
    fontSize: "12px",
    pointerEvents: "auto",
    "&.Mui-focused": {
      color: enabled ? "rgb(209 213 219)" : "black",
    },
    "&:hover": onLabelClick
      ? {
          cursor: "pointer",
          color: "#6366F1",
        }
      : {},
  };

  return (
    <>
      {!isRootUserActive && !disableExtraStyles ? (
        <div
          className={
            !disableExtraStyles &&
            "w-screen h-screen fixed top-0 left-0 border-[6px] border-indigo-500 pointer-events-none"
          }
        >
          <Tooltip
            arrow
            placement="right"
            title="You are controlling this user's account."
          >
            <div className="bottom-0 absolute bg-indigo-500 py-1 px-3 rounded-tr-lg text-white text-sm pointer-events-auto">
              <FontAwesomeIcon icon={faUsers} /> Acting As:{" "}
              <strong>{selectedUserName}</strong>{" "}
              <FontAwesomeIcon icon={faQuestionCircle} />
            </div>
          </Tooltip>
        </div>
      ) : null}

      <FormControl sx={{ m: 1, position: "relative" }} size="small">
        {onLabelClick ? (
          <Tooltip
            arrow
            placement="right"
            title={
              shouldPreventInterruptions
                ? "Stop recording to access team functionality."
                : "Click to switch teams."
            }
          >
            <InputLabel
              id="team-label"
              shrink
              sx={labelStyles}
              onClick={
                !shouldPreventInterruptions ? onLabelClick : undefined
              }
            >
              {currentTeam?.name}
            </InputLabel>
          </Tooltip>
        ) : (
          <InputLabel id="team-label" shrink sx={labelStyles}>
            {currentTeam?.name}
          </InputLabel>
        )}
        <div className="flex flex-row items-center dark:bg-gray-700 bg-gray-100 pl-3 rounded-lg text-indigo-500">
          <FontAwesomeIcon icon={faPeopleArrows} />
          <Select
            labelId="team-label"
            sx={[
              {
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "& .MuiOutlinedInput-input": {
                  pl: 0,
                },
              },
              {
                fontSize: "14px",
                color: enabled ? "#f9fafb" : "black",
                backgroundColor: enabled
                  ? "rgb(55 65 81)"
                  : "rgb(243 244 246)",
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "8px",
                borderBottomRightRadius: "8px",
                borderBottomLeftRadius: "8px",
                flex: 1,
                width: 160,
              },
            ]}
            disabled={shouldPreventInterruptions}
            // when the dropdown is used to switch users/display the current user it should always reflect the currently linked user
            // even if the user is changed from an external function and not the dropdown
            // when its used to transfer notes (or other actions) it should reflect the currently
            // selected user initially, but the selected value changeable (without changing the current teams user)
            {...(disableExtraStyles
              ? { defaultValue: selectedLinkedUser }
              : { value: selectedLinkedUser })}
            onChange={handleSelectChange}
            MenuProps={{
              PaperProps: {
                style: {
                  backgroundColor: enabled
                    ? "rgb(55 65 81)"
                    : "rgb(243 244 246)",
                },
              },
            }}
          >
            {currentTeam?.users?.map((user) => (
              <MenuItem
                key={user.uuid}
                value={user.uuid}
                sx={{
                  fontSize: "14px",
                }}
              >
                <h4 className="text-sm ml-2 font-medium truncate">
                  {user.firstName} {user.lastName}
                  {localStorage.getItem(
                    "root_logged_in_user_uuid",
                  ) === user.uuid && !disableExtraStyles ? (
                    <span className="text-xs font-normal">
                      {" "}
                      (You)
                    </span>
                  ) : null}
                </h4>
              </MenuItem>
            ))}
          </Select>
        </div>
      </FormControl>
    </>
  );
}
