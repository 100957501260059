import { useCombobox } from "downshift";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSpring, animated, config } from "react-spring";

import { species } from "./utils.js";
import { useWindowDimensions } from "../../hooks";
import Twemoji from "../../Twemoji.js";
import Loading from "../common/loading/Loading.js";

function EmptyState({ showFailedSearchState, showEmptyState }) {
  return (
    <div
      className="bg-white dark:bg-gray-700"
      data-cy="emptyPatients"
    >
      {showFailedSearchState && !showEmptyState ? (
        <h4 className="text-gray-500 dark:text-gray-400 font-normal text-center p-8">
          We couldn&apos;t find a patient with that name in your
          notebook.
          <Twemoji emoji="😿" /> <br /> If you would like to add a new
          patient, use the &quot;Add Patient&quot; button!
        </h4>
      ) : (
        <h4 className="text-gray-500 dark:text-gray-400 font-normal text-center p-8">
          You have no patients in your notebook.{" "}
          <Twemoji emoji="😿" /> <br /> Please add some using the
          &quot;Add Patient&quot; button!
        </h4>
      )}
    </div>
  );
}

export function PatientSearchBar(props) {
  const {
    onPatientClick,
    resultsMaxHeight,
    isForPatientSelectionAction,
    setSelectedPatientName,
    setSelectedPatientUuid,
    itemsAsObjects,
    loadingTrigger,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [inputItems, setInputItems] = useState(itemsAsObjects);
  const dropdownAnim = useSpring({
    config: config.stiff,
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? "translateY(0)" : "translateY(-40px)",
  });
  const [selectedItem, setSelectedItem] = useState("");
  const [showEmptyState, setShowEmptyState] = useState(
    itemsAsObjects?.length === 0,
  );
  const [showFailedSearchState, setShowFailedSearchState] =
    useState(false);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (itemsAsObjects?.length === 0) {
      setShowEmptyState(true);
    } else {
      setShowEmptyState(false);
      setInputItems(itemsAsObjects);
    }
  }, [itemsAsObjects]);

  const itemToString = (item) => {
    if (item) {
      if (item?.lastNameOfOwner?.length > 0) {
        return `${item.name} ${item.lastNameOfOwner}`;
      } else {
        return item.name;
      }
    } else {
      return "";
    }
  };

  const {
    getComboboxProps,
    getInputProps,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    highlightedIndex,
  } = useCombobox({
    selectedItem,
    itemToString,
    items: inputItems,
    onInputValueChange: ({ inputValue }) => {
      setInputItems(
        itemsAsObjects?.filter((item) => {
          return itemToString(item)
            .toLowerCase()
            .includes(inputValue?.toLowerCase());
        }),
      );
    },
    onStateChange: handleStateChange,
  });

  function handleClearInputClick() {
    setInputItems([]);
    setSelectedItem("");
  }

  function handleStateChange(changes) {
    // && argument prevents an error being thrown if selectedItem is empty (like when field is cleared)
    if (changes?.selectedItem && changes?.selectedItem != null) {
      setSelectedItem(changes?.selectedItem);
    } else if (changes?.inputValue) {
      setSelectedItem(changes?.inputValue);
    }

    // control user presses enter key on a patient
    if (changes?.type === "__input_keydown_enter__") {
      if (changes?.selectedItem) {
        onPatientClick(changes?.selectedItem?.uuid);
      } else {
        return null;
      }
    }

    // control showing empty and failed search state
    if (
      itemsAsObjects?.length !== 0 &&
      inputItems?.length === 0 &&
      changes?.inputValue !== ""
    ) {
      setShowFailedSearchState(true);
    } else {
      setShowFailedSearchState(false);
    }

    if (itemsAsObjects?.length === 0) {
      setShowEmptyState(true);
    }
  }

  function ArrowIcon({ isOpen }) {
    return (
      <svg
        viewBox="0 0 20 20"
        preserveAspectRatio="true"
        width={12}
        fill="transparent"
        stroke="#6b7280"
        strokeWidth="3px"
        transform={isOpen ? "rotate(180)" : undefined}
        className="pointer-events-auto cursor-pointer"
      >
        <path d="M1,6 L10,15 L19,6" />
      </svg>
    );
  }

  function XIcon() {
    return (
      <svg
        viewBox="0 0 20 20"
        preserveAspectRatio="true"
        width={10}
        fill="transparent"
        stroke="#6b7280"
        strokeWidth="3px"
        className="cursor-pointer"
      >
        <path d="M1,1 L19,19" />
        <path d="M19,1 L1,19" />
      </svg>
    );
  }

  return (
    <div>
      <div {...getComboboxProps()} className="relative w-full">
        <input
          autoFocus={width > 500}
          {...getInputProps({
            placeholder: "Search for a patient here...",
            name: "patient",
            onFocus: () => setIsOpen(true),
            onInput: () => setIsOpen(true),
            onKeyDown: (event) => {
              if (
                event?.key === "ArrowDown" ||
                event?.key === "ArrowUp"
              ) {
                setIsOpen(true);
              }
            },
          })}
          className="appearance-none w-full dark:bg-gray-700 dark:border-gray-700 dark:text-gray-100 border-2 rounded-xl py-3 px-4 lg:text-xl bg-gray-100 border-gray-100 focus:ring-2 focus:ring-indigo-300 outline-none cursor-text"
        />
        {selectedItem ? (
          <div
            className="appearance-none absolute right-6 top-6"
            onClick={handleClearInputClick}
            aria-label="clear selection"
          >
            <XIcon />
          </div>
        ) : (
          <div
            className="appearance-none absolute right-6 top-6"
            {...getToggleButtonProps({
              onClick: () => {
                isOpen ? setIsOpen(false) : null;
              },
            })}
          >
            <ArrowIcon isOpen={isOpen} />
          </div>
        )}
      </div>
      <div className="relative z-10 w-full transform">
        <animated.div
          {...getMenuProps()}
          style={dropdownAnim}
          className={`${
            isOpen ? "block" : "hidden"
          } absolute w-full divide-y text-sm dark:text-gray-200 dark:bg-gray-700 bg-white shadow-md border dark:border-gray-800 dark:divide-gray-800 rounded-lg border-gray-200 overflow-y-auto overflow-x-hidden cursor-pointer ${
            resultsMaxHeight || "max-h-96"
          }`}
        >
          {isOpen && (showEmptyState || showFailedSearchState) ? (
            <EmptyState
              showFailedSearchState={showFailedSearchState}
              showEmptyState={showEmptyState}
            />
          ) : (
            inputItems?.map((item, index) => (
              <div
                className={
                  highlightedIndex === index
                    ? "py-2 px-3 bg-indigo-50 dark:bg-indigo-900"
                    : "py-2 px-3 bg-white dark:bg-gray-700"
                }
                key={index}
                id={item?.uuid}
                {...getItemProps({
                  item,
                  index,
                  onClick: () => {
                    if (!isForPatientSelectionAction) {
                      onPatientClick(item?.uuid);
                    } else {
                      setSelectedPatientUuid(item?.uuid);
                      setSelectedPatientName(item?.name);
                    }
                  },
                })}
              >
                <div className="flex flex-row items-center">
                  <div className="flex-1 flex-row">
                    <div
                      className={
                        item?.sex == "m"
                          ? "text-blue-400 font-medium text-base"
                          : item?.sex == "f"
                          ? "text-pink-400 font-medium text-base"
                          : "text-indigo-400 font-medium text-base"
                      }
                    >
                      {item?.name} {item?.lastNameOfOwner}
                    </div>

                    <h5 className="text-xs text-gray-500 dark:text-gray-400">
                      {item?.breed ? `${item.breed}, ` : ""}

                      {item?.age ? item.age : "Born today 🎉"}
                    </h5>
                  </div>
                  <h3>
                    <Twemoji
                      emoji={
                        species[item?.species]?.icon
                          ? species[item?.species]?.icon
                          : "🦄"
                      }
                    />
                  </h3>
                </div>
              </div>
            ))
          )}
          {isOpen && loadingTrigger ? (
            <Loading shouldShow={true} />
          ) : null}
        </animated.div>
      </div>
    </div>
  );
}

PatientSearchBar.propTypes = {
  onPatientClick: PropTypes.func,
};
