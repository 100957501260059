import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";

import EDIT_USER from "../../graphql/mutations/EditUser.js";
import { alert } from "../common/Alert.js";
import EditDeleteButtons from "../common/buttons/EditDeleteButtons.js";
import { ErrorMessage, Input } from "../common/inputs/Input.js";

// This code be generalized for other inputs on the settings page when we get there
function AutoEmailInput({
  control,
  setShouldShowAutoExportSave,
  shouldShowAutoExportSave,
  errors,
}) {
  return (
    <div className="flex flex-col items-center">
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <Input
            placeholder="staff@yourclinic.com"
            onChange={(event) => {
              onChange(event.target.value);
              if (!shouldShowAutoExportSave) {
                if (event.target.value.length > 0) {
                  setShouldShowAutoExportSave(true);
                }
              }
            }}
            defaultValue={value}
            type="email"
          />
        )}
        name="autoEmail"
        rules={{
          required: true,
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: "Entered value does not match email format",
          },
        }}
      />
      {errors.autoEmail && (
        <ErrorMessage message="Please enter a valid email address!" />
      )}
    </div>
  );
}

function SavedAutoEmail({
  data,
  shouldShowAutoExportSave,
  setShouldShowAutoExportSave,
  reset,
  onSubmit,
}) {
  return (
    <div className="flex flex-col md:flex-row md:items-center md:space-x-2 space-y-2 md:space-y-0 mx-2">
      <div
        className={
          "text-xs md:text-sm text-center font-medium dark:text-gray-50"
        }
      >
        {data?.user?.autoExportEmail}
      </div>
      {!shouldShowAutoExportSave ? (
        <EditDeleteButtons
          onClickDestructiveButton={() => {
            reset();
            onSubmit({ autoEmail: "" });
          }}
          destructiveButtonText="Delete"
          onClickActionButton={() => {
            reset();
            setShouldShowAutoExportSave(true);
          }}
          actionButtonText="Edit"
        />
      ) : null}
    </div>
  );
}

export const AutoEmail = ({ data, refetch }) => {
  const [shouldShowAutoExportSave, setShouldShowAutoExportSave] =
    useState(false);

  const [editUser] = useMutation(EDIT_USER);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    // submit auto export mutation
    editUser({ variables: { autoExportEmail: data.autoEmail } })
      .then(() => {
        alert("success", "Account updated successfully.");
        setShouldShowAutoExportSave(false);
        refetch();
      })
      .catch((err) => alert("error", err.message));
  };

  return (
    <div
      className={"w-full md:w-auto my-2 flex flex-row items-center"}
    >
      {data?.user?.autoExportEmail && !shouldShowAutoExportSave ? (
        <SavedAutoEmail
          data={data}
          shouldShowAutoExportSave={shouldShowAutoExportSave}
          setShouldShowAutoExportSave={setShouldShowAutoExportSave}
          reset={reset}
          onSubmit={onSubmit}
        />
      ) : (
        <div className="flex flex-col md:flex-row md:items-center md:space-x-2 md:space-y-0 space-y-2">
          <AutoEmailInput
            control={control}
            setShouldShowAutoExportSave={setShouldShowAutoExportSave}
            shouldShowAutoExportSave={shouldShowAutoExportSave}
            errors={errors}
          />
          {shouldShowAutoExportSave ? (
            <EditDeleteButtons
              onClickDestructiveButton={() => {
                reset();
                setShouldShowAutoExportSave(false);
              }}
              destructiveButtonText="Cancel"
              onClickActionButton={handleSubmit(onSubmit)}
              actionButtonText="Save"
            />
          ) : null}
        </div>
      )}
    </div>
  );
};
