import { OBJECTIVE_FIELDS } from "../../../constants";

const NOTE_INPUT_FIELDS = [
  "noteUuid",
  "patientUuid",
  "userUuid",
  "createdAt",
  "lastUpdatedAt",
  "taggedAt",
  "isDraft",
  "isTagged",
  "isApproved",
  "hasBeenExported",
  "shouldUseGenericObjective",
  "shouldUseCustomForm",
  "transcription",
  "audioStorageLink",
  "storageObjectName",
  "title",
  "quickNote",
  "subjective",
  "objective",
  "assessment",
  "plan",
  "soapTags",
  "objectiveFields",
  "customObjectiveFields",
  "excludedObjectiveFields",
  "customText",
  "additionalNotes",
  "keyHistory",
  "keyMedications",
  "vaccinationStatus",
  "clientSummary",
];

function structureDefaultObjectiveFields(
  data = {},
  shouldSaveAsGenericObjective = false,
  isNewNote = false,
) {
  if (isNewNote) {
    return;
  }

  const structuredObjectiveFields = {};

  data?.defaultObjectiveFieldArray?.forEach(({ name, value }) => {
    structuredObjectiveFields[name] = value;
  });
  structuredObjectiveFields.weight = data?.weight;

  data.objectiveFields = structuredObjectiveFields;

  data.shouldUseGenericObjective =
    shouldSaveAsGenericObjective === true;
}

function structureCustomObjectiveFields(
  data = {},
  isNewNote = false,
) {
  if (isNewNote) {
    return;
  }
  data.customObjectiveFields = data?.customObjectiveFieldArray?.map(
    (customObjectiveField) => {
      // Apollo cache adds "__typename"
      // https://github.com/apollographql/apollo-feature-requests/issues/6
      delete customObjectiveField["__typename"];

      return customObjectiveField;
    },
  );
}

function removeUnneededFormFields(data) {
  Object.keys(data).forEach((name) => {
    if (!NOTE_INPUT_FIELDS.includes(name)) {
      delete data[name];
    }
  });
}

function calculateExcludedObjectiveFields({ data, isNewNote }) {
  const excludedObjectiveFields = [];

  if (isNewNote) {
    return excludedObjectiveFields;
  }

  const includedFields = [];
  data?.defaultObjectiveFieldArray?.forEach((field) => {
    includedFields.push(field.name);
  });

  OBJECTIVE_FIELDS.forEach((field) => {
    if (!includedFields.includes(field.name)) {
      excludedObjectiveFields.push(field.name);
    }
  });

  return excludedObjectiveFields;
}

/**
 * Feed me form data and I'll return a note input object.
 *
 * @param {object} data Form data from react-hook-form of a Basic note.
 * @param {boolean} shouldSaveAsGenericObjective boolean from the useBlank toggle.
 *
 * @returns {object} ready-to-use note input object for use with backend note/template mutations.
 */
export function formToNoteInput({
  data,
  shouldSaveAsGenericObjective = false,
  isNewNote = false,
}) {
  const formData = { ...data };

  structureDefaultObjectiveFields(
    formData,
    shouldSaveAsGenericObjective,
    isNewNote,
  );
  structureCustomObjectiveFields(formData, isNewNote);

  const excludedObjectiveFields = calculateExcludedObjectiveFields({
    data: formData,
    isNewNote,
  });

  removeUnneededFormFields(formData);

  return {
    ...formData,
    excludedObjectiveFields,
  };
}
