import { useMutation } from "@apollo/client";
import moment from "moment";
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router";

import { formToNoteInput } from "./common/utils";
import {
  CREATE_PATIENT_NOTE,
  CREATE_QUICK_NOTE,
} from "./edit/api/mutations";
import { submitBasicNote } from "./edit/api/submit";

function CreateNote() {
  const { patientUUID } = useParams();
  const navigate = useNavigate();

  const [createPatientNote] = useMutation(CREATE_PATIENT_NOTE);
  const [createQuickNote] = useMutation(CREATE_QUICK_NOTE);

  const mutations = {
    createPatientNote,
    createQuickNote,
  };

  useEffect(() => {
    const submitAsync = async () => {
      const structuredNoteData = formToNoteInput({
        data: {
          createdAt: moment().format(), // without this, createdAt is null.
        },
        isNewNote: true,
      });

      const { submittedNoteUuid } = await submitBasicNote({
        structuredNoteData,
        mutations,
        props: {
          patientUUID: patientUUID || null,
        },
        isDraft: true,
        isAutosave: true,
      });

      navigate(`/edit_note/${submittedNoteUuid}`, { replace: true });
    };

    submitAsync();
  }, []);

  return null;
}

export default CreateNote;
