import moment from "moment";

// import { SCRIBENOTE_FLASK } from "../constants.js";
import { alert } from "../components/common/Alert.js";
import { getTempScribenoteFlask } from "../utils.js";

export async function getCouponInfo(promotionalCode) {
  if (!promotionalCode) {
    return null;
  }

  const SCRIBENOTE_FLASK = getTempScribenoteFlask();

  const couponResponse = await fetch(
    `${SCRIBENOTE_FLASK}/validate-coupon`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        promotionalCode,
      }),
      credentials: "include",
    },
  );

  const couponJson = await couponResponse.json();
  return couponJson;
}

function handlePaymentThatRequiresCustomerAction({
  stripe,
  subscription,
  invoice,
  priceId,
  paymentMethodId,
}) {
  let setupIntent = subscription.pending_setup_intent;

  if (setupIntent && setupIntent.status === "requires_action") {
    return stripe
      .confirmCardSetup(setupIntent.client_secret, {
        payment_method: paymentMethodId,
      })
      .then((result) => {
        if (result.error) {
          // start code flow to handle updating the payment details
          // Display error message in your UI.
          // The card was declined (that is, insufficient funds, card has expired, etc)
          throw result;
        } else {
          if (result.setupIntent.status === "succeeded") {
            // There's a risk of the customer closing the window before callback
            // execution. To handle this case, set up a webhook endpoint and
            // listen to setup_intent.succeeded.
            return {
              priceId: priceId,
              subscription: subscription,
              invoice: invoice,
              paymentMethodId: paymentMethodId,
            };
          }
        }
      });
  } else {
    // No customer action needed
    return { subscription, priceId, paymentMethodId };
  }
}

async function createCustomer({ address, cardholderName }) {
  const SCRIBENOTE_FLASK = getTempScribenoteFlask();

  const createCustomerResponse = await fetch(
    `${SCRIBENOTE_FLASK}/create-customer`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        createdAt: moment().format(),
        address,
        cardholderName,
      }),
      credentials: "include",
    },
  );

  const customerJson = await createCustomerResponse.json();
  const customerId = customerJson["customerId"];

  return { customerId };
}

export async function provisionBilling({
  address,
  priceId,
  cardholderName,
  couponId = null,
}) {
  const { customerId } = await createCustomer({
    address,
    cardholderName,
  });

  const SCRIBENOTE_FLASK = getTempScribenoteFlask();

  const createSubscriptionResponse = await fetch(
    `${SCRIBENOTE_FLASK}/create-subscription`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        priceId: priceId,
        customerId: customerId,
        couponId,
      }),
      credentials: "include",
    },
  );

  const subscriptionJson = await createSubscriptionResponse.json();
  return subscriptionJson;
}

export async function provisionMeteredBilling({
  cardholderName,
  address,
  priceId,
  paymentMethod,
}) {
  const { customerId } = await createCustomer({
    cardholderName,
    address,
  });

  const SCRIBENOTE_FLASK = getTempScribenoteFlask();

  await fetch(`${SCRIBENOTE_FLASK}/create-metered-subscription`, {
    method: "post",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify({
      customerId: customerId,
      paymentMethodId: paymentMethod.id,
      priceId: priceId,
    }),
    credentials: "include",
  })
    .then((response) => {
      return response.json();
    })
    // If the card is declined, display an error to the user.
    .then((result) => {
      if (result.error) {
        // The card had an error when trying to attach it to a customer.
        alert("error", result.error);
      }
      return result;
    })
    // Normalize the result to contain the object returned by Stripe.
    // Add the additional details we need.
    .then((result) => {
      return {
        paymentMethodId: paymentMethod.id,
        priceId: priceId,
        subscription: result,
      };
    })
    // Some payment methods require a customer to be on session
    // to complete the payment process. Check the status of the
    // payment intent to handle these actions.
    .then(handlePaymentThatRequiresCustomerAction)
    .catch((error) => {
      alert("error", error.message);
    });
}

const SCRIBENOTE_FLASK = getTempScribenoteFlask();

export async function instantiateCustomerPortal() {
  fetch(`${SCRIBENOTE_FLASK}/create-customer-portal-session`, {
    method: "post",
    headers: {
      "Content-type": "application/json",
    },
    credentials: "include",
  })
    .then((response) => response.json())
    .then((data) => {
      const redirectUrl = data.redirect;
      window.location.href = redirectUrl;
    })
    .catch((error) => {
      alert("error", error.message);
    });
}
