import { useMutation, gql, useQuery } from "@apollo/client";
import {
  faBook,
  faEdit,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import Notes from "./note/Notes.js";
import RecentAttachments from "./summary/RecentAttachments.js";
import Summary from "./summary/Summary";
import Title from "./Title";
import { notebookStepsBasic } from "../../assets/data/JoyrideTourSteps.js";
import { NOTE_FIELDS } from "../../graphql/fragments/note.js";
import { useAccount } from "../../hooks";
import { alert } from "../common/Alert";
import { BasicButton } from "../common/buttons/BasicButton.js";
import HelpButton from "../common/buttons/HelpButton.js";
import { checkisAllowedAndDownloadEntirePatient } from "../common/clinical/NotePDF.js";
import JoyrideTour from "../common/modals/JoyrideTour.js";
import Record from "../common/record/Record.js";
import Tabs from "../common/tabs/Tabs.js";
import { CREATE_PATIENT_NOTE } from "../notes/edit/api/mutations.js";
import { EditablePatientModal as EditPatientModal } from "../patients/modal/EditablePatientModal.js";

const GET_NOTES = gql`
  ${NOTE_FIELDS}
  query patient($uuid: String, $last: Boolean) {
    patient(uuid: $uuid) {
      name
      lastNameOfOwner
      uuid
      species
      sex
      breed
      isIntact
      age
      weight
      color
      microchip
      ownerContactInfo
      emergencyContactInfo
      keyHistory
      keyMedications
      vaccinationStatus
      birthdayParts {
        dd
        mm
        yyyy
      }
      user {
        firstName
        lastName
        name
        account {
          logoFileLink
          headerInfoPdf
        }
      }
      notes(last: $last) {
        ...NoteFields
        keyHistory
        keyMedications
        vaccinationStatus
        attachments {
          fileName
          contentType
          fileLink
          createdAt
        }
      }
    }
  }
`;

const Notebook = () => {
  const [isRecording, setIsRecording] = useState(false);
  const { patientUUID } = useParams();
  const { accountType } = useAccount();
  const [createPatientNote] = useMutation(CREATE_PATIENT_NOTE);
  const [runJoyrideTour, setRunJoyrideTour] = useState(false);
  const [shouldShow, setShouldShow] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [activeTab, setActiveTab] = useState("patientSummary");
  const { control, getValues, reset } = useForm();

  const {
    data,
    refetch,
    loading: getNotesLoading,
  } = useQuery(GET_NOTES, {
    variables: {
      uuid: patientUUID,
      last: false,
    },
  });

  const isPageLoading = getNotesLoading;

  function hideModal() {
    setShouldShow(false);
  }

  async function createNewNote(audioStorageLink, storageObjectName) {
    const values = getValues();
    const jobType = JSON.parse(
      localStorage.getItem("next-recording-type"),
    );

    createPatientNote({
      variables: {
        noteInput: {
          patientUuid: patientUUID,
          audioStorageLink: audioStorageLink,
          storageObjectName: storageObjectName,
          createdAt: moment().format(),
          lastUpdatedAt: moment().format(),
          title: values.title,
          jobType,
        },
      },
    }).then(() => {
      // reset transcript between recordings
      alert("success", "Audio file uploaded!");

      refetch();
    });

    return true;
  }

  return (
    <div>
      <JoyrideTour
        steps={notebookStepsBasic}
        runJoyrideTour={runJoyrideTour}
        setRunJoyrideTour={setRunJoyrideTour}
      />
      <EditPatientModal
        shouldShow={shouldShow}
        hideModal={hideModal}
        isForPatientEdit={true}
        currentPatientInfo={data?.patient}
        refetch={refetch}
      />

      <div className="my-6 flex items-end">
        <div className="flex-1">
          <Title
            patientUUID={patientUUID}
            patient={data?.patient}
            dataCy="pTitle"
          />
        </div>
        <div className="flex flex-row items-center space-x-2 mr-4">
          <div id="editPatientButton">
            <BasicButton onClick={() => setShouldShow(true)}>
              <FontAwesomeIcon icon={faEdit} className="md:mr-2" />{" "}
              <span className="hidden md:block">Edit Patient</span>
            </BasicButton>
          </div>
          <div id="exportNotebookButton">
            <BasicButton
              onClick={() => {
                checkisAllowedAndDownloadEntirePatient(
                  data?.patient?.user,
                  data?.patient,
                  setIsExporting,
                );
              }}
            >
              <div className="md:w-36 md:h-5 flex flex-row items-center justify-center">
                {isExporting ? (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    color="white"
                    className="animate-spin"
                  />
                ) : (
                  <>
                    <FontAwesomeIcon
                      icon={faBook}
                      className="md:mr-2"
                    />{" "}
                    <span className="hidden md:block">
                      Export Notebook
                    </span>
                  </>
                )}
              </div>
            </BasicButton>
          </div>
        </div>

        <HelpButton
          onClick={() => {
            setRunJoyrideTour(true);
          }}
        />
      </div>
      <Tabs
        id="patientNotebookTabs"
        tabArray={[
          {
            id: "patientSummary",
            title: "Key Info",
          },
          {
            id: "attachments",
            title: "Attachments",
          },
        ]}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      >
        <div
          className={
            activeTab === "patientSummary" ? "block" : "hidden"
          }
          id="patientSummary"
        >
          <Summary patientUUID={patientUUID} />
        </div>
        <div
          className={activeTab === "attachments" ? "block" : "hidden"}
          id="patientAttachments"
        >
          <RecentAttachments
            isForPatientProfile={true}
            patientUUID={patientUUID}
          />
        </div>
      </Tabs>

      <div className="flex-grow-0 mt-32 mb-20">
        <Record
          createNewNote={createNewNote}
          id="recordButtonNotebook"
          isRecording={isRecording}
          setIsRecording={setIsRecording}
          control={control}
          reset={reset}
        />
      </div>

      <div className="mb-10" id="patientNotes">
        <Notes
          patientUUID={patientUUID}
          isPageLoading={isPageLoading}
          patient={data?.patient}
          data={data}
          refetch={refetch}
          isRecording={isRecording}
          accountType={accountType}
        />
      </div>
    </div>
  );
};

export default Notebook;
