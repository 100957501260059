import React from "react";

import DentalChart from "../../notes/edit/form/DentalChart";

export function CustomNoteSection(props) {
  const { note } = props;

  return (
    <div className="flex flex-col md:flex-row items-start justify-between">
      <h5 className="text-sm whitespace-pre-wrap flex-1">
        {note?.customFormFields[0]?.textValue}
      </h5>
      {note?.jobType === "dental_record" && (
        <div className="md:w-[300px] mx-4">
          <DentalChart
            record={note?.customFormFields[0]?.textValue}
          />
        </div>
      )}
    </div>
  );
}
