import React from "react";

import NoteItem from "../dashboard/dashboardInbox/NoteItem";
import ExportNoteItem from "../export/ExportNoteItem";

const Row = React.memo(function Row({
  isExportWindow,
  isAlreadyExported,
  row,
  refetch,
  activeTab,
}) {
  return (
    <div
      key={row.key}
      style={{
        top: 0,
        left: 0,
        width: "100%",
      }}
    >
      <>
        {isExportWindow ? (
          <ExportNoteItem
            exportNoteData={row}
            isAlreadyExported={isAlreadyExported}
            refetch={refetch}
            index={row.index}
            activeTab={activeTab}
          />
        ) : (
          <NoteItem note={row} shouldShowAudioPlayer />
        )}
      </>
    </div>
  );
});

export default Row;
