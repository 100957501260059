import { saveAs } from "file-saver";
import JSZip from "jszip";
import moment from "moment";

import { getPDFBlob } from "../common/clinical/NotePDF.js";

const currentTime = moment().format("MM-DD-YYYY, h mm ss a");

export function getName(patient) {
  const firstName = patient?.name || patient || "";
  const lastName = patient?.lastNameOfOwner || "";

  let name = "";

  if (firstName) {
    name = firstName + " " + lastName;
  }

  return name ? name : "Quicknote";
}

export function saveAsZip(blobs) {
  const zip = new JSZip();
  blobs.forEach((object) => {
    const noteTime = moment(object.createdAt).format(
      "MM-DD-YYYY, h mm ss a",
    );
    const filename = `ScribenoteSOAP-${getName(
      object?.patient,
    )} ${noteTime}.pdf`;

    zip.file(filename, object.blob);
  });
  return zip.generateAsync({ type: "blob" }).then((blob) => {
    saveAs(blob, `ScribenoteBulk-${currentTime}.zip`);
  });
}

// prevent race condition from trying to load custom fonts for multiple PDFs
// from https://github.com/diegomura/react-pdf/issues/310
export async function createBlobList(data, isForPatientNotebook) {
  if (!data) {
    return;
  }

  if (isForPatientNotebook) {
    const patientName = getName(data?.patient);
    let notes = data.patient.notes;

    if (!notes) {
      return;
    }

    const [firstNote, ...remainingNotes] = notes;
    const firstBlob = await getPDFBlob(
      firstNote,
      patientName,
      data?.patient?.user,
    );
    const remainingBlobs = await Promise.all(
      remainingNotes.map(async (item) => {
        const blob = await getPDFBlob(
          item,
          patientName,
          data?.patient?.user,
        );
        return {
          blob: blob,
          createdAt: item.createdAt,
          patient: patientName,
        };
      }),
    );

    saveAsZip([
      {
        blob: firstBlob,
        createdAt: firstNote.createdAt,
        patient: patientName,
      },
      ...remainingBlobs,
    ]);
  } else {
    const [firstNote, ...remainingNotes] = data;
    const firstBlob = await getPDFBlob(firstNote, firstNote.patient); // render one blob first to instantiate fonts, then load rest
    const remainingBlobs = await Promise.all(
      remainingNotes.map(async (item) => {
        const blob = await getPDFBlob(
          item,
          item.patient,
          data?.patient?.user,
        );
        return {
          blob: blob,
          createdAt: item.createdAt,
          patient: item.patient,
        };
      }),
    );
    saveAsZip([
      {
        blob: firstBlob,
        createdAt: firstNote.createdAt,
        patient: firstNote.patient,
      },
      ...remainingBlobs,
    ]);
  }
}

export function getUuidsOfExportedNotes(data, isForPatientNotebook) {
  if (isForPatientNotebook) {
    return data?.patient?.notes.map((note) => note.uuid);
  } else {
    return data.map((note) => note.uuid);
  }
}
