import { useMutation, gql } from "@apollo/client";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { Button } from "./form/Button.js";
import { alert } from "../common/Alert.js";
import { Input, ErrorMessage } from "../common/inputs/Input.js";

const UPDATE_FORGOTTEN_PASSWORD_MUTATION = gql`
  mutation updateForgottenPassword(
    $token: String
    $password: String
  ) {
    updateForgottenPassword(token: $token, password: $password) {
      ok
    }
  }
`;

export default function ForgotPasswordReset() {
  let { resetToken } = useParams();
  let navigate = useNavigate();

  const {
    handleSubmit,
    getValues,
    control,
    formState: { errors },
  } = useForm();

  const [updateForgottenPassword] = useMutation(
    UPDATE_FORGOTTEN_PASSWORD_MUTATION,
  );

  function submitPassword({ password }) {
    updateForgottenPassword({
      variables: {
        token: resetToken,
        password: password,
      },
    })
      .then((response) => {
        const { ok } = response.data.updateForgottenPassword;

        if (ok) {
          alert(
            "success",
            "Password updated successfully! Redirecting to our login page...",
          );
          setTimeout(function () {
            navigate("/auth/login");
          }, 3500);
        }
      })
      .catch((response) => {
        const errors = response.graphQLErrors.map((error) =>
          alert("error", error.message),
        );
        // need to catch errors to prevent exception
        // might as well console log to debug if client has issue
        console.log(errors);
      });
  }

  return (
    <form
      onSubmit={handleSubmit(submitPassword)}
      className="flex-col items-center justify-center sm:px-6 lg:px-8"
    >
      <div className="space-y-8">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 dark:text-gray-300">
          Reset your Scribenote password below 🙈
        </h2>
        <div className="max-w-sm mx-auto">
          <div className={"py-2"}>
            <h4 className="my-2">New Password</h4>
            <Controller
              control={control}
              name="password"
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  type="password"
                  placeholder={"Type your new password"}
                  {...field}
                />
              )}
            />
          </div>
          <div className={"py-2"}>
            <h4 className="my-2">Confirm New Password</h4>
            <Controller
              control={control}
              name="passwordConfirmation"
              rules={{
                required: "Please confirm password!",
                validate: {
                  matchesPreviousPassword: (value) => {
                    const { password } = getValues();
                    return (
                      password === value || "Passwords should match!"
                    );
                  },
                },
              }}
              render={({ field }) => (
                <Input
                  type="password"
                  placeholder={"Confirm your new password"}
                  {...field}
                />
              )}
            />
          </div>
          {errors.passwordConfirmation && (
            <ErrorMessage
              message={errors.passwordConfirmation.message}
            />
          )}

          <div className="m-8">
            <Button type="submit">Submit</Button>
          </div>
        </div>
      </div>
    </form>
  );
}
