import React from "react";

import Tooltip from "../..//modals/Tooltip";
import { NORMAL_ON_EXAM, BAR } from "../../../../constants";

export function CustomObjectiveText({
  name,
  value,
  symmetricalValueLeft,
  symmetricalValueRight,
  symmetryType,
  symmetryLabels,
  index,
}) {
  let classNameHeader = "font-medium text-center text-sm";
  const Header = () => {
    if (name) {
      return <div className={classNameHeader}>{name}:</div>;
    } else {
      return <div className={classNameHeader}>No name:</div>;
    }
  };

  let classNameCustomObjectiveValue =
    "w-full text-ellipsis overflow-hidden line-clamp-1 focus:outline-none rounded-sm text-xs";

  if (
    value !== NORMAL_ON_EXAM ||
    symmetricalValueLeft !== NORMAL_ON_EXAM ||
    symmetricalValueRight !== NORMAL_ON_EXAM
  ) {
    classNameCustomObjectiveValue +=
      " bg-pink-300 dark:bg-pink-500 bg-opacity-50 dark:bg-opacity-50 rounded-sm ";
  }

  return (
    <div
      key={index}
      className={
        "items-center relative w-full text-center pb-2 border-b dark:border-gray-600"
      }
    >
      <Header />
      <Tooltip
        tooltipContainerClassName="flex-1 overflow-hidden whitespace-pre-wrap"
        delay={1000}
        content={
          <div className="text-sm shadow-md bg-white dark:bg-gray-700 border border-gray-200 dark:border-gray-500 p-1 rounded-lg transform -translate-y-14">
            {symmetryType === "None" ? (
              <>
                <h4 className="text-sm">{name}</h4>
                <h5 className="text-sm">{value}</h5>
              </>
            ) : (
              <>
                <h4 className="text-sm text-left">
                  {symmetryLabels.left}: {symmetricalValueLeft}
                </h4>

                <h4 className="text-sm text-left">
                  {symmetryLabels.right}: {symmetricalValueRight}
                </h4>
              </>
            )}
          </div>
        }
      >
        <div>
          {symmetryType === "None" ? (
            <div className={classNameCustomObjectiveValue}>
              {value}
            </div>
          ) : (
            <div className="flex flex-row">
              <div className="flex flex-1 flex-col overflow-hidden text-ellipsis">
                <h4 className="text-gray-500 text-xs">
                  {symmetryLabels.left}
                </h4>
                <div className={classNameCustomObjectiveValue}>
                  {symmetricalValueLeft
                    ? symmetricalValueLeft
                    : name !== "overall"
                    ? NORMAL_ON_EXAM
                    : BAR}
                </div>
              </div>
              <div className="flex flex-1 flex-col overflow-hidden text-ellipsis">
                <h4 className="text-gray-500 text-xs">
                  {symmetryLabels.right}
                </h4>

                <div className={classNameCustomObjectiveValue}>
                  {symmetricalValueRight
                    ? symmetricalValueRight
                    : name !== "overall"
                    ? NORMAL_ON_EXAM
                    : BAR}
                </div>
              </div>
            </div>
          )}
        </div>
      </Tooltip>
    </div>
  );
}
